import React,{useState} from 'react'
import Card from "components/card/Card.js"
import FileUploadComponent from "../../../auth/signUp/fileupload";
import {   Box,
    Grid,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    Select,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue, } from "@chakra-ui/react";
    import { useAuth } from "auth-context/auth.context";
    import { MdOutlineRemoveRedEye } from "react-icons/md";
    import {useGetPrescriptionQuery, useUpdatePrescriptionMutation } from 'api/prescription';
    import { useGetAllDiagnosesQuery } from 'api/diagnosis';
    import { useGetAllPharmaciesQuery } from 'api/pharmacy';
    import { useGetAllPatientsQuery } from 'api/patient';
    import { useGetAllDoctorsQuery } from 'api/physician';
    import ListModal from "../../Diagnosis/components/listComponent";
    import ListDoctorModal from "./DoctoctorListModal";
    import DiagnosticListModal from "./DianosticListModal";
    import PharmListModal from "./PharmModal"


function EditForm({id}) {

  const { data, isLoading, errors } = useGetPrescriptionQuery(id);
const [updatePrescription, {isSuccess}] = useUpdatePrescriptionMutation();
const {data:diagnose, isError} = useGetAllDiagnosesQuery()
const {data:pharmacies} =  useGetAllPharmaciesQuery();
const {data:doctors} = useGetAllDoctorsQuery()
const { data:patients, } = useGetAllPatientsQuery()
const { user:{user} } = useAuth();
const [drugName, setDrugName] = useState('');
const [prescription, setPrescription] = useState(data?.prescription);
const [search, setSearch] = useState('');
const [searchDiagnostic, setSearchDiagnostic] = useState(data?.diagnoses);
const [diagnosticName, setDiagnosticName] = useState('');
const [patientId, setPatientId] = useState(data?.patient);
const [diagnosticId, setDiagnosticId] = useState('');
const [pharmId, setPharmId] = useState(data?.pharmacy);
const [specialtyId, setSpecialtyId] = useState(1);
const[temperature, setTemperature] = useState('');
const [filteredPatients, setFilteredPatients] = useState('')
const [filteredDoctors, setFilteredDoctors] = useState('');
const [filteredDiagnostic, setFilteredDiagnostic] = useState('');
const [filteredPharmacy, setFilteredPharmacy] = useState('');
const [doctorId, setDoctorId] = useState(data?.doctor);
const [isOpen, setIsOpen] = useState(false);
const [username, setUsername] = useState('');
const [pharmName, setPharmName] = useState('');
const [isDiagnostic, setDiagnostic] = useState('');
const [isPharm, setIsPharmacy] = useState(false);
const [searchDoc, setSearchDoc] = useState('');
const [searchPharm, setSearchPharmacy] = useState(false);
const [isOpenDoc, setIsOpenDoc] = useState(false);
const [doctorName, setDoctorName] = useState('');
const onClose = () => setIsOpen(false);
const onOpen = () => setIsOpen(true);
const onCloseDoc = () => setIsOpenDoc(false);
const onOpenDoc = () => setIsOpenDoc(true);
const onCloseDiagnosis = ()=> setDiagnostic(false);
const onOpenDiagnostic = ()=> setDiagnostic(true);
const onOpenPharmacy = () => setIsPharmacy(true);
const onClosePharmacy = () => setIsPharmacy(false);
const [error, setError] = useState(undefined);
const [buttonText, setButtonText] = useState("Update Prescription");
const [uploadedImageUrl, setUploadedImageUrl] = useState('')
// console.log(doctors, 'doctors')

const textColor = useColorModeValue("navy.700", "white");

const brandStars = useColorModeValue("brand.500", "brand.400");

const  handleSearch =(e)=>{
  setSearch(e)
}

const  handleSearchDoc =(e)=>{
  setSearchDoc(e)
}

const handleSearchDiagnostic =(e)=>{
  setSearchDiagnostic(e)
}


const handleSearchDiagnosticFilter = (searchTerm) => {
  // Update the query key to include the search term
  searchTerm.toLowerCase().trim();

  if(searchTerm !=="" && diagnose) {

   const filterobj = diagnose.filter(item => String(item.diagnosesID).includes(searchTerm))

   console.log(filterobj)

    setFilteredDiagnostic(filterobj)

  }else{
    setFilteredDiagnostic([]);
  }

};

const handleSearchPharm =(e)=>{
  setSearchPharmacy(e)
}

const handlesearchPharmacyFilter = (searchTerm) => {
  
  searchTerm.toLowerCase().trim();

  if(searchTerm !=="" && pharmacies) {

   const filterobj = pharmacies.filter(item => item.name && item.name.toLowerCase().includes(searchTerm))

   console.log(filterobj)

    setFilteredPharmacy(filterobj)

  }else{
    setFilteredPharmacy([]);
  }

};



const handleFilter = (searchTerm) => {
  
  searchTerm.toLowerCase().trim();

  if(searchTerm !=="" && patients) {

   const filterobj = patients.filter(item => item.name.toLowerCase().includes(searchTerm))

   console.log(filterobj)

    setFilteredPatients(filterobj)

  }else{
    setFilteredPatients([]);
  }

};



const handleFilterDoc = (searchTerm) => {
  // Update the query key to include the search term
  searchTerm.toLowerCase().trim();

  if(searchTerm !=="" && doctors) {

   const filterobj = doctors.filter(item => item.name.toLowerCase().includes(searchTerm))

   console.log(filterobj)

    setFilteredDoctors(filterobj)

  }else{
    setFilteredDoctors([]);
  }

};


// console.log(specialisation, 'profile')

const [selectedOption, setSelectedOption] = useState(Number(3));

console.log(diagnose, 'profile')
const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
//   const handleClick = () => setShow(!show);
  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }

    if (drugName === "") {
      return setError("You must enter your drug name");
    }

    // if(diagnosticId === "") {
    //   return setError("You must enter the diagnostic ID");
    // }
    // if(doctorId === "") {
    //   return setError("You must enter a doctor name");
    // }
    // if(patientId=== "") {
    //   return setError("You must enter a the patient name");
    // }
    
    try {
      setButtonText(isLoading?'Loading...':'Update Prescription');
      const datatoBackend ={
        prescriptionId:data?.prescriptionId,
        drugName:drugName?drugName:data?.drugName,
        prescription:prescription?prescription:data?.prescription,
        status:data.stataus?data?.status:'unfulfilled',
        createdBy: Number(user.id),
        patient:Number(patientId)?Number(patientId):data?.patientId,
        doctor:Number(doctorId)?Number(doctorId):data?.doctor,
        pharmacy:Number(pharmId)?Number(pharmId):data?.pharmacy,
        diagnoses:Number(diagnosticId)?Number(diagnosticId):data?.diagnoses
        // profileImage:uploadedImageUrl,
      }

      console.log(datatoBackend, 'test')

      updatePrescription(datatoBackend)
      .unwrap() // Unwrap the result to access the data directly
      .then((userData) => {
        console.log(userData, 'userData')
        // Handle successful login
        setButtonText('Prescription updataed successfully');
        if(!userData){
          setButtonText(error?error:'Update Prescription');
        // return setError(data.detail);
        }
       
      })
    
    } catch (err) {
      console.log(err);
      setButtonText("Edit");
      if (err.response) {
        // return setError(err.response.data.msg);
        console.log(err.responsedata)
        return setError('something went wrong');
      }
      return setError("There has been an error.");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>Error: {errors.message}</div>;
  }

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <FormControl>
    <Grid
    mb='20px'
    
    templateColumns={{
      base: "1fr",
      lg: "repeat(1, 1fr)",
      "2xl": "1.34fr 1.62fr 1fr",
    }}
    gap={{ base: "20px", xl: "20px" }}
    >
      <div>
<FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Drug Name<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            placeholder={data?.drugName}
            mb='24px'
            fontWeight='500'
            size='lg'
            onChange={(event) => {
              setDrugName(event.target.value)
              setError(undefined);
            }}
          />
</div>
<div>
<FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          mb='8px'>
          Precsription<Text color={brandStars}>*</Text>
        </FormLabel>
        <Input
          isRequired={true}
          variant='auth'
          fontSize='sm'
          ms={{ base: "0px", md: "0px" }}
          type='weight'
          placeholder={data?.prescription}
          mb='24px'
        //   defaultValue={weight}
          fontWeight='500'
          size='lg'
          onChange={(event) => {
            setPrescription(event.target.value);
            setError(undefined);
          }}
        />
</div>
     
</Grid>
<Grid
    mb='20px'
    
    templateColumns={{
      base: "1fr",
      lg: "repeat(1, 1fr)",
      "2xl": "1.34fr 1.62fr 1fr",
    }}
    gap={{ base: "20px", xl: "20px" }}
    >

        <div>
        <Button colorScheme="blue" ml={3} onClick={()=> onOpen(true)}>{username?username:'Select Patient'}</Button>
        </div>  
      
     
    
        <div>
        <Button colorScheme="blue" ml={3} onClick={()=> onOpenDoc(true)}>{doctorName?doctorName:'Select Doctor'}</Button>
        </div> 
        <div>
        <Button colorScheme="blue" ml={3} onClick={()=> onOpenDiagnostic(true)}>{diagnosticName?diagnosticName:'Select Diagnosis ID'}</Button>
        </div>  
      
     
    
        <div>
        <Button colorScheme="blue" ml={3} onClick={()=> onOpenPharmacy(true)}>{pharmName?pharmName:'Select Pharmacy'}</Button>
        </div> 

      </Grid>
<div>
        <Box mt={4}>
            <ListModal
            isOpen={isOpen}
            onClose={onClose}
            filteredPatients={filteredPatients}
            setPatientId={setPatientId}
            setSearch={setSearch}
            handleSearch={handleSearch}
            handleFilter={handleFilter}
            setError={setError}
            search={search}
            setUsername={setUsername}
            />
    </Box>
  
        </div>
        <div>
           <Box mt={4}>
            <DiagnosticListModal
            isOpen={isDiagnostic}
            onClose={onCloseDiagnosis}
            filteredPatients={filteredDiagnostic}
            setPatientId={setDiagnosticId}
            setSearch={setSearchDiagnostic}
            handleSearch={handleSearchDiagnostic}
            handleFilter={handleSearchDiagnosticFilter}
            setError={setError}
            search={searchDiagnostic}
            setUsername={setDiagnosticName}
            />
    </Box>
  
        </div>
        <div>
           <Box mt={4}>
            <PharmListModal
            isOpen={isPharm}
            onClose={onClosePharmacy}
            filteredPatients={filteredPharmacy}
            setPatientId={setPharmId}
            setSearch={setSearchDiagnostic}
            handleSearch={handleSearchPharm}
            handleFilter={handlesearchPharmacyFilter}
            setError={setError}
            search={searchPharm}
            setUsername={setPharmName}
            />
    </Box>
  
        </div>
        <div>
        <Box mt={4}>
            <ListDoctorModal
            isOpen={isOpenDoc}
            onClose={onCloseDoc}
            filteredPatients={filteredDoctors}
            setPatientId={setDoctorId}
            setSearch={setSearchDoc}
            handleSearch={handleSearchDoc}
            handleFilter={handleFilterDoc}
            setError={setError}
            search={searchDoc}
            setUsername={setDoctorName}
            />
    </Box>
  
        </div>
<Button
            fontSize='sm'
            variant='brand'
            fontWeight='500'
            w='100%'
            h='50'
            mb='24px'
            onClick={register}
          >
            {buttonText}
          </Button>
</FormControl>
    
    </Box>
 
</Card>
  )
}

export default EditForm

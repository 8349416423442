// api/users.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";







export const transactionsApi = createApi({
  reducerPath:'transactions',
  tagTypes: ['transactions'],
  baseQuery: fetchBaseQuery({baseUrl: `${API_SERVER}/api`,
  prepareHeaders: (headers, { getState }) => {
    const {authSlice:{token}} = getState();
 
    console.log(token , 'state token');
    let useToken = localStorage.getItem('user');
    const mytoken =JSON.parse(useToken).token
    if (token || mytoken) {
      headers.set('Authorization', `Token ${mytoken?mytoken:token}`);
    }
    return headers;
  },

}), // Replace with your API base URL
endpoints: (builder) => ({
    getTransaction: builder.query({
      query: (id) => `/transactions/records/${id}/`, // Replace with your backend endpoint
      headers:{'Content-Type': 'application/json'},
    }),
    // providesTags:['patients'],
    getAllTransactions: builder.query({
      query: () =>({
        url:`/transactions/records/`,
        headers:{'Content-Type': 'application/json'},
      }),
      transformErrorResponse:res=>res.sort((a,b)=> b.id - a.id),
      invalidatesTags: ['transactions'],
    }),
    addTransaction: builder.mutation({
      query:(patient)=>({
        url:'/transactions/records/',
        method: 'POST',
        body:patient,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['transactions'],
    }),
    updateTransaction:builder.mutation({
      query:(patient)=>({
        url:`/transactions/records/${patient.transactionId}/`,
        method: 'PATCH',
        body:patient,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['transactions'],
    }),

 
    deleteTransaction:builder.mutation({ 
      query:(obj)=>({
        url: `/transactions/records/${obj.id}`,
        method: 'DELETE',
        body:obj,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['transactions'],

    }),
  })

});

export const { useGetTransactionQuery, useGetAllTransactionsQuery, useAddTransactionMutation,
   useUpdateTransactionMutation,
   useDeleteTransactionMutation} = transactionsApi;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";



export const authApi = createApi({
  reducerPath:'auth',
  tagTypes:['auth'],
  baseQuery: fetchBaseQuery({ baseUrl: API_SERVER,
    prepareHeaders: (headers, { getState }) => {
      const {authSlice:{token}} = getState();
      // console.log(token , 'state');
      if (token) {
        headers.set('Authorization', `Token ${token}`);
      }
      return headers;
    },
  }), // Replace with your API base URL
 
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/login',
        method: 'POST',
        body: credentials,
      }),
    }),

    signup: builder.mutation({
      query: (userData) => ({
        url: '/signup',
        method: 'POST',
        body: userData,
        }),
      
        invalidatesTags: ['user'],
    }),

    sendMail: builder.mutation({
      query: (mail) => ({
        url: '/send/mail',
        method: 'POST',
        body:mail
      })

    }),

    forgotPassword: builder.mutation({
      query: (email) => ({
        url: '/api/reset_passport/forgot-password/',
        method: 'POST',
        body:email
      })
    }),

    passwordConfirm: builder.mutation({
      query: (obj) => ({
        url: `/api/reset_passport/confirm-password/${obj.uid}/${obj.token}`,
        method: 'POST',
        body:{new_password: obj.password}
      })
    }),
  
    logout: builder.mutation({
      query: () => ({
        url: '/logout',
        method: 'POST',
      }),
    }),
  }),
});



// class AuthApi {
//   static Login = (data) => {
//     return axios.post(`${base}/login`, data);
//   };

//   static Register = (data) => {
//     return axios.post(`${base}/register`, data);
//   };

//   static Logout = (data) => {
//     console.log(data?.token, 'my logout')
   
//     return axios.post(`${base}/logout`,{Authorization:`Token ${data?.token}` });

     
//   };
// }

// let base = "http://127.0.0.1:8000";

// export default AuthApi;

export const { useLoginMutation, useLogoutMutation, useSignupMutation, useSendMailMutation, useForgotPasswordMutation, usePasswordConfirmMutation} = authApi;
// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue, Grid, } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import Information from "components/information/information";
import { formatDate } from "components/DateFormater/dateFormater";
import { useGetDoctorQuery } from "api/physician";
import { useGetUserQuery } from "api/user";
import { useGetCategoryQuery } from "api/categories";
import {useGetDiagnosisQuery} from "api/diagnosis";
import banner from "assets/img/auth/banner.png";

export default function Banner(props) {
  const { banner, avatar, job, posts, followers, following,data } = props;

  const {  name,  inventoriesId, description,  unit_price, is_available,  quantity,
    image,  category,  created_by, created_at} = data;
  // const{data:consultation} = useGetDoctorQuery(doctor)
  const {data:cat} = useGetCategoryQuery(category)
  // const {data:diagnosis} = useGetDiagnosisQuery(diagnoses)
  const{data:user} = useGetUserQuery(created_by)
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  console.log(cat, 'cat')
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
    <Grid
      mb='20px'
      templateColumns={{
        base: "1fr",
        lg: "repeat(1, 1fr)",
        "2xl": "1.34fr 1.62fr 1fr",
      }}
    >

<Information
        //   boxShadow={cardShadow}
          title='Drug Info'
          value={name?name:null}
        />

    </Grid>
     
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(1, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>

        <Information
        //   boxShadow={cardShadow}
          title='Drug Name'
          value={  name?name:null}
        />
         <Information
        //   boxShadow={cardShadow}
          title='Category'
          value={ cat?.name}
        />
        <Information
        //   boxShadow={cardShadow}
          title='unit price'
          value={`N${unit_price}`} 
        /> 
       
      <Information
        //   boxShadow={cardShadow}
          title='Description'
          value={description} 
        /> 
        
        <Information
        //   boxShadow={cardShadow}
          title='Created At'
          value={created_at?formatDate(created_at, 'yyyy-MM-dd') :null}
        /> 
          

        </Grid>
        <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
        >
              <Information
        //   boxShadow={cardShadow}
          title='Available'
          value={is_available?'Yes': 'No'} 
        />  
           <Information
        //   boxShadow={cardShadow}
          title='Quantity'
          value={quantity} 
        />  
   
         <Information
        //   boxShadow={cardShadow}
          title='Created By'
          value={user?`${user.first_name} ${user.last_name}`:null} 
        />   


       
        </Grid>

    </Card>
  );
}

// api/users.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";







export const prescriptionsApi = createApi({
  reducerPath:'prescriptions',
  tagTypes: ['prescriptions'],
  baseQuery: fetchBaseQuery({baseUrl: `${API_SERVER}/api`,
  prepareHeaders: (headers, { getState }) => {
    const {authSlice:{token}} = getState();
 
    console.log(token , 'state token');
    let useToken = localStorage.getItem('user');
    const mytoken =JSON.parse(useToken).token
    if (token || mytoken) {
      headers.set('Authorization', `Token ${mytoken?mytoken:token}`);
    }
    return headers;
  },

}), // Replace with your API base URL
endpoints: (builder) => ({
    getPrescription: builder.query({
      query: (id) => `/prescriptions/prescriptions/${id}/`, // Replace with your backend endpoint
      headers:{'Content-Type': 'application/json'},
    }),
    // providesTags:['patients'],
    getAllPrescriptions: builder.query({
      query: () =>({
        url:`/prescriptions/prescriptions/`,
        headers:{'Content-Type': 'application/json'},
      }),
      transformErrorResponse:res=>res.sort((a,b)=> b.id - a.id),
      invalidatesTags: ['prescriptions'],
    }),
    addPrescription: builder.mutation({
      query:(patient)=>({
        url:'/prescriptions/prescriptions/',
        method: 'POST',
        body:patient,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['prescriptions'],
    }),
    updatePrescription:builder.mutation({
      query:(patient)=>({
        url:`/prescriptions/prescriptions/${patient.prescriptionId}/`,
        method: 'PATCH',
        body:patient,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['prescriptions'],
    }),

    updatePrescriptionStatus: builder.mutation({
      query:(obj)=>({
        url:`/prescriptions/prescriptions/status/${obj.prescriptionId}/`,
        method: 'PATCH',
        body:obj,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['prescriptions'],
    }),
    deletePrescription:builder.mutation({ 
      query:(obj)=>({
        url: `/prescriptions/prescriptions/${obj.id}`,
        method: 'DELETE',
        body:obj,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['prescriptions'],

    }),
  })

});

export const { useGetPrescriptionQuery, useGetAllPrescriptionsQuery, useAddPrescriptionMutation,
   useUpdatePrescriptionMutation, useUpdatePrescriptionStatusMutation,
   useDeletePrescriptionMutation} = prescriptionsApi;
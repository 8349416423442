import React,{useCallback, useState} from 'react'
import Card from "components/card/Card.js"
import FileUploadComponent from "../../../auth/signUp/fileupload";
import {   Box,
    Grid,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Textarea,
    UnorderedList, 
     ListItem,
    Input,
    Select,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue, } from "@chakra-ui/react";
    import { useAuth } from "auth-context/auth.context";
    import { MdOutlineRemoveRedEye } from "react-icons/md";
    import {useAddDiagnosisMutation} from 'api/diagnosis';
    import { useGetAllSpecialisationsQuery } from 'api/specialisation';
    import { useGetAllPatientsQuery } from 'api/patient';
import ListModal from './listComponent'


function DiagnosisForm() {

  const [addDiagnosis, {isLoading, errors }] = useAddDiagnosisMutation();
// const {data:specialisation, isError} = useGetAllSpecialisationsQuery()
const { data:patients, } = useGetAllPatientsQuery()
const { user:{user} } = useAuth();
const [symptoms, setSymptoms] = useState('');
const [causes, setCauses] = useState('');
const [prognosis, setPrognosis] = useState('');
const [severity, setSeverity] = useState('mild');
const [location, setLocation] = useState('');
const [name, setName] = useState('');
const[patientId,  setPatientId] = useState('');
const [search, setSearch] = useState('');
const [diagnoses, setDiagnoses] = useState('medical condition');
const [remarks, setRemarks] = useState('');
const [prescription, setPrescription] = useState('');
const [filteredPatients, setFilteredPatients] = useState('')
const [username, setUsername] = useState('');
const [isOpen, setIsOpen] = useState(false);

const onClose = () => setIsOpen(false);
const onOpen = () => setIsOpen(true);

const [error, setError] = useState(undefined);
const [buttonText, setButtonText] = useState("Add Diagnosis");
const [uploadedImageUrl, setUploadedImageUrl] = useState('')
console.log(symptoms, 'img')

const textColor = useColorModeValue("navy.700", "white");

const brandStars = useColorModeValue("brand.500", "brand.400");
const errorText = useColorModeValue("red.500", "red.400");

const  handleSearch =(e)=>{
  setSearch(e)
}


const handleFilter = (searchTerm) => {
  // Update the query key to include the search term
  searchTerm.toLowerCase().trim();

  if(searchTerm !=="" && patients) {

   const filterobj = patients.filter(item => item.name.toLowerCase().includes(searchTerm))

   console.log(filterobj)

    setFilteredPatients(filterobj)
  

  }else{
    setFilteredPatients([]);
  }

};


// console.log(user, 'profile')

// const [show, setShow] = React.useState(false);


// const [selectedOption, setSelectedOption] = useState(Number(3));

// console.log(selectedOption, 'profile')

const handleSeverity =(event)=>{
  setSeverity(event.target.value)
}
const handleSelectChange = (event) => {
    // setSelectedOption(event.target.value);
    setDiagnoses(event.target.value)
  };
//   const handleClick = () => setShow(!show);\



  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }

    if (patientId=== "") {
      return setError(`Field must not be empty.`);
    }
  

    
    try {
      setButtonText(isLoading?'Loading...':'Add Vitalsign');
      const datatoBackend ={
        symptoms:symptoms,
        causes:causes,
        prognosis:prognosis,
        diagnosis_type:diagnoses,
        doctorRemark:remarks,
        severity: severity,
        location:location,
        doctor:Number(user.id),
        name:Number(patientId),
        prescription,
        createdBy: Number(user.id),
        // profileImage:uploadedImageUrl,
      }

      console.log(datatoBackend, 'test')

      addDiagnosis(datatoBackend)
      .unwrap() // Unwrap the result to access the data directly
      .then((userData) => {
        console.log(userData, 'userData')
        // Handle successful login
        setButtonText('Diagnosis added successfully');
        setSearch('')
        if(!userData){
          setButtonText(error?error:'Edit Patient');
        // return setError(data.detail);
        }
       
      })

    } catch (err) {
      console.log(err);
      setButtonText("Edit");
      if (err.response) {
        // return setError(err.response.data.msg);
        console.log(err.response.data)
        return setError('something went wrong');
      }
      return setError("There has been an error.");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>Error: {errors.message}</div>;
  }

 

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <FormControl>
        <Grid
        mb='20px'
        
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        
    
          <div>
<FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Symptoms<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
     <Textarea
      placeholder="Symptoms ..."
      size="md" // Size of the textarea: "sm", "md", "lg"
      resize="vertical" // Allow vertical resizing: "none", "vertical", "horizontal"
      focusBorderColor="blue.500" // Border color when focused
      borderColor="gray.300" // Border color
      onChange={(event) => {
        setSymptoms(event.target.value)
        setError(undefined);
      }}
    />
</div>
<div>
<FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Causes<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
            </FormLabel>
            <Textarea
      placeholder="Causes ..."
      size="md" // Size of the textarea: "sm", "md", "lg"
      resize="vertical" // Allow vertical resizing: "none", "vertical", "horizontal"
      focusBorderColor="blue.500" // Border color when focused
      borderColor="gray.300" // Border color
      onChange={(event) => {
        setCauses(event.target.value)
        setError(undefined);
      }}
    />
</div>
         <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Progonisis<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Textarea
      placeholder="Prognosis ..."
      size="md" // Size of the textarea: "sm", "md", "lg"
      resize="vertical" // Allow vertical resizing: "none", "vertical", "horizontal"
      focusBorderColor="blue.500" // Border color when focused
      borderColor="gray.300" // Border color
      onChange={(event) => {
        setPrognosis(event.target.value)
        setError(undefined);
       
      }}
    />
            </div>
              <div>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Type of Diagnosis<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Select value={diagnoses} onChange={handleSelectChange}  mb='24px'>
              
                    <option value='medical condition' >Medical Condition</option>
                    <option value='disease'>Disease</option>
                    <option value='disorder'>Disorder</option>
                    <option value='others'>Others</option>
      </Select>
      </div>
      <div>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Severity<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Select value={severity} onChange={handleSeverity}  mb='24px'>
              
                    <option value='mild' >Mild</option>
                    <option value='moderate'>Moderate</option>
                    <option value='severe'>Severe</option>
      </Select>
      </div>
      <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Doctor Remar<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Textarea
      placeholder="Doctor Remark ..."
      size="md" // Size of the textarea: "sm", "md", "lg"
      resize="vertical" // Allow vertical resizing: "none", "vertical", "horizontal"
      focusBorderColor="blue.500" // Border color when focused
      borderColor="gray.300" // Border color
      onChange={(event) => {
        setRemarks(event.target.value)
        setError(undefined);
      }}
    />
            </div>
            <div>
      <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Location<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <InputGroup size='md'>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={''}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setLocation(event.target.value);
                  setError(undefined);
                }}
              />
              </InputGroup>

              
        
      </div>
      <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Prescription<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Textarea
      placeholder="Prescription..."
      size="md" // Size of the textarea: "sm", "md", "lg"
      resize="vertical" // Allow vertical resizing: "none", "vertical", "horizontal"
      focusBorderColor="blue.500" // Border color when focused
      borderColor="gray.300" // Border color
      onChange={(event) => {
        setPrescription(event.target.value)
        setError(undefined);
      }}
    />
            </div>  
             
</Grid>
<Grid
  mb='20px'
        
  templateColumns={{
    base: "1fr",
    lg: "repeat(1, 1fr)",
    "2xl": "1.34fr 1.62fr 1fr",
  }}
>
            <div>
              <Button colorScheme="blue" ml={3} onClick={()=> onOpen(true)}>{username?username:'Select Patient'}</Button>
            </div>  
            </Grid>
            <div>
            <Box mt={4}>
                <ListModal
                isOpen={isOpen}
                onClose={onClose}
                filteredPatients={filteredPatients}
                setPatientId={setPatientId}
                setSearch={setSearch}
                handleSearch={handleSearch}
                handleFilter={handleFilter}
                setError={setError}
                search={search}
                setUsername={setUsername}
                />
        </Box>
      
            </div>
<Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                onClick={register}
              >
                {buttonText}
              </Button>
</FormControl>
        
        </Box>
     
    </Card>
  )
}

export default DiagnosisForm

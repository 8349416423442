import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Avatar,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Profile from "assets/img/avatars/avatar6.png"

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { useGetAllRolesQuery} from "api/role"

// Assets
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
export default function ColumnsTable(props) {
  const { columnsData, tableData } = props;
  const {data:roles} =  useGetAllRolesQuery();

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");


  const displayRole =  (num)=>{
    let myrole ='';
    if(num && roles){
      roles && roles.map(role =>{
        if(role.id === num)
        myrole = role?.name
      })

    }
      return myrole;
  }

 

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='10px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Users
        </Text>
        <Menu />
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
              
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "AVATAR") {
                    
                    data = (
                      <Avatar
                      mx='auto'
                      src={row.original.imageUrl?row.original.imageUrl:Profile}
                      h='50px'
                      w='50px'
                      mt='1px'
                      border='4px solid'
                      borderColor={borderColor}
                    />
                    );
                  } else if (cell.column.Header === "ID") {
                    data = (
                      <Flex align='center'>

                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {row.original.id}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "STATUS") {

                 
                    data = (
                      <Flex align='center'>

                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {row.original.is_active? "active":"disabled"}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "FIRSTNAME") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                       {row.original.first_name}
                      </Text>
                    );
                  } else if (cell.column.Header === "LASTNAME") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {row.original.last_name}
                     </Text>
                    );
                  }else if(cell.column.Header === "EMAIL") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {row.original.email}
                     </Text>
                    );
                  }else if(cell.column.Header === "ROLE") {
                    // console.log(row.original.role, 'org')
                    // console.log(displayRole(row.original?.role), 'rl')
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {displayRole(row.original?.role)}
                     </Text>
                    )
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      maxH='30px !important'
                      py='8px'
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}

import React,{useState, useEffect} from 'react'
import {
    Avatar,
    Box,
    Flex,
    FormLabel,
    Icon,
    Select,
    Text,
    SimpleGrid,
    useColorModeValue,
  } from "@chakra-ui/react"
 
  import {
    columnsDataCheck,
    columnsDataComplex,
  } from "./components/columnData";

  import ComplexTable from "./components/complexTable"
  import { useGetUserQuery, useGetAllUsersQuery } from "api/user";
  import IconBox from "components/icons/IconBox";
  import AddUserModal from "./components/AddUserModal";
  import {
    MdAddTask,
    // MdAttachMoney,
    // MdBarChart,
    // MdFileCopy,
  } from "react-icons/md";

function Users() {
    const [isOpen, setIsOpen] = useState(false);
    const [isAddUserOpen, setIsAddUserOpen] = useState(false);
    const [isDelete, setDelete] = useState(false)
    const [isModified, setModified] = useState(false)
    const brandColor = useColorModeValue("brand.500", "white");
    let mainText = useColorModeValue("navy.700", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const [userID, setUserId] = useState()
    const onClose = () => {setIsOpen(false); setDelete(false)};
    const onOpen = () => setIsOpen(true);
    const onOpenAddUser = () => setIsAddUserOpen(true);
    const onCloseAddUser = () => setIsAddUserOpen(false);
    const {data:users, error, isLoading} = useGetAllUsersQuery();
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    const [usersState, setUsersState] = useState([])

    // const [currentPage, setCurrentPage] = useState(1);


    // const pageSize = 5;
    // const lastIndex = currentPage * pageSize;
    // const firstIndex = lastIndex - pageSize;

    // const startIndex = currentPage * itemsPerPage;
    // const endIndex = startIndex + itemsPerPage;


  //  const nPage =Math.ceil(users && users.length / pageSize);



   useEffect(()=>{
    setUsersState(users)
   }, [isLoading, isModified])



  //  const handlePageChange = (page) => {
  //   setCurrentPage(page);
  //   // You can also fetch data for the new page here
  // };


  
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
      );
      const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.100" }
      );

    // console.log(users, 'allusers')
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    if (!users) {
      return <div>User not found.</div>;
    }

  const tableData = ()=>{
  
    let result=[];


    
   users && users.map(user =>{
      const{id, first_name, last_name, email, imageUrl, is_active, username, occupation, role, last_login, date_joined, createdBy} = user;
      result.push({ imageUrl, id, first_name, last_name, email,  is_active, username, occupation, role, last_login, date_joined, createdBy})
    })

    return result;
  }


  console.log(usersState, 'usersState')



    // if(users?.length >0){

  
  return (
 <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
 <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px' mt="30px">
 <SimpleGrid 
     _hover={bgHover}
     _focus={bgFocus}
     _active={bgFocus}
     onClick={onOpenAddUser}
     >
  <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAddTask} color={brandColor} />
              }
            />
     <Text
            color={textColor}
            fontSize='14px'
            fontWeight='700'
            lineHeight='100%'>
            Add user
          </Text>
     </SimpleGrid>

 
      <ComplexTable
      columnsData={columnsDataComplex}
      tableData={tableData()}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      userID={userID}
      isDelete={isDelete}
      setDelete={setDelete}
      isModified={isModified}
      setModified={setModified}

    />
      
   
      
        <AddUserModal 
        isOpen={isAddUserOpen}
        onClose={onCloseAddUser}
        onOpen={onOpenAddUser}
        userID={userID}
        isModified={isModified}
        setModified={setModified}
          />




        {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid> */}
      </SimpleGrid>

 </Box>
  )
// }

// else{
//   return <div>Loadining....  </div>
// }
}

export default Users
import React,{useState} from 'react'
import Card from "components/card/Card.js"
import FileUploadComponent from "../../../auth/signUp/fileupload";
import {   Box,
    Grid,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    Select,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue, } from "@chakra-ui/react";
    import { MdOutlineRemoveRedEye } from "react-icons/md";
    import {useUpdateNurseMutation, useGetNurseQuery} from 'api/nurse';
import { RiEyeCloseLine } from "react-icons/ri";


function EditForm({id}) {

  const { data, isLoading, errors } = useGetNurseQuery(id);
const [updateNurse, {isSuccess}] = useUpdateNurseMutation();
const [fullname, setName] = useState(data.fullname);
const [email, setEmail] = useState(data.email);
const [phone, setPhone] = useState(data?.phone);
const [address, setAddress] = useState(data?.address);
const [occupation, setOccupation] = useState("");
const [error, setError] = useState(undefined);
const [buttonText, setButtonText] = useState("Edit Nurse");
const [uploadedImageUrl, setUploadedImageUrl] = useState('')
console.log(fullname, 'img')
const textColor = useColorModeValue("navy.700", "white");
const textColorSecondary = "gray.400";
const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
const textColorBrand = useColorModeValue("brand.500", "white");
const brandStars = useColorModeValue("brand.500", "brand.400");
const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
const googleText = useColorModeValue("navy.700", "white");
const googleHover = useColorModeValue(
  { bg: "gray.200" },
  { bg: "whiteAlpha.300" }
);
const googleActive = useColorModeValue(
  { bg: "secondaryGray.300" },
  { bg: "whiteAlpha.200" }

);
// let { user } = useAuth();
// console.log(user.user.id, 'profile')

const [show, setShow] = React.useState(false);


const [selectedOption, setSelectedOption] = useState(data.occupation);


const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
//   const handleClick = () => setShow(!show);
  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }
    
    try {
      setButtonText(isLoading?'Loading...':'Edit Nurse');
      const datatoBackend ={
        nurseID:data.nurseID,
        name:fullname?fullname:data?.name,
        email:email?email:data?.email,
        contact:address?address:data?.contact,
        phone:phone?phone:data?.phone,
        // profileImage:uploadedImageUrl,
      }

      console.log(datatoBackend, 'test')

      updateNurse(datatoBackend)
      .unwrap() // Unwrap the result to access the data directly
      .then((userData) => {
        console.log(userData, 'userData')
        // Handle successful login
        setButtonText('Nurse updated');
        if(!userData){
          setButtonText(error?error:'Edit Patient');
        // return setError(data.detail);
        }
       
      })

   
    
    } catch (err) {
      console.log(err);
      setButtonText("Edit");
      if (err.response) {
        // return setError(err.response.data.msg);
        console.log(err.responsedata)
        return setError('something went wrong');
      }
      return setError("There has been an error.");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>Error: {errors.message}</div>;
  }

  if (!data) {
    return <div>User not found.</div>;
  }


  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <FormControl>
        <Grid
        mb='20px'
        
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
        >
          <div>
<FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                FullName<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.name}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setName(event.target.value)
                  setError(undefined);
                }}
              />
</div>
<div>
<FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder={data?.email}
              mb='24px'
            //   defaultValue={email}
              fontWeight='500'
              size='lg'
              onChange={(event) => {
                setEmail(event.target.value);
                setError(undefined);
              }}
            />
</div>
         <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Address<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.contact}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setAddress(event.target.value);
                  setError(undefined);
                }}
              />
            </div>



<FormLabel
               
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                ProfileImage<Text color={brandStars}>*</Text>
                <FileUploadComponent uploadedImageUrl={uploadedImageUrl} setUploadedImageUrl={setUploadedImageUrl}/>
              </FormLabel> 
              {/* <div>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Occupation<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select value={selectedOption} onChange={handleSelectChange}  mb='24px'>
        <option value="nurse">Nurse</option>
        <option value="pharmacy">Pharmacy</option>
        <option value="doctor">Doctor</option>
        <option value="other">Other</option>
      </Select>
      </div> */}
      <div>
      <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Phone<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.phone}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setPhone(event.target.value);
                  setError(undefined);
                }}
              />
              
              </InputGroup>
      </div>
         
             

</Grid>
<Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                onClick={register}
              >
                {buttonText}
              </Button>
</FormControl>
        
        </Box>
     
    </Card>
  )
}

export default EditForm

// api/users.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";







export const doctorsApi = createApi({
  reducerPath:'doctors',
  tagTypes: ['doctors'],
  baseQuery: fetchBaseQuery({baseUrl: `${API_SERVER}/api`,
  prepareHeaders: (headers, { getState }) => {
    const {authSlice:{token}} = getState();
 
    console.log(token , 'state token');
    let useToken = localStorage.getItem('user');
    const mytoken =JSON.parse(useToken).token
    if (token || mytoken) {
      headers.set('Authorization', `Token ${mytoken?mytoken:token}`);
    }
    return headers;
  },

}), // Replace with your API base URL
endpoints: (builder) => ({
    getDoctor: builder.query({
      query: (id) => `/doctors/doctors/${id}/`, // Replace with your backend endpoint
      headers:{'Content-Type': 'application/json'},
    }),
    // providesTags:['patients'],
    getAllDoctors: builder.query({
      query: () =>({
        url:`/doctors/doctors/`,
        headers:{'Content-Type': 'application/json'},
      }),
      transformErrorResponse:res=>res.sort((a,b)=> b.id - a.id),
      invalidatesTags: ['doctors'],
    }),
    addDoctor:builder.mutation({
      query:(patient)=>({
        url:'/doctors/doctors/',
        method: 'POST',
        body:patient,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['doctors'],
    }),
    updateDoctor:builder.mutation({
      query:(patient)=>({
        url:`/doctors/doctors/${patient.doctorID}/`,
        method: 'PATCH',
        body:patient,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['doctors'],
    }),
    deleteDoctor:builder.mutation({ 
      query:(obj)=>({
        url: `/doctors/doctors/${obj.id}`,
        method: 'DELETE',
        body:obj,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['doctors'],

    }),
  })

});

export const { useGetDoctorQuery, useGetAllDoctorsQuery, useAddDoctorMutation,
   useUpdateDoctorMutation, 
   useDeleteDoctorMutation} = doctorsApi;
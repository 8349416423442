import React,{useState} from 'react'
import Card from "components/card/Card.js"
import FileUploadComponent from "../../../auth/signUp/fileupload";
import {   Box,
    Grid,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    Select,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue, } from "@chakra-ui/react";
    import { MdOutlineRemoveRedEye } from "react-icons/md";
    import {useGetVitalsignQuery, useUpdateVitalsignMutation, useDeleteVitalsignMutation} from 'api/vitalsign';
    import { useGetAllSpecialisationsQuery } from 'api/specialisation';
import { RiEyeCloseLine } from "react-icons/ri";
import { vitalsignsApi } from 'api/vitalsign';


function EditForm({id}) {

  const { data, isLoading, errors } = useGetVitalsignQuery(id);
const [updateVitalsign, {isSuccess, error:updateError}] = useUpdateVitalsignMutation();
const {data:specialisation, isError} = useGetAllSpecialisationsQuery()
const [bloodPressure, setBloodPressure] = useState(data.bloodPressure);
const [weight, setWeight] = useState(data.weight);
const [painLevel, setPain] = useState(data?.painLevel);
const [blood_glucose_level, setGlucoseLevel] = useState(data?.blood_glucose_level);
const [urine_level, setUrineLevel] = useState(data?.urine_level);
const [capillary_refill_time, setCapillaryRefillTime] = useState(data?.capillary_refill_time);
const [pupil_repsonse, setPupilResponse] = useState(data?.pupil_repsonse);
const[pulse_oximetry, setPulseOximetry] = useState(data?.pulse_oximetry);
const[pain_assessment, setPainessment] = useState(data?.pain_assessment);
const [pulseRate, setPulseRate] = useState(data?.pulse_rate);
const [height, setHeight] = useState(data?.height);
const[temperature, setTemperature] = useState(data?.temperature);




const [error, setError] = useState(undefined);
const [buttonText, setButtonText] = useState("Edit VitalSign");
const [uploadedImageUrl, setUploadedImageUrl] = useState('')
console.log(bloodPressure, 'img')

const textColor = useColorModeValue("navy.700", "white");
const textColorSecondary = "gray.400";
const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
const textColorBrand = useColorModeValue("brand.500", "white");
const brandStars = useColorModeValue("brand.500", "brand.400");
const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
const googleText = useColorModeValue("navy.700", "white");
const googleHover = useColorModeValue(
  { bg: "gray.200" },
  { bg: "whiteAlpha.300" }
);
const googleActive = useColorModeValue(
  { bg: "secondaryGray.300" },
  { bg: "whiteAlpha.200" }

);


// let { user } = useAuth();
console.log(specialisation, 'profile')

const [show, setShow] = React.useState(false);


const [selectedOption, setSelectedOption] = useState(Number(specialisation?.specialisationID));


const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
//   const handleClick = () => setShow(!show);
  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }
    
    try {
      setButtonText(isLoading?'Loading...':'Edit Vitalsign');
      const datatoBackend ={
        vitalSignId:data.vitalSignId,
        bloodPressure:bloodPressure?bloodPressure:data?.bloodPressure,
        weight:weight?weight:data?.weight,
        blood_glucose_level:blood_glucose_level?blood_glucose_level:data?.blood_glucose_level,
        painLevel:painLevel?painLevel:data?.painLevel,
        specilisation:selectedOption?selectedOption:specialisation?.specialisationID,
        urine_level: urine_level?urine_level:data?.urine_level,
        capillary_refill_time: capillary_refill_time?capillary_refill_time:data?.capillary_refill_time,
        pupil_repsonse: pupil_repsonse?pupil_repsonse:data?.pupil_repsonse,
        pulse_oximetry: pulse_oximetry?pulse_oximetry:data?.pulse_oxiimetry,
        pain_assessment: pain_assessment?pain_assessment:data?.pain_assessment,
        pulseRate: pulseRate?pulseRate:data?.pulseRate,
        height: height?height:data?.height,
        temperature: temperature?temperature:data?.temperature,
        // profileImage:uploadedImageUrl,
      }

      console.log(datatoBackend, 'test')

      updateVitalsign(datatoBackend)
      .unwrap() // Unwrap the result to access the data directly
      .then((userData) => {
        console.log(userData, 'userData')
        // Handle successful login
        setButtonText('Vitalsign updated');
        if(!userData){
          setButtonText(updateError?updateError.data:'Edit Vitalsign');
        // return setError(data.detail);
        }
       
      })

   
      vitalsignsApi.endpoints.updateVitalsign.invalidates([]);
    } catch (err) {
      console.log(err);
      setButtonText(updateError.data);
      if (err.response) {
        // return setError(err.response.data.msg);
        console.log(err.responsedata)
        return setError('something went wrong');
      }
      return setError("There has been an error.");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>Error: {errors.message}</div>;
  }

  if (!data) {
    return <div>User not found.</div>;
  }


  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <FormControl>
        <Grid
        mb='20px'
        
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
        >
          <div>
<FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Blood Pressure<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.bloodPressure}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setBloodPressure(event.target.value)
                  setError(undefined);
                }}
              />
</div>
<div>
<FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Weight<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='weight'
              placeholder={data?.weight}
              mb='24px'
            //   defaultValue={weight}
              fontWeight='500'
              size='lg'
              onChange={(event) => {
                setWeight(event.target.value);
                setError(undefined);
              }}
            />
</div>
         <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Blood Glucose Level<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.blood_glucose_level}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setGlucoseLevel(event.target.value);
                  setError(undefined);
                }}
              />
            </div>



{/* <FormLabel
               
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                ProfileImage<Text color={brandStars}>*</Text>
                <FileUploadComponent uploadedImageUrl={uploadedImageUrl} setUploadedImageUrl={setUploadedImageUrl}/>
              </FormLabel>  */}
              <div>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Type of Consultation<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select value={selectedOption} onChange={handleSelectChange}  mb='24px'>
                {
                  specialisation && specialisation.map(role =>(
                    <option value={role.id} key={role.id}>{role.name}</option>
                  ))
                }
       
       
      </Select>
      </div>
      <div>
      <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Pain Level<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.painLevel}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setPain(event.target.value);
                  setError(undefined);
                }}
              />


              
              </InputGroup>

              
        
      </div>
      <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Temperature<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.temperature}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setTemperature(event.target.value);
                  setError(undefined);
                }}
              />
            </div>
            <div>
      <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Urine Level<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.urine_level}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setUrineLevel(event.target.value);
                  setError(undefined);
                }}
              />


              
              </InputGroup>

              
        
      </div>
      <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Height<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.height}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setHeight(event.target.value);
                  setError(undefined);
                }}
              />
            </div>  
             
            <div>
      <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Pulse Oximetry<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.pulse_oximetry}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setPulseOximetry(event.target.value);
                  setError(undefined);
                }}
              />


              
              </InputGroup>

              
        
      </div>
      <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Pupil Repsonse<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.pupil_repsonse}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setPupilResponse(event.target.value);
                  setError(undefined);
                }}
              />
            </div> 
            <div>
      <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Capillary Refill Time<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.capillary_refill_time}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setCapillaryRefillTime(event.target.value);
                  setError(undefined);
                }}
              />


              
              </InputGroup>

              
        
      </div>
      <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Pain Assessment<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.pain_assessment}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setPainessment(event.target.value);
                  setError(undefined);
                }}
              />
            </div>   

            <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Pulse Rate<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.pulseRate}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setPulseRate(event.target.value);
                  setError(undefined);
                }}
              />
            </div>  
</Grid>
<Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                onClick={register}
              >
                {buttonText}
              </Button>
</FormControl>
        
        </Box>
     
    </Card>
  )
}

export default EditForm

// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue, Grid, } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import Information from "components/information/information";
import { formatDate } from "components/DateFormater/dateFormater";
import { useGetSpecialisationQuery } from "api/specialisation";
import { useGetUserQuery } from "api/user";
import { useGetPatientQuery } from "api/patient";
import banner from "assets/img/auth/banner.png";

export default function Banner(props) {
  const { banner, avatar, job, posts, followers, following,data } = props;
  const {bloodPressure, weight, vitalSignId, blood_glucose_level, urine_level, capillary_refill_time, pupil_repsonse,
    pulse_oximetry, specialisation, height, patient,comment_notes, pulseRate, createdBy,temperature, createdAt, gender, email} = data;
  const{data:consultation} = useGetSpecialisationQuery(specialisation)
  const {data:patientName} = useGetPatientQuery(patient)
  const{data:user} = useGetUserQuery(createdBy)
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  console.log(user, 'nurse')
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
    <Grid
      mb='20px'
      templateColumns={{
        base: "1fr",
        lg: "repeat(1, 1fr)",
        "2xl": "1.34fr 1.62fr 1fr",
      }}
    >

<Information
        //   boxShadow={cardShadow}
          title='Patient Info'
          value={patientName?patientName.name:null}
        />

    </Grid>
     
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(3, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>

        <Information
        //   boxShadow={cardShadow}
          title='Blood Pressure'
          value={bloodPressure}
        />
         <Information
        //   boxShadow={cardShadow}
          title='Weight'
          value={weight}
        />
         {/* <Information
        //   boxShadow={cardShadow}
          title='Date of Birth'
          value={dateOfBirth}
        /> */}
         <Information
        //   boxShadow={cardShadow}
          title='Height'
          value={height}
        />
          {/* <Information
        //   boxShadow={cardShadow}
          title='Date of Birth'
          value={dateOfBirth}
        /> */}
         <Information
        //   boxShadow={cardShadow}
          title='Urine Level'
          value={urine_level}
        />
           <Information
        //   boxShadow={cardShadow}
          title='Temperature'
          value={temperature} 
        />
           <Information
        //   boxShadow={cardShadow}
          title='Pulse Rate'
          value={pulseRate}
        />

          <Information
        //   boxShadow={cardShadow}
          title='Blood Glucose'
          value={blood_glucose_level} 
          
        />    
         <Information
        //   boxShadow={cardShadow}
          title='Capillary Refill Time'
          value={capillary_refill_time} 
        />   
        
        <Information
        //   boxShadow={cardShadow}
          title='Purpose response'
          value={pupil_repsonse} 
        />  
      

        </Grid>
        <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
        >
           
<Information
        //   boxShadow={cardShadow}
          title='Consultation'
          value={consultation?consultation.name:null} 
        />    
         <Information
        //   boxShadow={cardShadow}
          title='Created By'
          value={user?`${user.first_name} ${user.last_name}`:null} 
        />   

<Information
        //   boxShadow={cardShadow}
          title='Pulse Oxymetry'
          value={pulse_oximetry} 
        />  
      <Information
        //   boxShadow={cardShadow}
          title='Created At'
          value={formatDate(createdAt, 'yyyy-MM-dd')}
        />    
        </Grid>

    </Card>
  );
}

// api/users.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";




export const nursesApi = createApi({
  reducerPath: 'nurses',
  tagTypes: ['nurses'],
  baseQuery: fetchBaseQuery({baseUrl: `${API_SERVER}/api`,
  prepareHeaders: (headers, { getState }) => {
    const {authSlice:{token}} = getState();
 
    console.log(token , 'state token');
    let useToken = localStorage.getItem('user');
    const mytoken =JSON.parse(useToken).token
    if (token || mytoken) {
      headers.set('Authorization', `Token ${mytoken?mytoken:token}`);
    }
    return headers;
  },

}), // Replace with your API base URL
endpoints: (builder) => ({
    getNurse: builder.query({
      query: (nurseId) => `/nurses/nurses/${nurseId}/`, // Replace with your backend endpoint
      headers:{'Content-Type': 'application/json'},
    }),
    invalidatesTags: ['nurses'],
    getAllNurses: builder.query({
      query: () =>({
        url:`/nurses/nurses/`,
        headers:{'Content-Type': 'application/json'},
      }),
      transformErrorResponse:res=>res.sort((a,b)=> b.id - a.id),
      invalidatesTags: ['nurses'],
    }),
    addNurse:builder.mutation({
      query:(nurse)=>({
        url:'/nurses/nurses/',
        method: 'POST',
        body:nurse,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['nurses'],
    }),
    updateNurse:builder.mutation({
      query:(nurse)=>({
        url:`/nurses/nurses/${nurse.nurseID}/`,
        method: 'PATCH',
        body:nurse,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['nurses'],
    }),
    deleteNurse:builder.mutation({ 
      query:(obj)=>({
        url: `/nurses/nurses/${obj.id}/`,
        method: 'DELETE',
        body:obj,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['nurses'],

    }),
  })

});

export const { useGetNurseQuery, useGetAllNursesQuery, useAddNurseMutation,
   useUpdateNurseMutation, 
   useDeleteNurseMutation} = nursesApi;
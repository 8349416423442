import React,{useCallback, useState} from 'react'
import Card from "components/card/Card.js"
import FileUploadComponent from "../../../auth/signUp/fileupload";
import {   Box,
    Grid,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    UnorderedList, 
     ListItem,
    Input,
    Textarea,
    Select,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue, } from "@chakra-ui/react";
import { useAuth } from "auth-context/auth.context";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import {useAddPrescriptionMutation} from 'api/prescription';
import {useGetAllInventoriesQuery} from 'api/inventories';
import { useGetAllDiagnosesQuery } from 'api/diagnosis';
import { useGetAllPharmaciesQuery } from 'api/pharmacy';
import { useGetAllPatientsQuery } from 'api/patient';
import { useGetAllDoctorsQuery } from 'api/physician';
import ListModal from "../../Diagnosis/components/listComponent";
import ListDoctorModal from "./DoctoctorListModal";
import DiagnosticListModal from "./DianosticListModal";
import PharmListModal from "./PharmModal"
import DrugListModal from './DrugListModal'



function Form({onBoxClose}) {

const [addPrescription, {isLoading, errors }] = useAddPrescriptionMutation();
const {data:inventories} = useGetAllInventoriesQuery();
const {data:diagnose, isError} = useGetAllDiagnosesQuery()
const {data:pharmacies} =  useGetAllPharmaciesQuery();
const {data:doctors} = useGetAllDoctorsQuery()
const { data:patients, } = useGetAllPatientsQuery()
const { user:{user} } = useAuth();
const [drugName, setDrugName] = useState('');
const [prescription, setPrescription] = useState('');
const [search, setSearch] = useState('');
const [searchDiagnostic, setSearchDiagnostic] = useState('');
const [diagnosticName, setDiagnosticName] = useState('');
const [patientId, setPatientId] = useState('');
const [diagnosticId, setDiagnosticId] = useState('');
const [pharmId, setPharmId] = useState('');
const [filteredPatients, setFilteredPatients] = useState('')
const [filteredDoctors, setFilteredDoctors] = useState('');
const [filteredDiagnostic, setFilteredDiagnostic] = useState('');
const [filteredPharmacy, setFilteredPharmacy] = useState('');
const [filteredPrescription, setFilteredPrescription] = useState('');
const [drugId, setDrugId] = useState('');
const [doctorId, setDoctorId] = useState('');
const [isOpen, setIsOpen] = useState(false);
const [isOpenDrug, setIsOpenDrug] = useState(false);
const [username, setUsername] = useState('');
const [pharmName, setPharmName] = useState('');
const [isDiagnostic, setDiagnostic] = useState('');
const [isPharm, setIsPharmacy] = useState(false);
const [searchDoc, setSearchDoc] = useState('');
const [searchPharm, setSearchPharmacy] = useState(false);
const [searchDrug, setSearchDrug] = useState('');
const [isOpenDoc, setIsOpenDoc] = useState(false);
const [doctorName, setDoctorName] = useState('');
const onCloseDrug =()=>setIsOpenDrug(false);
const onOpenDrug =()=>setIsOpenDrug(true);
const onClose = () => setIsOpen(false);
const onOpen = () => setIsOpen(true);
const onCloseDoc = () => setIsOpenDoc(false);
const onOpenDoc = () => setIsOpenDoc(true);
const onCloseDiagnosis = ()=> setDiagnostic(false);
const onOpenDiagnostic = ()=> setDiagnostic(true);
const onOpenPharmacy = () => setIsPharmacy(true);
const onClosePharmacy = () => setIsPharmacy(false);
const [error, setError] = useState(undefined);
const [buttonText, setButtonText] = useState("Add Prescription");
const errorText = useColorModeValue("red.500", "red.400");
const [uploadedImageUrl, setUploadedImageUrl] = useState('')
// console.log(doctors, 'doctors')

const textColor = useColorModeValue("navy.700", "white");

const brandStars = useColorModeValue("brand.500", "brand.400");

const  handleSearch =(e)=>{
  setSearch(e)
}

const  handleSearchDoc =(e)=>{
  setSearchDoc(e)
}

const handleSearchDiagnostic =(e)=>{
  setSearchDiagnostic(e)
}





const handleSearchDiagnosticFilter = (searchTerm) => {
  // Update the query key to include the search term
  searchTerm.toLowerCase().trim();

  if(searchTerm !=="" && diagnose) {

   const filterobj = diagnose.filter(item => String(item.diagnosesID).includes(searchTerm))

   console.log(filterobj)

    setFilteredDiagnostic(filterobj)

  }else{
    setFilteredDiagnostic([]);
  }

};

const handleSearchPharm =(e)=>{
  setSearchPharmacy(e)
}


const handleSearchDrug =(e)=>{
  setSearchDrug(e)
}


const handlesearchDrugFilter = (searchTerm) => {
  
  searchTerm && searchTerm.toLowerCase().trim();

  if(searchTerm !=="" && inventories) {

   const filterobj = inventories.filter(item => item?.name.toLowerCase().includes(searchTerm))

   console.log(filterobj)

    setFilteredPrescription(filterobj)

  }else{
    setFilteredPrescription([]);
  }

};




const handlesearchPharmacyFilter = (searchTerm) => {
  
  searchTerm && searchTerm.toLowerCase().trim();

  if(searchTerm !=="" && pharmacies) {

   const filterobj = pharmacies.filter(item => item?.name.toLowerCase().includes(searchTerm))

   console.log(filterobj)

    setFilteredPharmacy(filterobj)

  }else{
    setFilteredPharmacy([]);
  }

};



const handleFilter = (searchTerm) => {
  
  searchTerm.toLowerCase().trim();

  if(searchTerm !=="" && patients) {

   const filterobj = patients.filter(item => item.name.toLowerCase().includes(searchTerm))

   console.log(filterobj)

    setFilteredPatients(filterobj)

  }else{
    setFilteredPatients([]);
  }

};



const handleFilterDoc = (searchTerm) => {
  // Update the query key to include the search term
  searchTerm.toLowerCase().trim();

  if(searchTerm !=="" && doctors) {

   const filterobj = doctors.filter(item => item.name.toLowerCase().includes(searchTerm))

   console.log(filterobj)

    setFilteredDoctors(filterobj)

  }else{
    setFilteredDoctors([]);
  }

};


// console.log(specialisation, 'profile')

const [selectedOption, setSelectedOption] = useState(Number(3));

console.log(drugId, 'inv')
const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
//   const handleClick = () => setShow(!show);
  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }


    if(doctorId === "") {
      return setError("You must enter a doctor name");
    }
    if(patientId=== "") {
      return setError("You must enter a the patient name");
    }
    
    try {
      setButtonText(isLoading?'Loading...':'Add Prescription');
      const datatoBackend ={
        drugName:drugName,
        drugId:drugId,
        prescription:prescription,
        status:'unfulfilled',
        createdBy: user?.id,
        patient:Number(patientId),
        doctor:Number(doctorId),
        pharmacy:Number(pharmId),
        diagnoses:Number(diagnosticId)
        // profileImage:uploadedImageUrl,
      }

      console.log(datatoBackend, 'test')
      setButtonText(isLoading?'Loading...': 'Add Pharmacy')
      addPrescription(datatoBackend)
      .unwrap() // Unwrap the result to access the data directly
      .then((userData) => {
        console.log(userData, 'userData')
        // Handle successful login
        setButtonText('Prescription added successfully');
        onBoxClose(false)
        if(!userData){
          setButtonText(error?error:'Add Prescription');
        // return setError(data.detail);
        }
       
      })
    
    } catch (err) {
      console.log(err);
      setButtonText("Edit");
      if (err.response) {
        // return setError(err.response.data.msg);
        console.log(err.responsedata)
        return setError('something went wrong');
      }
      return setError("There has been an error.");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>Error: {errors.message}</div>;
  }

 
 
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <FormControl>
        <Grid
        mb='20px'
        
        templateColumns={{
          base: "1fr",
          lg: "repeat(1, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
        >
          {/* <div>
<FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Drug Name<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={''}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setDrugName(event.target.value)
                  setError(undefined);
                }}
              />
</div> */}
<div>
<FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Precsription<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='weight'
              placeholder={''}
              mb='24px'
            //   defaultValue={weight}
              fontWeight='500'
              size='lg'
              onChange={(event) => {
                setPrescription(event.target.value);
                setError(undefined);
              }}
            />
</div>
         
</Grid>
<Grid
        mb='20px'
        
        templateColumns={{
          base: "1fr",
          lg: "repeat(1, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
        >
            <div>
            <Button colorScheme="blue" ml={3} onClick={()=> onOpenDrug(true)}>{drugName?drugName:'Select Drug'}</Button>
            </div>  
          

            <div>
            <Button colorScheme="blue" ml={3} onClick={()=> onOpen(true)}>{username?username:'Select Patient'}</Button>
            </div>  
          
         
        
            <div>
            <Button colorScheme="blue" ml={3} onClick={()=> onOpenDoc(true)}>{doctorName?doctorName:'Select Doctor'}</Button>
            </div> 
            <div>
            <Button colorScheme="blue" ml={3} onClick={()=> onOpenDiagnostic(true)}>{diagnosticName?diagnosticName:'Select Diagnosis ID'}</Button>
            </div>  
          
         
        
            <div>
            <Button colorScheme="blue" ml={3} onClick={()=> onOpenPharmacy(true)}>{pharmName?pharmName:'Select Pharmacy'}</Button>
            </div> 

          </Grid>
<div>
            <Box mt={4}>
                <ListModal
                isOpen={isOpen}
                onClose={onClose}
                filteredPatients={filteredPatients}
                setPatientId={setPatientId}
                setSearch={setSearch}
                handleSearch={handleSearch}
                handleFilter={handleFilter}
                setError={setError}
                search={search}
                setUsername={setUsername}
                />
        </Box>
      
            </div>
            <div>
               <Box mt={4}>
                <DiagnosticListModal
                isOpen={isDiagnostic}
                onClose={onCloseDiagnosis}
                filteredPatients={filteredDiagnostic}
                setPatientId={setDiagnosticId}
                setSearch={setSearchDiagnostic}
                handleSearch={handleSearchDiagnostic}
                handleFilter={handleSearchDiagnosticFilter}
                setError={setError}
                search={searchDiagnostic}
                setUsername={setDiagnosticName}
                />
        </Box>
      
            </div>
            <div>
               <Box mt={4}>
                <DrugListModal
                isOpen={isOpenDrug}
                onClose={onCloseDrug}
                filteredPatients={filteredPrescription}
                setPatientId={setDrugId}
                setSearch={setDrugName}
                handleSearch={handleSearchDrug}
                handleFilter={handlesearchDrugFilter}
                setError={setError}
                search={searchDrug}
                // setUsername={setDrugName}
                />
        </Box>
      
            </div>
            <div>
               <Box mt={4}>
                <PharmListModal
                isOpen={isPharm}
                onClose={onClosePharmacy}
                filteredPatients={filteredPharmacy}
                setPatientId={setPharmId}
                setSearch={setSearchDiagnostic}
                handleSearch={handleSearchPharm}
                handleFilter={handlesearchPharmacyFilter}
                setError={setError}
                search={searchPharm}
                setUsername={setPharmName}
                />
        </Box>
      
            </div>
            <div>
            <Box mt={4}>
                <ListDoctorModal
                isOpen={isOpenDoc}
                onClose={onCloseDoc}
                filteredPatients={filteredDoctors}
                setPatientId={setDoctorId}
                setSearch={setSearchDoc}
                handleSearch={handleSearchDoc}
                handleFilter={handleFilterDoc}
                setError={setError}
                search={searchDoc}
                setUsername={setDoctorName}
                />
        </Box>
      
            </div>
<Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                onClick={register}
              >
                {buttonText}
              </Button>
</FormControl>
        
        </Box>
     
    </Card>
  )
}

export default Form

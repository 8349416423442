// api/users.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";
import { useAuth } from 'auth-context/auth.context';






export const patientsApi = createApi({
  reducerPath:'patients',
  tagTypes: ['patients'],
  baseQuery: fetchBaseQuery({baseUrl: `${API_SERVER}/api`,
  prepareHeaders: (headers, { getState }) => {
    const {authSlice:{token}} = getState();
 

    let useToken = localStorage.getItem('user');
    const mytoken =JSON.parse(useToken).token
    if (token || mytoken) {
      headers.set('Authorization', `Token ${mytoken?mytoken:token}`);
    }
    return headers;
  },

}), // Replace with your API base URL
endpoints: (builder) => ({
    getPatient: builder.query({
      query: (id) => `/patients/patients/${id}/`, // Replace with your backend endpoint
      headers:{'Content-Type': 'application/json'},
    }),
    providesTags:['patients'],
    getAllPatients: builder.query({
      query: () =>({
        url:`/patients/patients/`,
        headers:{'Content-Type': 'application/json'},
      }),
      transformErrorResponse:res=>res.sort((a,b)=> b.id - a.id),
      invalidatesTags: ['patients'],
    }),
    addPatient:builder.mutation({
      query:(patient)=>({
        url:'/patients/patients/',
        method: 'POST',
        body:patient,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['patients'],
    }),
    updatePatient:builder.mutation({
      query:(patient)=>({
        url:`/patients/patients/${patient.patientId}/`,
        method: 'PATCH',
        body:patient,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['patients'],
    }),
    deletePatient:builder.mutation({ 
      query:(obj)=>({
        url: `/patients/patients/${obj.id}`,
        method: 'DELETE',
        body:obj,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['patients'],

    }),
  })

});

export const { useGetPatientQuery, useGetAllPatientsQuery, useAddPatientMutation,
   useUpdatePatientMutation, 
   useDeletePatientMutation} = patientsApi;
import React, { useState } from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import {  useGetTransactionQuery } from "api/transaction";
import Banner from "./banner";
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import EditNurse from "./EditNurse"
import DeleteModal from './DeleteModal';


const MyModal = ({isOpen, onOpen, onClose, id}) => {
 
    const { data, isLoading } =  useGetTransactionQuery(id);
    const [buttonText, setButtonText] = useState("Delete");
    const [error, setError] = useState(undefined);
    // const[deletePatient, {isSuccess}] = useDeleteNurseMutation()
    const[edit, setEdit] = useState(false)
    const [isDelete, setDelete] = useState(false)
    const [msg, setMsg] = useState('')


    const handleShow = () => {
      setDelete(true)
      setEdit(false)

    }

    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    if (!data) {
      return <div>Nurse not found.</div>;
    }


    const handleDelete = () => {
    try{
        // deletePatient(id)
        //   .unwrap() // Unwrap the result to access the data directly
        //   .then((userData) => {
        //     console.log(userData, 'userData')
        //     const{message, error} = userData;
        //     setMsg(message?message:error)
        //     setButtonText('Nurse Deleted');
        //     if(!userData){
        //       setButtonText(error?error:'Delete');
        //     // return setError(data.detail);
        //     }
           
        //   })
    
       
        
        } catch (err) {
          console.log(err);
          setButtonText("Edit");
          if (err.response) {
            // return setError(err.response.data.msg);
            console.log(err.responsedata)
            return setError('something went wrong');
          }
          return setError("There has been an error.");
        }
      
    }


    console.log(id, 'idss')
  
    return (
      <>
        {/* <Button onClick={onOpen}>Open Modal</Button> */}
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Records</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {
                edit? 
                
                <EditNurse
                 id={id}/>
                :isDelete?
              
              <DeleteModal nurse={data} message={msg}/>
              :
              <Banner
              gridArea='1 / 1 / 2 / 2'
              banner={banner}
              avatar={avatar}
              data={data}
              name='Adela Parkson'
              job='Product Designer'
              posts='17'
              followers='9.7k'
              following='274'
            /> 
              }
        
            </ModalBody>
             
                
                <ModalFooter>
{/*                
                {
                  edit || !isDelete?  
                  <>
                   <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Close
                </Button> */}
                      {/* <Button colorScheme="green" mr={3} onClick={()=>setEdit(true)}>Edit</Button>

               
                  
<Button colorScheme="red" mr={3} onClick={()=> handleShow()}>Delete</Button> */}
                  {/* </>
              
                  :
                  <>
                      <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Close
                </Button>
                  <Button colorScheme="red" mr={3} onClick={()=> handleDelete()}>Delete</Button>
                  </>
                } */}
            
               
               
              </ModalFooter>
              
           
          
          </ModalContent>
        </Modal>
      </>
    );
  };
  
  export default MyModal;
  
import React, { useState } from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { useGetPatientQuery, useDeletePatientMutation } from "api/patient";
import Banner from "./components/banner";
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import EditPatient from "./components/EditPatient"
import DeleteModal from './components/DeleteModal';
import { useAuth } from "auth-context/auth.context";


const MyModal = ({isOpen, onOpen, onClose, id, isDelete, setDelete}) => {
  const { user:{user} } = useAuth();
    const { data, isLoading } = useGetPatientQuery(id);
    const [buttonText, setButtonText] = useState("Delete");
    const [error, setError] = useState(undefined);
    const[deletePatient, {isSuccess}] = useDeletePatientMutation()
    const [edit, setEdit] = useState(false)
    // const [isDelete, setDelete] = useState(false)
    const [msg, setMsg] = useState('')


    const handleShow = () => {
      setDelete(true)
      setEdit(false)

    }

    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div style="color:red">Error: {error.message}</div>;
    }
  
    if (!data) {
      return <div>Patient not found.</div>;
    }


    const handleDelete = () => {

      const tosend={
        id:id,
        user:user?.id
      }

    try{
        deletePatient(tosend)
          .unwrap() // Unwrap the result to access the data directly
          .then((userData) => {
            console.log(userData, 'userData')
            const{message, error} = userData;
            setMsg(message?message:error)
            // Handle successful login
            setButtonText('Patient Deleted');
            if(!userData){
              setButtonText(error?error:'Delete');
            // return setError(data.detail);
            }
           
          })
    
       
        
        } catch (err) {
          console.log(err);
          setButtonText("Edit");
          if (err.response) {
            // return setError(err.response.data.msg);
            console.log(err.responsedata)
            return setError('something went wrong');
          }
          return setError("There has been an error.");
        }
      
    }

  
    return (
      <>
        {/* <Button onClick={onOpen}>Open Modal</Button> */}
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Patient</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {
                edit? 
                
                <EditPatient id={id}/>
                :isDelete?
              
              <DeleteModal patient={data} message={msg} onClose={onClose} handleDelete={handleDelete} user={user}/>
              :
              <Banner
              gridArea='1 / 1 / 2 / 2'
              banner={banner}
              avatar={avatar}
              info={data}
              name='Adela Parkson'
              job='Product Designer'
              posts='17'
              followers='9.7k'
              following='274'
            /> 
              }
        
            </ModalBody>
             
                
                <ModalFooter>
               
                {
                  edit || !isDelete?  
                  <>
                   <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Close
                </Button>
                      <Button colorScheme="green" mr={3} onClick={()=>setEdit(true)}>Edit</Button>

               
                  
{/* <Button colorScheme="red" mr={3} onClick={()=> handleShow()}>Delete</Button> */}
                  </>
              
                  : null
                //   <>
                //       <Button colorScheme="blue" mr={3} onClick={onClose}>
                //   Close
                // </Button>
                //   <Button colorScheme="red" mr={3} onClick={()=> handleDelete()}>Delete</Button>
                //   </>
                }
            
               
               
              </ModalFooter>
              
           
          
          </ModalContent>
        </Modal>
      </>
    );
  };
  
  export default MyModal;
  
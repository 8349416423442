// api/users.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";



export const diagnosisApi = createApi({
  reducerPath:'diagnoses',
  tagTypes: ['diagnoses'],
  baseQuery: fetchBaseQuery({baseUrl: `${API_SERVER}/api`,
  prepareHeaders: (headers, { getState }) => {
    const {authSlice:{token}} = getState();
 
    console.log(token , 'state token');
    let useToken = localStorage.getItem('user');
    const mytoken =JSON.parse(useToken).token
    if (token || mytoken) {
      headers.set('Authorization', `Token ${mytoken?mytoken:token}`);
    }
    return headers;
  },

}), // Replace with your API base URL
endpoints: (builder) => ({
    getDiagnosis: builder.query({
      query: (id) => `/checkups/checkups/${id}/`, // Replace with your backend endpoint
      headers:{'Content-Type': 'application/json'},
    }),
    // providesTags:['patients'],
    getAllDiagnoses: builder.query({
      query: () =>({
        url:`/checkups/checkups/`,
        headers:{'Content-Type': 'application/json'},
      }),
      transformErrorResponse:res=>res.sort((a,b)=> b.id - a.id),
      invalidatesTags: ['diagnoses'],
    }),
    addDiagnosis:builder.mutation({
      query:(pharm)=>({
        url:'/checkups/checkups/',
        method: 'POST',
        body:pharm,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['diagnosis'],
    }),
    updateDiagnosis:builder.mutation({
      query:(pharm)=>({
        url:`/checkups/checkups/${pharm.diagnosesID}/`,
        method: 'PATCH',
        body:pharm,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['diagnosis'],
    }),
    deleteDiagnosis:builder.mutation({ 
      query:(obj)=>({
        url: `/checkups/checkups/${obj.id}`,
        method: 'DELETE',
        body:obj,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['diagnosis'],

    }),
  })

});

export const { useGetDiagnosisQuery, useGetAllDiagnosesQuery, useAddDiagnosisMutation,
   useUpdateDiagnosisMutation, 
   useDeleteDiagnosisMutation} = diagnosisApi;
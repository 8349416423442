// api/users.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";







export const specialisationsApi = createApi({
  reducerPath:'specialisations',
  tagTypes: ['specialisations'],
  baseQuery: fetchBaseQuery({baseUrl: `${API_SERVER}/api`,
  prepareHeaders: (headers, { getState }) => {
    const {authSlice:{token}} = getState();
 
    console.log(token , 'state token');
    let useToken = localStorage.getItem('user');
    const mytoken =JSON.parse(useToken).token
    if (token || mytoken) {
      headers.set('Authorization', `Token ${mytoken?mytoken:token}`);
    }
    return headers;
  },

}), // Replace with your API base URL
endpoints: (builder) => ({
    getSpecialisation: builder.query({
      query: (id) => `/specialisations/specialisations/${id}/`, // Replace with your backend endpoint
      headers:{'Content-Type': 'application/json'},
    }),
    // providesTags:['patients'],
    getAllSpecialisations: builder.query({
      query: () =>({
        url:`/specialisations/specialisations/`,
        headers:{'Content-Type': 'application/json'},
      }),
      transformErrorResponse:res=>res.sort((a,b)=> b.id - a.id),
      invalidatesTags: ['specialisations'],
    }),
    addSpecialisation:builder.mutation({
      query:(specialisation)=>({
        url:'/specialisations/specialisations/',
        method: 'POST',
        body:specialisation,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['specialisations'],
    }),
    updateSpecialisation:builder.mutation({
      query:(patient)=>({
        url:`/specialisations/specialisations/${patient.patientID}/`,
        method: 'PATCH',
        body:patient,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['specialisations'],
    }),
    deleteSpecialisation:builder.mutation({ 
      query:(id)=>({
        url: `/specialisations/specialisations/${id}`,
        method: 'DELETE',
        body:id,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['specifications'],

    }),
  })

});

export const { useGetSpecialisationQuery, useGetAllSpecialisationsQuery, useAddSpecialisationMutation,
   useUpdateSpecialisationMutation, 
   useDeleteSpecialisationMutation} = specialisationsApi;
import React from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  
    return (
      <Box mt={4} display="flex" justifyContent="center">
        {pages.map((page) => (
          <Button
            key={page}
            variant={currentPage === page ? 'solid' : 'outline'}
            colorScheme="teal"
            onClick={() => onPageChange(page)}
          >
            {page}
          </Button>
        ))}
      </Box>
    );
  };
  
  export default Pagination;
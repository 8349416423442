import React from 'react'
import { Button } from '@chakra-ui/react';

function DeleteModal({message, inventories, user, handleDelete, onClose, setDelete}) {
  return (
    <div>

      {
        user?.role === 5 || user.is_superuser ?   
        <>
        
        {
        message?message: `You are about to delete this document with id ${inventories.inventoriesId}?`
      }

<div style={{justifyContent: 'center', margin:'10px'}}>
      
      <Button colorScheme="blue" mr={3} onClick={()=> setDelete(false)}>
                  Close
                </Button>
                <Button colorScheme="red" mr={3} onClick={()=> handleDelete()}>Confirm Delete</Button>
      </div>

        </>
        
        : null
      }
       
    </div>
  )
}

export default DeleteModal
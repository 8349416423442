import React, { useState } from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { useGetUserQuery } from "api/user";
import Banner from "views/admin/profile/components/Banner";
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import DiagnosisForn from "./diagnosisForm"

const MyModal = ({isOpen, onOpen, onClose, id}) => {
    console.log(id)
    // const { data, error, isLoading } = useGetUserQuery(id);

    // if (isLoading) {
    //   return <div>Loading...</div>;
    // }
  
    // if (error) {
    //   return <div>Error: {error.message}</div>;
    // }
  
    // if (!data) {
    //   return <div>User not found.</div>;
    // }
  
    return (
      <>
        {/* <Button onClick={onOpen}>Open Modal</Button> */}
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Diagnosis</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            {/* <Banner
          gridArea='1 / 1 / 2 / 2'
          banner={banner}
          avatar={avatar}
          data={data}
          name='Adela Parkson'
          job='Product Designer'
          posts='17'
          followers='9.7k'
          following='274'
        /> */}
        <DiagnosisForn/>
            </ModalBody>
  
            <ModalFooter>
          
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              {/* <Button colorScheme="red" mr={3}>Edit</Button> */}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };
  
  export default MyModal;
  
export const columnsDataCheck = [
  
      {
        Header: "ID",
        accessor: "Id",
      },
      {
        Header: "PRESCRIPTIONID",
        accessor: "prescriptionid",
      },
      {
        Header: "DRUG NAME",
        accessor: "drugname",
      },
      {
        Header: "DOCTOR",
        accessor: "doctor",
      },
      {
          Header: "PATIENT",
          accessor: "patient",
        },
        {
            Header: "AMOUNT",
            accessor: "amount",
          },
  
          {
            Header: "VIEW",
            accessor: "view",
          },
   
  ];
  export const columnsDataComplex = [
    {
      Header: "ID",
      accessor: "Id",
    },
    {
      Header: "PRESCRIPTIONID",
      accessor: "prescriptionid",
    },
    {
      Header: "DRUG NAME",
      accessor: "drugname",
    },
    {
      Header: "DOCTOR",
      accessor: "doctor",
    },
    {
        Header: "PATIENT",
        accessor: "patient",
      },
      {
          Header: "AMOUNT",
          accessor: "amount",
      },

      {
        Header: "VIEW",
        accessor: "view",
      },

  ];
  
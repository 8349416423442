import React,{useState} from 'react'
import {
    Avatar,
    Box,
    Flex,
    FormLabel,
    Icon,
    Select,
    Text,
    SimpleGrid,
    useColorModeValue,
  } from "@chakra-ui/react"
 
  import {
    columnsDataCheck,
    columnsDataComplex,
  } from "./components/column";
  import { useAuth } from "auth-context/auth.context";

  import DoctorTable from "./components/doctorTable"
  import {useGetAllDoctorsQuery } from "api/physician";
  import IconBox from "components/icons/IconBox";
  import AddDoctor from "./components/Adddoctor";
  import {
    MdAddTask,
    // MdAttachMoney,
    // MdBarChart,
    // MdFileCopy,
  } from "react-icons/md";

function Doctors() {
    const [isOpen, setIsOpen] = useState(false);
    const [isAddPatientOpen, setIsAddPatientOpen] = useState(false);
    const brandColor = useColorModeValue("brand.500", "white");
    const [isDelete, setDelete] = useState(false)
    let mainText = useColorModeValue("navy.700", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const { user:{user} } = useAuth();
    const [userID, setUserId] = useState()
    const onClose = () => {setIsOpen(false); setDelete(false); }
    const onOpen = () => setIsOpen(true);
    const onOpenAddPatient = () => setIsAddPatientOpen(true);
    const onCloseAddPatient = () => setIsAddPatientOpen(false);
    const {data:doctors, error, isLoading} = useGetAllDoctorsQuery();
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
      );
      const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.100" }
      );

    console.log(doctors, 'allusers')
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    if (!doctors) {
      return <div>User not found.</div>;
    }

  const tableData = (doctors)=>{
  
    let result=[];

    doctors && doctors.map(nurse =>{
      const{doctorID,  profileImage, name, contact, email, gender, phone, createdby, dateOfBirth} = nurse;
      result.push({ profileImage, doctorID, name, email, contact, gender, createdby, phone, dateOfBirth})
    })

    return result;
  }
  return (
 <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
 <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px' mt="30px">
  {
         user?.is_superuser || user?.role ==5?(
          

 <SimpleGrid 
     _hover={bgHover}
     _focus={bgFocus}
     _active={bgFocus}
     onClick={onOpenAddPatient}
     >
  <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAddTask} color={brandColor} />
              }
            />
     <Text
            color={textColor}
            fontSize='14px'
            fontWeight='700'
            lineHeight='100%'>
            Add Doctors
          </Text>
     </SimpleGrid>
              ) : null
            }
        <DoctorTable
          columnsData={columnsDataComplex}
          patientData={tableData(doctors)}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          isDelete={isDelete}
          setDelete={setDelete}
        />
        <AddDoctor 
        isOpen={isAddPatientOpen}
        onClose={onCloseAddPatient}
        onOpen={onOpenAddPatient}
        userID={userID}
          />

      </SimpleGrid>

 </Box>
  )
}

export default Doctors
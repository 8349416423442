import React,{useState} from "react";

import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/hospital.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useAuth } from "../../../auth-context/auth.context";
import { useForgotPasswordMutation} from '../../../api/auth';

import AuthApi from "../../../api/auth";
import { setToken } from "api/authSlice";
function SignIn() {
  const [email, setEmail] = useState("");  // <-- Default values HERE
  const [password, setPassword] = useState(""); 
  const dispatch =useDispatch()      // <-- Default values HERE
  const [err, setError] = useState(undefined);
  const [buttonText, setButtonText] = useState("Sign in");
  const [forgotPassword, { data:message, isLoading, isError, error, isSuccess}] = useForgotPasswordMutation();
  const history = useHistory();
  const { setUser } = useAuth();
  const { myuser } = useAuth();
  // Chakra color mode


  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);


  const handleLogin = async (event) => {
    if (event) {
      event.preventDefault();
    }
    // if (myuser && myuser.token && isSuccess && !isError) {
    //   return history.push("/admin/dashboards");
    // }
    if (email === "") {
      return setError("You must enter your email.");
    }
 
    // setButtonText("");
    try {

       forgotPassword({ email })
      .unwrap() // Unwrap the result to access the data directly
      .then((userData) => {
        console.log(userData, 'userData')
        setButtonText("Confirm New Password");
        // Handle successful login
        if(!userData){
          setButtonText("Send");
          console.log('error')
        // return setError(data.detail);
        }
        
        // return setProfile(userData);
      })
     
      
    } catch (err) {
      console.log(err, 'ceck');
      setButtonText("Sign in");
      
        console.log(err.data.detail, 'my err');
        return setError(err.data.detail);
      
   
    }
  };

  console.log(message,'msg')
 
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
           Change Password
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
           Eimpact
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
         
          <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
            <h4
              style={{
                fontSize: ".9em",
                color: "red",
                textAlign: "center",
                fontWeight: 400,
                transition: ".2s all",
              }}
            >
              {error?.data?.detail}
            </h4>
            {
                message?.message && isSuccess? 

                <h2
                style={{
                  fontSize: ".9em",
                  color: "green",
                  textAlign: "center",
                  fontWeight: 800,
                  transition: ".2s all",
                }}
              >
                { message?.message}
              </h2>
                
            //     <>
            //       <FormLabel
            //   ms='4px'
            //   fontSize='sm'
            //   fontWeight='500'
            //   color={textColor}
            //   display='flex'>
            //   Password<Text color={brandStars}>*</Text>
            // </FormLabel>
            // <InputGroup size='md'>
            //   <Input
            //     isRequired={true}
            //     fontSize='sm'
            //     placeholder='Min. 8 characters'
            //     mb='24px'
            //     size='lg'
            //     defaultValue={password}
            //     type={show ? "text" : "password"}
            //     variant='auth'
            //     onChange={(event) => {
            //       setPassword(event.target.value);
            //       setError(undefined);
            //     }}
            //   />
            //   <InputRightElement display='flex' alignItems='center' mt='4px'>
            //     <Icon
            //       color={textColorSecondary}
            //       _hover={{ cursor: "pointer" }}
            //       as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
            //       onClick={handleClick}
            //     />
            //   </InputRightElement>
            // </InputGroup>
                
            //     </>
                
                : null
            }
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='mail@simmmple.com'
              mb='24px'
              defaultValue={email}
              fontWeight='500'
              size='lg'
              onChange={(event) => {
                setEmail(event.target.value);
                setError(undefined);
              }}
            />
          
            <Flex justifyContent='space-between' align='center' mb='24px'>
             
              {/* <NavLink to='/auth/forgot-password'> */}
               <NavLink to='/auth'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Back to Login?
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={handleLogin}>
              {isLoading? `Loading....`:'Send'}
            </Button>
          </FormControl>
          {/* <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
              <NavLink to='/auth/sign-up'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex> */}
        </Flex>
      </Flex>
      </Flex>
    </DefaultAuth>
   
  );
}

export default SignIn;

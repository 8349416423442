import React,{useState} from 'react'
import Card from "components/card/Card.js"
import FileUploadComponent from "../../../auth/signUp/fileupload";
import {   Box,
    Grid,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    Select,
    InputGroup,
    Textarea,
    InputRightElement,
    Text,
    useColorModeValue, } from "@chakra-ui/react";
    import { MdOutlineRemoveRedEye } from "react-icons/md";
    import { useGetDiagnosisQuery, useUpdateDiagnosisMutation} from 'api/diagnosis';
    import { useAuth } from "auth-context/auth.context";
    import { useGetAllPatientsQuery } from 'api/patient';
    import { useGetAllSpecialisationsQuery } from 'api/specialisation';
import { RiEyeCloseLine } from "react-icons/ri";
import { vitalsignsApi } from 'api/vitalsign';
import ListModal from "./listComponent"


function EditForm({id}) {

  const { data, isLoading, errors } = useGetDiagnosisQuery(id);
const [updateDiagnosis, {isSuccess}] = useUpdateDiagnosisMutation();
const { user:{user} } = useAuth();
const { data:patients, } = useGetAllPatientsQuery()
// const {data:specialisation, isError} = useGetAllSpecialisationsQuery()
const [symptoms, setSymptoms] = useState(data?.symptoms);
const [causes, setCauses] = useState(data?.causes);
const [prognosis, setPrognosis] = useState(data?.prognosis);
const [severity, setSeverity] = useState(data?.severity);
const [location, setLocation] = useState(data?.location);
const [name, setName] = useState();
const[patientId,  setPatientId] = useState(data?.name);
const [search, setSearch] = useState('');
const [diagnoses, setDiagnoses] = useState(data?.diagnosis_type);
const [remarks, setRemarks] = useState(data?.doctorRemark);
const [prescription, setPrescription] = useState(data?.prescription);
const [filteredPatients, setFilteredPatients] = useState('')
const [isOpen, setIsOpen] = useState(false);

const onClose = () => setIsOpen(false);
const onOpen = () => setIsOpen(true);



const  handleSearch =(e)=>{
  setSearch(e)
}


const handleFilter = (searchTerm) => {
  // Update the query key to include the search term
  searchTerm.toLowerCase().trim();

  if(searchTerm !=="" && patients) {

   const filterobj = patients.filter(item => item.name.toLowerCase().includes(searchTerm))

   console.log(filterobj)

    setFilteredPatients(filterobj)
  

  }else{
    setFilteredPatients([]);
  }

};





const [error, setError] = useState(undefined);
const [buttonText, setButtonText] = useState("Edit VitalSign");
const [uploadedImageUrl, setUploadedImageUrl] = useState('')


const textColor = useColorModeValue("navy.700", "white");
const textColorSecondary = "gray.400";
const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
const textColorBrand = useColorModeValue("brand.500", "white");
const brandStars = useColorModeValue("brand.500", "brand.400");
const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
const googleText = useColorModeValue("navy.700", "white");
const googleHover = useColorModeValue(
  { bg: "gray.200" },
  { bg: "whiteAlpha.300" }
);
const googleActive = useColorModeValue(
  { bg: "secondaryGray.300" },
  { bg: "whiteAlpha.200" }

);


// let { user } = useAuth();


const [show, setShow] = React.useState(false);


// const [selectedOption, setSelectedOption] = useState(Number(specialisation?.specialisationID));

const handleSeverity =(event)=>{
  setSeverity(event.target.value)
}
const handleSelectChange = (event) => {
    // setSelectedOption(event.target.value);
    setDiagnoses(event.target.value)
  };


// const handleSelectChange = (event) => {
//     setSelectedOption(event.target.value);
//   };
//   const handleClick = () => setShow(!show);
  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }
    
    try {
      setButtonText(isLoading?'Loading...':'Edit Vitalsign');
      const datatoBackend ={
        diagnosesID:data.diagnosesID,
        symptoms:symptoms? symptoms:data?.symptoms,
        causes:causes?causes:data?.causes,
        prognosis:prognosis?prognosis:data?.prognosis,
        diagnosis_type:diagnoses?diagnoses:data?.diagnoses,
        doctorRemark:remarks?remarks:data?.doctorRemark,
        severity: severity?severity:data?.severity,
        location:location?location:data?.location,
        doctor:Number(user.id),
        name:Number(patientId)?Number(patientId):data?.name,
        prescription:prescription?prescription:data?.prescription,
        createdBy: Number(user.id),
        // profileImage:uploadedImageUrl,
      }

      console.log(datatoBackend, 'test')

      updateDiagnosis(datatoBackend)
      .unwrap() // Unwrap the result to access the data directly
      .then((userData) => {
        console.log(userData, 'userData')
        // Handle successful login
        setButtonText('Diagniosis updated');
        if(!userData){
          setButtonText(error?error:'Edit');
        // return setError(data.detail);
        }
       
      })

   
      // Api.endpoints.updateVitalsign.invalidates([]);
    } catch (err) {
      console.log(err);
      setButtonText("Edit");
      if (err.response) {
        // return setError(err.response.data.msg);
        console.log(err.responsedata)
        return setError('something went wrong');
      }
      return setError("There has been an error.");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>Error: {errors.message}</div>;
  }

  if (!data) {
    return <div>Data not found.</div>;
  }


  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <FormControl>
    <Grid
    mb='20px'
    
    templateColumns={{
      base: "1fr",
      lg: "repeat(2, 1fr)",
      "2xl": "1.34fr 1.62fr 1fr",
    }}
    gap={{ base: "20px", xl: "20px" }}
    >
      {
        error?
        
        (
          <h4
          style={{
            fontSize: ".9em",
            color: "red",
            textAlign: "center",
            fontWeight: 400,
            transition: ".2s all",
          }}
        >
          {error?error:null}
        </h4>
        )
          :
          
          null
      }

      <div>
<FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Symptoms<Text color={brandStars}>*</Text>
          </FormLabel>
 <Textarea
  size="md" // Size of the textarea: "sm", "md", "lg"
  resize="vertical" // Allow vertical resizing: "none", "vertical", "horizontal"
  focusBorderColor="blue.500" // Border color when focused
  borderColor="gray.300" // Border color
  placeholder={data?.symptoms}
  onChange={(event) => {
    setSymptoms(event.target.value)
    setError(undefined);
  }}
/>
</div>
<div>
<FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          mb='8px'>
          Causes<Text color={brandStars}>*</Text>
        </FormLabel>
        <Textarea
  placeholder={data?.causes}
  size="md" // Size of the textarea: "sm", "md", "lg"
  resize="vertical" // Allow vertical resizing: "none", "vertical", "horizontal"
  focusBorderColor="blue.500" // Border color when focused
  borderColor="gray.300" // Border color
  onChange={(event) => {
    setCauses(event.target.value)
    setError(undefined);
  }}
/>
</div>
     <div>
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Progonisis<Text color={brandStars}>*</Text>
          </FormLabel>
          <Textarea
  placeholder={data?.prognosis}
  size="md" // Size of the textarea: "sm", "md", "lg"
  resize="vertical" // Allow vertical resizing: "none", "vertical", "horizontal"
  focusBorderColor="blue.500" // Border color when focused
  borderColor="gray.300" // Border color
  onChange={(event) => {
    setPrognosis(event.target.value)
    setError(undefined);
   
  }}
/>
        </div>
          <div>
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Type of Diagnosis<Text color={brandStars}>*</Text>
          </FormLabel>
          <Select value={diagnoses} onChange={handleSelectChange}  mb='24px'>
          
                <option value='medical condition' >Medical Condition</option>
                <option value='disease'>Disease</option>
                <option value='disorder'>Disorder</option>
                <option value='others'>Others</option>
  </Select>
  </div>
  <div>
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Severity<Text color={brandStars}>*</Text>
          </FormLabel>
          <Select value={severity} onChange={handleSeverity}  mb='24px'>
          
                <option value='mild' >Mild</option>
                <option value='moderate'>Moderate</option>
                <option value='severe'>Severe</option>
  </Select>
  </div>
  <div>
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Doctor Remark<Text color={brandStars}>*</Text>
          </FormLabel>
          <Textarea
  placeholder="Doctor Remark ..."
  size="md" // Size of the textarea: "sm", "md", "lg"
  resize="vertical" // Allow vertical resizing: "none", "vertical", "horizontal"
  focusBorderColor="blue.500" // Border color when focused
  borderColor="gray.300" // Border color
  onChange={(event) => {
    setRemarks(event.target.value)
    setError(undefined);
  }}
/>
        </div>
        <div>
  <FormLabel
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            display='flex'>
            Location<Text color={brandStars}>*</Text>
          </FormLabel>
          <InputGroup size='md'>
          <Input
            isRequired={true}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            placeholder={''}
            mb='24px'
            fontWeight='500'
            size='lg'
            onChange={(event) => {
              setLocation(event.target.value);
              setError(undefined);
            }}
          />
          </InputGroup>

          
    
  </div>
  <div>
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Prescription<Text color={brandStars}>*</Text>
          </FormLabel>
          <Textarea
  placeholder="Prescription..."
  size="md" // Size of the textarea: "sm", "md", "lg"
  resize="vertical" // Allow vertical resizing: "none", "vertical", "horizontal"
  focusBorderColor="blue.500" // Border color when focused
  borderColor="gray.300" // Border color
  onChange={(event) => {
    setPrescription(event.target.value)
    setError(undefined);
  }}
/>
        </div>  
         
</Grid>
<Grid
mb='20px'
    
templateColumns={{
base: "1fr",
lg: "repeat(1, 1fr)",
"2xl": "1.34fr 1.62fr 1fr",
}}
>
        <div>
          {/* <Button colorScheme="blue" ml={3} onClick={()=> onOpen(true)}>{search?search:'Select Patient'}</Button> */}
        </div>  
        </Grid>
        <div>
        <Box mt={4}>
            <ListModal
            isOpen={isOpen}
            onClose={onClose}
            filteredPatients={filteredPatients}
            setPatientId={setPatientId}
            setSearch={setSearch}
            handleSearch={handleSearch}
            handleFilter={handleFilter}
            setError={setError}
            search={search}
            />
    </Box>
  
        </div>
<Button
            fontSize='sm'
            variant='brand'
            fontWeight='500'
            w='100%'
            h='50'
            mb='24px'
            onClick={register}
          >
            {buttonText}
          </Button>
</FormControl>
    
    </Box>
 
</Card>
  )
}

export default EditForm

// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue, Grid, } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import Information from "components/information/information";
import { formatDate } from "components/DateFormater/dateFormater";
import { useGetSpecialisationQuery } from "api/specialisation";
import banner from "assets/img/auth/banner.png";
import {useGetUserQuery} from 'api/user';

export default function Banner(props) {
  const { banner, avatar, job, posts, followers, following,data } = props;
  const {name, contact, doctorID, phone, imageUrl, specialisation, createdBy, createdAt, gender, email} = data;
  const{data:user} = useGetSpecialisationQuery(specialisation)
  const {data:creator} = useGetUserQuery(createdBy??createdBy)
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  console.log(data, 'nurse')
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={imageUrl?imageUrl:banner}
        h='87px'
        w='87px'
        mt='-43px'
        border='4px solid'
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize='sm'>
        {email}
      </Text>
      <Flex w='max-content' mx='auto' mt='26px'>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {posts}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Patients
          </Text>
        </Flex>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {followers}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Consultations
          </Text>
        </Flex>
      </Flex>
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>

        <Information
        //   boxShadow={cardShadow}
          title='address'
          value={contact}
        />
         <Information
        //   boxShadow={cardShadow}
          title='Phone'
          value={phone}
        />
         {/* <Information
        //   boxShadow={cardShadow}
          title='Date of Birth'
          value={dateOfBirth}
        /> */}
         <Information
        //   boxShadow={cardShadow}
          title='Email'
          value={email}
        />
          {/* <Information
        //   boxShadow={cardShadow}
          title='Date of Birth'
          value={dateOfBirth}
        /> */}
         <Information
        //   boxShadow={cardShadow}
          title='Gender'
          value={gender}
        />
          <Information
        //   boxShadow={cardShadow}
          title='Created At'
          value={formatDate(createdAt, 'yyyy-MM-dd')}
        />
        <Information
        //   boxShadow={cardShadow}
          title='Specilisation'
          value={user?user.name:null} 
        />
         <Information
        //   boxShadow={cardShadow}
          title='Created by'
          value={creator? `${creator?.first_name} ${creator.last_name}`: null} 
        />



        </Grid>

    </Card>
  );
}

// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue, Grid, } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import Information from "components/information/information";
import { formatDate } from "components/DateFormater/dateFormater";
import { useGetSpecialisationQuery } from "api/specialisation";
import { useGetDoctorQuery } from "api/physician";
import { useGetPatientQuery } from "api/patient";
import banner from "assets/img/auth/banner.png";

export default function Banner(props) {
  const { banner, avatar, job, posts, followers, following,data } = props;
  const {name, diagnosesID, diagnosis_type, causes, symptoms, prognosis, createdAt, severity, doctorRemark,
    location, prescription, detail,doctor} = data;
  // const{data:consultation} = useGetSpecialisationQuery(specialisation)
  const {data:user} = useGetPatientQuery(name)
  const {data:physician} = useGetDoctorQuery(doctor)
  // const{data:user} = useGetUserQuery(createdBy)
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  console.log(physician, 'doctor')
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
    <Grid
      mb='20px'
      templateColumns={{
        base: "1fr",
        lg: "repeat(1, 1fr)",
        "2xl": "1.34fr 1.62fr 1fr",
      }}
    >

<Information
        //   boxShadow={cardShadow}
          title='Patient Name'
          value={user?user.name:null}
        />

    </Grid>
     
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>

        <Information
        //   boxShadow={cardShadow}
          title='Diagnosis Type'
          value={diagnosis_type}
        />
         <Information
        //   boxShadow={cardShadow}
          title='Symptom Type'
          value={symptoms}
        />
         {/* <Information
        //   boxShadow={cardShadow}
          title='Date of Birth'
          value={dateOfBirth}
        /> */}
         <Information
        //   boxShadow={cardShadow}
          title='Causes'
          value={causes}
        />
          {/* <Information
        //   boxShadow={cardShadow}
          title='Date of Birth'
          value={dateOfBirth}
        /> */}
         <Information
        //   boxShadow={cardShadow}
          title='Prognosis'
          value={prognosis}
        />
           <Information
        //   boxShadow={cardShadow}
          title='Severity'
          value={severity} 
        />
           <Information
        //   boxShadow={cardShadow}
          title='Prescription'
          value={prescription}
        />

          <Information
        //   boxShadow={cardShadow}
          title='Location'
          value={location} 
          
        />    
         <Information
        //   boxShadow={cardShadow}
          title='Remarks'
          value={doctorRemark} 
        />   
        
        {/* <Information
        //   boxShadow={cardShadow}
          title='Purpose response'
          value={pupil_repsonse} 
        />   */}
      

        </Grid>
        <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
        >
           
<Information
        //   boxShadow={cardShadow}
          title='Details'
          value={detail} 
        />    
         <Information
        //   boxShadow={cardShadow}
          title='Doctor'
          value={physician?.name}
          // value={user?`${user.first_name} ${user.last_name}`:null} 
        />   

{/* <Information
        //   boxShadow={cardShadow}
          title='Pulse Oxymetry'
          value={pulse_oximetry} 
        />   */}
      <Information
        //   boxShadow={cardShadow}
          title='Created At'
          value={formatDate(createdAt, 'yyyy-MM-dd')}
        />    
        </Grid>

    </Card>
  );
}

export const columnsDataCheck = [
    {
      Header: "AVATAR",
      accessor: "avatar",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "FIRSTNAME",
      accessor: "firstname",
    },
    {
      Header: "LASTNAME",
      accessor: "lastname",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "ROLE",
      accessor: "role",
    },
    {
      Header: "VIEW",
      accessor: "view",
    },
    {
      Header: "DELETE",
      accessor: "delete",
    },
  
  ];
  export const columnsDataComplex = [
    {
      Header: "AVATAR",
      accessor: "avatar",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "FIRSTNAME",
      accessor: "firstname",
    },
    {
      Header: "LASTNAME",
      accessor: "lastname",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "ROLE",
      accessor: "role",
    },
    {
      Header: "VIEW",
      accessor: "view",
    },
    {
      Header: "DELETE",
      accessor: "delete",
    },
   
  ];
  
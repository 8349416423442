import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdGamepad,
  MdSpatialAudioOff,

} from "react-icons/md";

// Admin Imports

import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import Vitalsign from "views/admin/VitalSign/vitalsign";
import Patient from "views/admin/Patients/patient";
import RTL from "views/admin/rtl";

// Auth Imports
// import SignInCentered from "views/auth/signIn";


const routes = [

  {
    name: "Volunteers",
    layout: "/nurse",
    path: "/default",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
    {
      name: "Vitalsign",
      layout: "/nurse",
      path: "/vitalsign",
      icon: <Icon as={MdGamepad} width='20px' height='20px' color='inherit' />,
      component: Vitalsign,
    },
    {
      name: "Beneficials",
      layout: "/nurse",
      path: "/patients",
      icon: <Icon as={MdSpatialAudioOff} width='20px' height='20px' color='inherit' />,
      component: Patient,
    },

];


export default routes;

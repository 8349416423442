// api/users.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";







export const rolesApi = createApi({
  reducerPath:'roles',
  tagTypes: ['roles'],
  baseQuery: fetchBaseQuery({baseUrl: `${API_SERVER}/api`,
  prepareHeaders: (headers, { getState }) => {
    const {authSlice:{token}} = getState();
 
    console.log(token , 'state token');
    let useToken = localStorage.getItem('user');
    const mytoken =JSON.parse(useToken).token
    if (token || mytoken) {
      headers.set('Authorization', `Token ${mytoken?mytoken:token}`);
    }
    return headers;
  },

}), // Replace with your API base URL
endpoints: (builder) => ({
    getRole: builder.query({
      query: (id) => `/roles/roles/${id}/`, // Replace with your backend endpoint
      headers:{'Content-Type': 'application/json'},
    }),
    // providesTags:['patients'],
    getAllRoles: builder.query({
      query: () =>({
        url:`/roles/roles/`,
        headers:{'Content-Type': 'application/json'},
      }),
      transformErrorResponse:res=>res.sort((a,b)=> b.id - a.id),
      invalidatesTags: ['roles'],
    }),
    addRole:builder.mutation({
      query:(roles)=>({
        url:'/roles/roles/',
        method: 'POST',
        body:roles,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['roles'],
    }),
    updateRole:builder.mutation({
      query:(role)=>({
        url:`/roles/roles/${role.id}/`,
        method: 'PATCH',
        body:role,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['roles'],
    }),
    deleteRole:builder.mutation({ 
      query:(id)=>({
        url: `/roles/roles/${id}`,
        method: 'DELETE',
        body:id,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['roles'],

    }),
  })

});

export const { useGetRoleQuery, useGetAllRolesQuery, useAddRoleMutation,
   useUpdateRoleMutation, 
   useDeleteRoleMutation} = rolesApi;
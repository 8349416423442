import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState:{
        user:null,
        token:null,
    },
    reducers:{
        setToken:(state, {payload})=>{
           
            const {token, user} = payload
     
            state.user = user;
            state.token = token;
        }
    },
});

export const {setToken} = authSlice.actions;
export default authSlice.reducer;
export const columnsDataCheck = [
  
      {
        Header: "ID",
        accessor: "Id",
      },
      {
        Header: "DRUG NAME",
        accessor: "drug name",
      },
      {
        Header: "DRUG NAME",
        accessor: "drug name",
      },
  
      {
        Header: "DOCTOR NAME",
        accessor: "doctor name",
      },
      {
          Header: "PHARMACY NAME",
          accessor: "pharmacy name",
        },
        {
            Header: "DIAGNOSIS",
            accessor: "diagnosis",
          },

          {
            Header: "VIEW",
            accessor: "view",
          },
          {
            Header: "DELETE",
            accessor: "delete",
          },
  
   
  ];
  export const columnsDataComplex = [

    {
      Header: "ID",
      accessor: "Id",
    },
    {
      Header: "DRUG NAME",
      accessor: "drug name",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },

    {
      Header: "DOCTOR NAME",
      accessor: "doctor name",
    },
    {
        Header: "PHARMACY NAME",
        accessor: "pharmacy name",
      },
      {
          Header: "DIAGNOSIS",
          accessor: "diagnosis",
        },

        {
          Header: "VIEW",
          accessor: "view",
        },
        {
          Header: "DELETE",
          accessor: "delete",
        },
   
  ];
  
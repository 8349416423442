import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, UnorderedList, ListItem,  Input, } from "@chakra-ui/react";

const MyModal = ({ isOpen, onClose, filteredPatients, setPatientId, setSearch, setUsername, handleSearch, handleFilter, setError, search}) => {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Patient Name</ModalHeader> */}
          <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={''}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  handleSearch(event.target.value);
                  handleFilter(search)
                  setError(undefined);
                //   onOpen(true)
                }}
              />
          <ModalCloseButton />
          <ModalBody>

          <UnorderedList>
    {filteredPatients && filteredPatients.map((item) => (
      <ListItem key={item.pharmacyID}
      onClick={()=>{
        setPatientId(item.pharmacyID)
        setSearch(item.name)
        setUsername(item.name)
        onClose()

      }}
      // bg="gray.200"
      _hover={{
        bg: 'blue.700', // Apply this color on hover
        cursor: 'pointer', // Change cursor to pointer on hover
      }}
      >{item.name}</ListItem>
    ))}
  </UnorderedList>
          {/* Display your search results here */}
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };


  export default MyModal;
  
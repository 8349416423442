// api/users.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";







export const pharmaciesApi = createApi({
  reducerPath:'pharmacies',
  tagTypes: ['pharmacies'],
  baseQuery: fetchBaseQuery({baseUrl: `${API_SERVER}/api`,
  prepareHeaders: (headers, { getState }) => {
    const {authSlice:{token}} = getState();
 
    console.log(token , 'state token');
    let useToken = localStorage.getItem('user');
    const mytoken =JSON.parse(useToken).token
    if (token || mytoken) {
      headers.set('Authorization', `Token ${mytoken?mytoken:token}`);
    }
    return headers;
  },

}), // Replace with your API base URL
endpoints: (builder) => ({
    getPharmacy: builder.query({
      query: (id) => `/pharmacies/pharmacies/${id}/`, // Replace with your backend endpoint
      headers:{'Content-Type': 'application/json'},
    }),
    // providesTags:['patients'],
    getAllPharmacies: builder.query({
      query: () =>({
        url:`/pharmacies/pharmacies/`,
        headers:{'Content-Type': 'application/json'},
      }),
      transformErrorResponse:res=>res.sort((a,b)=> b.id - a.id),
      invalidatesTags: ['pharmacies'],
    }),
    addPharmacy:builder.mutation({
      query:(pharm)=>({
        url:'/pharmacies/pharmacies/',
        method: 'POST',
        body:pharm,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['pharmacies'],
    }),
    updatePharmacy:builder.mutation({
      query:(pharm)=>({
        url:`/pharmacies/pharmacies/${pharm.pharmacyID}/`,
        method: 'PATCH',
        body:pharm,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['pharmacies'],
    }),
    deletePharmacy:builder.mutation({ 
      query:(id)=>({
        url: `/pharmacies/pharmacies/${id}`,
        method: 'DELETE',
        body:id,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['pharmacies'],

    }),
  })

});

export const { useGetPharmacyQuery, useGetAllPharmaciesQuery, useAddPharmacyMutation,
   useUpdatePharmacyMutation, 
   useDeletePharmacyMutation} = pharmaciesApi;
import React, { useState } from 'react';
import { Input, Box, Button } from '@chakra-ui/react';
// import { Image } from 'cloudinary-react';


const MyFileUpload = ({setSelectedFile}) => {
  // const [selectedFile, setSelectedFile] = useState(null);
//   const [uploadedImageUrl, setUploadedImageUrl] = useState('');

  const handleFileChange =  (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    // if(selectedFile){
    //     handleFileUpload(selectedFile)
    // }
  };

  

  return (
    <>
      <Input type="file" onChange={ async(e)=> handleFileChange(e)}  mb='24px'/>

    </>
  );
};

export default MyFileUpload;
// api/users.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";







export const categoriesApi = createApi({
  reducerPath:'categories',
  tagTypes: ['categories'],
  baseQuery: fetchBaseQuery({baseUrl: `${API_SERVER}/api`,
  prepareHeaders: (headers, { getState }) => {
    const {authSlice:{token}} = getState();
 
    console.log(token , 'state token');
    let useToken = localStorage.getItem('user');
    const mytoken =JSON.parse(useToken).token
    if (token || mytoken) {
      headers.set('Authorization', `Token ${mytoken?mytoken:token}`);
    }
    return headers;
  },

}), // Replace with your API base URL
endpoints: (builder) => ({
    getCategory: builder.query({
      query: (id) => `/categories/categories/${id}/`, // Replace with your backend endpoint
      headers:{'Content-Type': 'application/json'},
    }),
    // providesTags:['patients'],
    getAllCategories: builder.query({
      query: () =>({
        url:`/categories/categories/`,
        headers:{'Content-Type': 'application/json'},
      }),
      transformErrorResponse:res=>res.sort((a,b)=> b.id - a.id),
      invalidatesTags: ['categories'],
    }),
    addCategory:builder.mutation({
      query:(cat)=>({
        url:'/categories/categories/',
        method: 'POST',
        body:cat,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['categories'],
    }),
    updateCategory:builder.mutation({
      query:(patient)=>({
        url:`/categories/categories/${patient.categoriedID}/`,
        method: 'PATCH',
        body:patient,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['categories'],
    }),
    deleteCategory:builder.mutation({ 
      query:(id)=>({
        url: `/categories/categories/${id}`,
        method: 'DELETE',
        body:id,
        
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['categories'],

    }),
  })

});

export const { useGetCategoryQuery, useGetAllCategoriesQuery, useAddCategoryMutation,
   useUpdateCategoryMutation, 
   useDeleteCategoryMutation} = categoriesApi;
import React,{useEffect, useState} from 'react'
import Card from "components/card/Card.js"
import FileUploadComponent from "../../../auth/signUp/fileupload";
import axios from 'axios';
import {   Box,
    Grid,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    Select,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue, } from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import {useAddUserMutation} from 'api/user';
import {useSignupMutation,useSendMailMutation } from 'api/auth';
import {useGetAllRolesQuery,  useGetRoleQuery} from 'api/role';
import {useGetUserQuery} from 'api/user';
import { RiEyeCloseLine } from "react-icons/ri";
import { useAuth } from "auth-context/auth.context";


function UserForm({onClose, isModified, setModified}) {
const [username, setName] = useState("");
const { user:{user} } = useAuth();
const [first_name, setFirstName] = useState("");
const {data:roles} = useGetAllRolesQuery()
const [last_name, setLastName] = useState("");
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [confirmPassword, setConfirmPassword] = useState("");
const [error, setError] = useState(undefined);
const [buttonText, setButtonText] = useState("Add User");
const [uploadedImageUrl, setUploadedImageUrl] = useState('')
const [selectedFile, setSelectedFile] = useState(null);
const [selectedRoleOption, setSelectedRoleOption] = useState(4);
console.log(uploadedImageUrl, 'img')
const textColor = useColorModeValue("navy.700", "white");
const textColorSecondary = "gray.400";
const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
const textColorBrand = useColorModeValue("brand.500", "white");
const brandStars = useColorModeValue("brand.500", "brand.400");
const errorText = useColorModeValue("red.500", "red.400");
const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
const googleText = useColorModeValue("navy.700", "white");
const googleHover = useColorModeValue(
  { bg: "gray.200" },
  { bg: "whiteAlpha.300" }
);
const googleActive = useColorModeValue(
  { bg: "secondaryGray.300" },
  { bg: "whiteAlpha.200" }

);



const [signup, { isLoading, isError, isSuccess, error:serverError}] = useSignupMutation();
const [sendMail,] = useSendMailMutation()
const [show, setShow] = React.useState(false);
const { data:authUser, errors } = useGetUserQuery(user?.id);
function toTitleCase(inputString) {
    return inputString
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

const [selectedOption, setSelectedOption] = useState('nurse');

useEffect(()=>{

}, [onClose, isModified, user?.city])

const handleSelectRoleChange = (event) => {
  setSelectedRoleOption(event.target.value);

};

console.log(authUser)

const handleFileUpload = async () => {
  if (selectedFile) {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('upload_preset', 'xhuvfous'); // Replace with your Cloudinary upload preset

      // Upload the file to Cloudinary using the Cloudinary API
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/daxgakzgm/image/upload`, // Replace 'your_cloud_name' with your Cloudinary Cloud Name
        formData
      );

      // Get the image URL from the Cloudinary response
      const imageUrl = await response.data.secure_url;

      // Set the uploaded image URL state
     await setUploadedImageUrl(imageUrl);

      // // Make a POST request to your backend to store the image URL
      // await axios.post('/api/store-image', { imageUrl });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
};

const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleClick = () => setShow(!show);
  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }
   
 
    if (password === "") {
      return setError(` Field must not be empty.`);
    }
    if(first_name === "") {
      return setError(` Field must not be empty.`);
    }
    if(selectedOption === "") {
      return setError(`Field must not be empty.`);
    }
    if(last_name === "") {
      return setError(`Field must not be empty.`)}
    if(confirmPassword === "") {
      return setError(`Field must not be empty.`);}
      if(password !== confirmPassword) {
        return setError(`Your password not match`)}
    try {
      setButtonText(isLoading?'Loading.....':'Add User');

      const datatoBackend ={
        username,
        first_name,
        last_name,
        email,
        occupation:selectedOption,
        imageUrl:uploadedImageUrl,
        role:Number(selectedRoleOption),
        city:user?authUser?.city:null,
        state:user?authUser?.state:null,
        password
      }

      console.log(datatoBackend)
      setButtonText('sending data.....')


      signup(datatoBackend)
      .unwrap() // Unwrap the result to access the data directly
      .then((userData) => {
        // console.log(userData, 'userData')
        setButtonText('User added successfully...sending email')
        setModified(true)
        // sendMail({email, password})
        // .unwrap()
        // .then((res)=>{
        //      onClose(false)
        //   // console.log(res, 'response')
        //   // setButtonText('User added successfully')
      
        // })
 

        // Handle successful login
        if(!userData){
          setButtonText(serverError?serverError?.data:'Add user');
        // return setError(data.detail);
        }
       
        // return setProfile(userData);
      })

      // let response = await AuthApi.Register({
      //   username: name,
      //   email,
      //   password,
      // });
      // if (response.data && response.data.success === false) {
      //   setButtonText("Sign up");
      //   return setError(response.data.msg);
      // }
    
    } catch (err) {
      console.log(err);
      setButtonText("Sign up");
      if (err.response) {
        return setError(err.response.data.msg);
      }
      return setError("There has been an error.");
    }
  };


  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <FormControl>
        <Grid
        mb='20px'
        
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
        >

          <div>
<FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                  
                FirstName<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder='FirstName'
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setFirstName(toTitleCase(event.target.value));
                  setError(undefined);
                }}
              />
</div>
<div>
<FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                LastName<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder='LastName'
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setLastName(toTitleCase(event.target.value));
                  setError(undefined);
                }}
              />
</div>
         <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Username<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder='Username'
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setName(event.target.value);
                  setError(undefined);
                }}
              />
            </div>
<div>
<FormLabel
               
               ms='4px'
               fontSize='sm'
               fontWeight='500'
               color={textColor}
               mb='8px'>
               ProfileImage<Text color={brandStars}>*</Text>
               <FileUploadComponent  setSelectedFile={setSelectedFile}/>
             </FormLabel> 
</div>
<FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='your email'
              mb='24px'
              defaultValue={email}
              fontWeight='500'
              size='lg'
              onChange={async (event) => {
                setEmail(event.target.value);
               await  handleFileUpload();
                setError(undefined);
              }}
            />


              <div>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Occupation<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select value={selectedOption} onChange={handleSelectChange}  mb='24px'>
        <option value="nurse">Nurse</option>
        <option value="pharmacy">Pharmacy</option>
        <option value="doctor">Doctor</option>
        <option value="nurse">Volunteer</option>
        <option value="other">Other</option>
      </Select>
      </div>
      <div>
      
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                roles<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select value={selectedRoleOption} onChange={handleSelectRoleChange}  mb='24px'>
                {
                  roles && roles.map(role =>(
                    <option value={role.id} key={role.id}>{role.name}</option>
                  ))
                }
       
       
      </Select>
      
      </div>
      <div>
      <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Password<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='auth'
                  onChange={(event) => {
                    setPassword(event.target.value);
                    setSelectedFile('')
                    setError(undefined);
                  }}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
      </div>
         
              <div>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                ConfirmPassword<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='auth'
                  onChange={(event) => {
                    setConfirmPassword(event.target.value);
                    setError(undefined);
                  }}
                />
                  <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              </div>

</Grid>
<Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                onClick={register}
              >
                {buttonText}
              </Button>
</FormControl>
        
        </Box>
     
    </Card>
  )
}

export default UserForm

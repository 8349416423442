import React from 'react'
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import EditForm from '../users/components/EditUser';

function Edit({isOpen, onOpen, id, onClose,}) {
  return (
 
    <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>User</ModalHeader>
      <ModalCloseButton />
        <EditForm id={id} onClose={onClose}/>
        </ModalContent>
        </Modal>
  )
}

export default Edit
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { authApi } from './api/auth'; // Replace this with your API configuration
import {usersApi} from './api/user'; // Replace this with your API configuration
import { nursesApi } from 'api/nurse';
import { patientsApi } from 'api/patient';
import {rolesApi} from 'api/role';
import authSlice from 'api/authSlice';
import { doctorsApi } from 'api/physician';
import { specialisationsApi } from 'api/specialisation';
import { pharmaciesApi } from 'api/pharmacy';
import { prescriptionsApi } from 'api/prescription';
import { vitalsignsApi } from 'api/vitalsign';
import { diagnosisApi } from 'api/diagnosis';
import { inventoriesApi } from 'api/inventories';
import { categoriesApi } from 'api/categories';
import { transactionsApi } from 'api/transaction';
import { dentistsApi } from 'api/dentists';
import { ophthamologistsApi } from 'api/ophthamology';




const store = configureStore({
  reducer: {
    // Add other reducers as needed
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [patientsApi.reducerPath]: patientsApi.reducer,
    [nursesApi.reducerPath]:nursesApi.reducer,
    [rolesApi.reducerPath]:rolesApi.reducer,
    [pharmaciesApi.reducerPath]:pharmaciesApi.reducer,
    [doctorsApi.reducerPath]:doctorsApi.reducer,
    [prescriptionsApi.reducerPath]:prescriptionsApi.reducer,
    [diagnosisApi.reducerPath]:diagnosisApi.reducer,
    [vitalsignsApi.reducerPath]:vitalsignsApi.reducer,
    [specialisationsApi.reducerPath]:specialisationsApi.reducer,
    [inventoriesApi.reducerPath]:inventoriesApi.reducer,
    [categoriesApi.reducerPath]:categoriesApi.reducer,
    [transactionsApi.reducerPath]:transactionsApi.reducer,
    [dentistsApi.reducerPath]:dentistsApi.reducer,
    [ophthamologistsApi.reducerPath]:ophthamologistsApi.reducer,
    authSlice,
    // [usersApi.reducerPath]: usersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(authApi.middleware)
    .concat(usersApi.middleware)
    .concat(patientsApi.middleware)
    .concat(nursesApi.middleware)
    .concat(pharmaciesApi.middleware)
    .concat(doctorsApi.middleware)
    .concat(specialisationsApi.middleware)
    .concat(vitalsignsApi.middleware)
    .concat(prescriptionsApi.middleware)
    .concat(diagnosisApi.middleware)
    .concat(inventoriesApi.middleware)
    .concat(categoriesApi.middleware)
    .concat(transactionsApi.middleware)
    .concat(dentistsApi.middleware)
    .concat(ophthamologistsApi.middleware)
    .concat(rolesApi.middleware),

});

// Add the listeners for automatic retries and cache invalidation
setupListeners(store.dispatch);

export default store;
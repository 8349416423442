import React,{useState} from 'react'
import Card from "components/card/Card.js"
import FileUploadComponent from "../../../auth/signUp/fileupload";
import {   Box,
    Grid,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    Select,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue, } from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import {useUpdateUserMutation, useGetUserQuery} from 'api/user';
import {useGetAllRolesQuery,  useGetRoleQuery} from 'api/role';
import { RiEyeCloseLine } from "react-icons/ri";


function EditForm({id}) {
const { data, isLoading, errors } = useGetUserQuery(id);
const [updateUser, {isSuccess}] = useUpdateUserMutation();

const {data:roles, isError} = useGetAllRolesQuery()
const [username, setName] = useState(data.username);
const [first_name, setFirstName] = useState(data.first_name);
const [last_name, setLastName] = useState(data.last_name);
const [email, setEmail] = useState(data.email);
const[city, setCity] = useState(data?.city);
const[state, setState] = useState(data?.state);
const [role, setRole] = useState(data.role);
const [occupation, setOccupation] = useState("");
const [error, setError] = useState(undefined);
const [buttonText, setButtonText] = useState("Edit User");
const [uploadedImageUrl, setUploadedImageUrl] = useState('')
const errorText = useColorModeValue("red.500", "red.400");
console.log(username, 'img')
const textColor = useColorModeValue("navy.700", "white");
const textColorSecondary = "gray.400";
const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
const textColorBrand = useColorModeValue("brand.500", "white");
const brandStars = useColorModeValue("brand.500", "brand.400");
const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
const googleText = useColorModeValue("navy.700", "white");
const googleHover = useColorModeValue(
  { bg: "gray.200" },
  { bg: "whiteAlpha.300" }
);
const googleActive = useColorModeValue(
  { bg: "secondaryGray.300" },
  { bg: "whiteAlpha.200" }

);

// let { user } = useAuth();


const [show, setShow] = React.useState(false);
function toTitleCase(inputString) {
    return inputString
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

const [selectedOccupationOption, setSelectedOccupationOption] = useState(data.occupation);



const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };


const handleSelectOccupationChange = (event) => {
    setSelectedOccupationOption(event.target.value);
  };

  const [selectedOption, setSelectedOption] = useState(Number(data.role));

  const {data:priviledge} =  useGetRoleQuery(Number(data.role))


  console.log(priviledge, 'profile')
//   const handleClick = () => setShow(!show);
  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }
    
    try {
      setButtonText(isLoading?isLoading:'Edit User');
      const datatoBackend ={
        id:data.id,
        username:username?username:data?.username,
        first_name:first_name?first_name:data?.first_name,
        last_name:last_name?last_name:data?.last_name,
        email:email?email:data?.email,
        // occupation:selectedOccupationOption?selectedOccupationOption:data?.occupation,
        role:selectedOption?Number(selectedOption):data?.role,
        imageUrl:uploadedImageUrl?uploadedImageUrl: data?.imageUrl,
        city:city?city:data?.city,
        state:state?state:data?.state
      }

      console.log(datatoBackend, 'test')

      updateUser(datatoBackend)
      .unwrap() // Unwrap the result to access the data directly
      .then((userData) => {
        console.log(userData, 'userData')
        // Handle successful login
        setButtonText('User updated');
        if(!userData){
          setButtonText(error?error:'Edit User');
        // return setError(data.detail);
        }
       
      })

   
    
    } catch (err) {
      console.log(err);
      setButtonText("Edit");
      if (err.response) {
        // return setError(err.response.data.msg);
        console.log(err.responsedata)
        return setError('something went wrong');
      }
      return setError("There has been an error.");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>Error: {errors.message}</div>;
  }

  if (!data) {
    return <div>User not found.</div>;
  }

  


  console.log(roles, 'roles')

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <FormControl>
        <Grid
        mb='20px'
        
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
        >
          <div>
<FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                FirstName<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.first_name}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setFirstName(toTitleCase(event.target.value));
                  setError(undefined);
                }}
              />
</div>
<div>
<FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                LastName<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.last_name}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setLastName(toTitleCase(event.target.value));
                  setError(undefined);
                }}
              />
</div>
         <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Username<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.username}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setName(event.target.value);
                  setError(undefined);
                }}
              />
            </div>
<div>
<FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder={data?.email}
              mb='24px'
            //   defaultValue={email}
              fontWeight='500'
              size='lg'
              onChange={(event) => {
                setEmail(event.target.value);
                setError(undefined);
              }}
            />
</div>


<FormLabel
               
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                ProfileImage<Text color={brandStars}>*</Text>
                <FileUploadComponent uploadedImageUrl={uploadedImageUrl} setUploadedImageUrl={setUploadedImageUrl}/>
              </FormLabel> 
              <div>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Occupation<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select value={selectedOccupationOption} onChange={handleSelectOccupationChange}  mb='24px'>
        <option value="nurse">Nurse</option>
        <option value="pharmacy">Pharmacy</option>
        <option value="doctor">Doctor</option>
        <option value="other">Other</option>
      </Select>
      </div>
      <div>
      
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                roles<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select value={selectedOption} onChange={handleSelectChange}  mb='24px'>
                {
                  roles && roles.map(role =>(
                    <option value={role.id} key={role.id}>{role.name}</option>
                  ))
                }
       
       
      </Select>
      
      </div>
         
      <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                City<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.city}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setCity(event.target.value);
                  setError(undefined);
                }}
              />
            </div>
            <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                State<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={data?.state}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setState(event.target.value);
                  setError(undefined);
                }}
              />
            </div>
             

</Grid>
<Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                onClick={register}
              >
                {buttonText}
              </Button>
</FormControl>
        
        </Box>
     
    </Card>
  )
}

export default EditForm

export const columnsDataCheck = [
  
      {
        Header: "ID",
        accessor: "Id",
      },
      {
        Header: "NAME",
        accessor: "name",
      },
      {
        Header: "TYPE",
        accessor: "type",
      },
      {
        Header: "SYMPTOMS",
        accessor: "symptoms",
      },
      {
          Header: "CAUSES",
          accessor: "causes",
        },
        {
            Header: "SEVERITY",
            accessor: "severity",
          },

          {
            Header: "LOCATION",
            accessor: "location",
          },

          {
            Header: "VIEW",
            accessor: "view",
          },
          {
            Header: "DELETE",
            accessor: "delete",
          },
  
  
   
  ];
  export const columnsDataComplex = [

    {
      Header: "ID",
      accessor: "Id",
    },
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "TYPE",
      accessor: "type",
    },
    {
      Header: "SYMPTOMS",
      accessor: "symptoms",
    },
    {
        Header: "CAUSES",
        accessor: "causes",
      },
      {
          Header: "SEVERITY",
          accessor: "severity",
        },

        {
          Header: "LOCATION",
          accessor: "location",
        },
        {
          Header: "VIEW",
          accessor: "view",
        },
        {
          Header: "DELETE",
          accessor: "delete",
        },

   
  ];
  
import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import NurseLayout from "layouts/nurse"
import DoctorLayout from "layouts/doctor/doctor";
import PharmacyLayout from "layouts/pharm"
import RTLLayout from "layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { AuthProvider } from "./auth-context/auth.context";
import { Provider } from 'react-redux'
import { ProtectedRoute } from "./layouts/protectedRoute.js";
import { ProtectedAdminRoute } from "layouts/protectedAdmin";
import ResetPassword from "views/auth/passwordReset/forgot_password"
import ChangePassword from "views/auth/passwordReset/confirmPassord"
import store from "./store";

let user = localStorage.getItem("user");
user = JSON.parse(user);
ReactDOM.render(
  <Provider store={store}>
       <React.StrictMode>
  <ChakraProvider theme={theme}>
    <AuthProvider userData={user}>

      <HashRouter>
        <Switch>
          {/* <Route path="/" component={AuthLayout} /> */}
          <Route path={`/auth`} component={AuthLayout} />
          <ProtectedAdminRoute path={`/admin`} component={AdminLayout} />
          <ProtectedRoute path={`/nurse`} component={NurseLayout} />
          <ProtectedRoute path={`/doctor`} component={DoctorLayout} />
          <ProtectedRoute path={'/pharm'} component={PharmacyLayout}/>
          <ProtectedRoute path={`/rtl`} component={RTLLayout} />
          <Route path={'/reset-password'} component={ResetPassword} />
          <Route path={'/change-password'} component={ChangePassword} />
        
          <Redirect from='/' to='/auth' />
          
        </Switch>
      </HashRouter>
 
    </AuthProvider>
  </ChakraProvider>
  </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

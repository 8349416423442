import React,{useCallback, useState} from 'react'
import Card from "components/card/Card.js"
import FileUploadComponent from "../../../auth/signUp/fileupload";
import {   Box,
    Grid,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    UnorderedList, 
     ListItem,
    Input,
    Select,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue, } from "@chakra-ui/react";
    import { useAuth } from "auth-context/auth.context";
    import { MdOutlineRemoveRedEye } from "react-icons/md";
    import {useAddVitalsignMutation,} from 'api/vitalsign';
    import { useGetAllSpecialisationsQuery } from 'api/specialisation';
    import { useGetAllPatientsQuery } from 'api/patient';
    import { useGetAllDoctorsQuery } from 'api/physician';
    import ListModal from "../../Diagnosis/components/listComponent";
  import ListDoctorModal from "./DoctoctorListModal"


function EditForm({onClose}) {

  const [addVitalsign, {isLoading, errors }] = useAddVitalsignMutation();
const {data:specialisation, isError} = useGetAllSpecialisationsQuery()
const {data:doctors} = useGetAllDoctorsQuery()
const { data:patients, } = useGetAllPatientsQuery()
const { user:{user} } = useAuth();
const [bloodPressure, setBloodPressure] = useState('');
const [weight, setWeight] = useState('');
const [painLevel, setPain] = useState('');
const [blood_glucose_level, setGlucoseLevel] = useState('');
const [urine_level, setUrineLevel] = useState('');
const [capillary_refill_time, setCapillaryRefillTime] = useState('');
const [pupil_repsonse, setPupilResponse] = useState('');
const[pulse_oximetry, setPulseOximetry] = useState('');
const[pain_assessment, setPainessment] = useState('');
const [pulseRate, setPulseRate] = useState('');
const [height, setHeight] = useState('');
const [search, setSearch] = useState('');
const [patientId, setPatientId] = useState('');
const [specialtyId, setSpecialtyId] = useState(1);
const[temperature, setTemperature] = useState('');
const [filteredPatients, setFilteredPatients] = useState('')
const [filteredDoctors, setFilteredDoctors] = useState('')
const [doctorId, setDoctorId] = useState('');
const [isOpen, setIsOpen] = useState(false);
const [username, setUsername] = useState('');
const [searchDoc, setSearchDoc] = useState('');
const [isOpenDoc, setIsOpenDoc] = useState(false);
const [doctorName, setDoctorName] = useState('');
const onListClose = () => setIsOpen(false);
const onOpen = () => setIsOpen(true);

const onCloseDoc = () => setIsOpenDoc(false);
const onOpenDoc = () => setIsOpenDoc(true);




const [error, setError] = useState(undefined);
const [buttonText, setButtonText] = useState("Add VitalSign");
const [uploadedImageUrl, setUploadedImageUrl] = useState('')
console.log(doctors, 'doctors')

const textColor = useColorModeValue("navy.700", "white");
const errorText = useColorModeValue("red.500", "red.400");

const brandStars = useColorModeValue("brand.500", "brand.400");

const  handleSearch =(e)=>{
  setSearch(e)
}

const  handleSearchDoc =(e)=>{
  setSearchDoc(e)
}


const handleFilter = (searchTerm) => {
  // Update the query key to include the search term
  searchTerm.toLowerCase().trim();

  if(searchTerm !=="" && patients) {

   const filterobj = patients.filter(item => item.name.toLowerCase().includes(searchTerm))

   console.log(filterobj)

    setFilteredPatients(filterobj)

  }else{
    setFilteredPatients([]);
  }

};



const handleFilterDoc = (searchTerm) => {
  // Update the query key to include the search term
  searchTerm.toLowerCase().trim();

  if(searchTerm !=="" && doctors) {

   const filterobj = doctors.filter(item => item.name.toLowerCase().includes(searchTerm))

   console.log(filterobj)

    setFilteredDoctors(filterobj)

  }else{
    setFilteredDoctors([]);
  }

};


// console.log(specialisation, 'profile')




const [selectedOption, setSelectedOption] = useState(Number(3));

console.log(selectedOption, 'profile')
const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
//   const handleClick = () => setShow(!show);
  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }

   
    if(selectedOption === "") {
      return setError(` Field must not be empty.`);
    }
    if(patientId=== "") {
      return setError(` Field must not be empty.`);
    }
    
    try {
      setButtonText(isLoading?'Loading...':'Add Vitalsign');
      const datatoBackend ={
        bloodPressure:bloodPressure,
        weight:weight,
        blood_glucose_level:blood_glucose_level,
        painLevel:painLevel,
        specialisation:Number(selectedOption),
        urine_level: urine_level,
        capillary_refill_time: capillary_refill_time,
        pupil_repsonse: pupil_repsonse,
        pulse_oximetry: pulse_oximetry,
        pain_assessment: pain_assessment,
        pulseRate: pulseRate,
        height: height,
        temperature: temperature,
        createdBy: Number(user.id),
        patient:Number(patientId)
        // profileImage:uploadedImageUrl,
      }

      console.log(datatoBackend, 'test')

      addVitalsign(datatoBackend)
      .unwrap() // Unwrap the result to access the data directly
      .then((userData) => {
        console.log(userData, 'userData')
        // Handle successful login
        setButtonText('VitalSign added successfully');
        onClose(false)
        if(!userData){
          setButtonText(error?error:'Edit Patient');
        // return setError(data.detail);
        }
       
      })

   
    
    } catch (err) {
      console.log(err);
      setButtonText("Edit");
      if (err.response) {
        // return setError(err.response.data.msg);
        console.log(err.responsedata)
        return setError('something went wrong');
      }
      return setError("There has been an error.");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>Error: {errors.message}</div>;
  }

 
 


  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <FormControl>
        <Grid
        mb='20px'
        
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
        >
          <div>
<FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Blood Pressure<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={''}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setBloodPressure(event.target.value)
                  setError(undefined);
                }}
              />
</div>
<div>
<FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Weight<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='weight'
              placeholder={''}
              mb='24px'
            //   defaultValue={weight}
              fontWeight='500'
              size='lg'
              onChange={(event) => {
                setWeight(event.target.value);
                setError(undefined);
              }}
            />
</div>
         <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Blood Glucose Level<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={''}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setGlucoseLevel(event.target.value);
                  setError(undefined);
                }}
              />
            </div>



{/* <FormLabel
               
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                ProfileImage<Text color={brandStars}>*</Text>
                <FileUploadComponent uploadedImageUrl={uploadedImageUrl} setUploadedImageUrl={setUploadedImageUrl}/>
              </FormLabel>  */}
              <div>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Type of Consultation<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Select value={selectedOption} onChange={handleSelectChange}  mb='24px'>
                {
                  specialisation && specialisation.map(role =>(
                    <option value={role.specialisationID} key={role.specialisationID}>{role.name}</option>
                  ))
                }
       
       
      </Select>
      </div>
      <div>
      <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Pain Level<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <InputGroup size='md'>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={''}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setPain(event.target.value);
                  setError(undefined);
                }}
              />


              
              </InputGroup>

              
        
      </div>
      <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Temperature<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={''}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setTemperature(event.target.value);
                  setError(undefined);
                }}
              />
            </div>
            <div>
      <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Urine Level<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <InputGroup size='md'>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={''}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setUrineLevel(event.target.value);
                  setError(undefined);
                }}
              />


              
              </InputGroup>

              
        
      </div>
      <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Height<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={''}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setHeight(event.target.value);
                  setError(undefined);
                }}
              />
            </div>  
             
            <div>
      <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Pulse Oximetry<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <InputGroup size='md'>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={''}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setPulseOximetry(event.target.value);
                  setError(undefined);
                }}
              />


              
              </InputGroup>

              
        
      </div>
      <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Pupil Repsonse<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={''}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setPupilResponse(event.target.value);
                  setError(undefined);
                }}
              />
            </div> 
            <div>
      <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Capillary Refill Time <Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <InputGroup size='md'>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={''}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setCapillaryRefillTime(event.target.value);
                  setError(undefined);
                }}
              />


              
              </InputGroup>

              
        
      </div>
      <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Pain Assessment<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={''}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setPainessment(event.target.value);
                  setError(undefined);
                }}
              />
            </div>   

            <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Pulse Rate<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={''}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setPulseRate(event.target.value);
                  setError(undefined);
                }}
              />
            </div>
            <div>
            <div>
            <Button colorScheme="blue" ml={3} onClick={()=> onOpen(true)}>{username?username:'Select Patient'}</Button>
            </div>  
          
         
            </div>  
            <div>
            <Button colorScheme="blue" ml={3} onClick={()=> onOpenDoc(true)}>{doctorName?doctorName:'Select Doctor'}</Button>
            </div> 
</Grid>
<div>
            <Box mt={4}>
                <ListModal
                isOpen={isOpen}
                onClose={onListClose}
                filteredPatients={filteredPatients}
                setPatientId={setPatientId}
                setSearch={setSearch}
                handleSearch={handleSearch}
                handleFilter={handleFilter}
                setError={setError}
                search={search}
                setUsername={setUsername}
                />
        </Box>
      
            </div>
            <div>
            <Box mt={4}>
                <ListDoctorModal
                isOpen={isOpenDoc}
                onClose={onCloseDoc}
                filteredPatients={filteredDoctors}
                setPatientId={setDoctorId}
                setSearch={setSearchDoc}
                handleSearch={handleSearchDoc}
                handleFilter={handleFilterDoc}
                setError={setError}
                search={searchDoc}
                setUsername={setDoctorName}
                />
        </Box>
      
            </div>
<Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                onClick={register}
              >
                {buttonText}
              </Button>
</FormControl>
        
        </Box>
     
    </Card>
  )
}

export default EditForm

import React from 'react'
import { Button, Box, Text } from '@chakra-ui/react';

function DeleteModal({pharmacy, message, handleDelete, onClose, user}) {
  return (
    <div>
      {
        user?.role ===5 || user.is_superuser?  
        
        <>
            {
        message?message: `You are about to delete this patient - ${pharmacy?.name}?`
      }

<Box style={{flex:'row', justifyContent:'center', alignItems:'center', marginTop:'20px',}}>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
              <Button colorScheme="red" mr={3} onClick={()=> handleDelete()}>Delete</Button>
              </Box>

        </>
        
        :

        <Box style={{flex:'row', justifyContent:'center', alignItems:'center', marginTop:'20px',}}>

        <Text>You are not authorised to delete this file</Text>
            </Box>
  
      }




        

      
      
     
    </div>
  )
}

export default DeleteModal
import React, { useState } from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import {  useGetPrescriptionQuery, useDeletePrescriptionMutation, 
  useUpdatePrescriptionStatusMutation, useUpdatePrescriptionMutation} from "api/prescription";
import Banner from "./banner";
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import EditVitalSign from "./EditPrescription"
import DeleteModal from './DeleteModal';
import { useAuth } from "auth-context/auth.context";



const MyModal = ({isOpen, onOpen, onClose, id, setDelete, isDelete}) => {
  const { user:{user} } = useAuth();
    const { data, isLoading } =  useGetPrescriptionQuery(id);
    const [updatePrescriptionStatus] = useUpdatePrescriptionStatusMutation()
    const [updatePrescription] = useUpdatePrescriptionMutation()
    const [buttonText, setButtonText] = useState("Delete");
    const [error, setError] = useState(undefined);
    const[deletePrescription, {isSuccess}] = useDeletePrescriptionMutation()
    const[edit, setEdit] = useState(false)
    // const [isDelete, setDelete] = useState(false)
    const [sold, setSold] = useState(false)
    const [msg, setMsg] = useState('')

    console.log(data, 'data')

    const handleShow = () => {
      setDelete(true)
      setEdit(false)

    }

    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    if (!data) {
      return <div>Nurse not found.</div>;
    }

    const handleStatusUpdate =()=>{

      const statusObj ={
        prescriptionId: data?.prescriptionId,
        drugId: data?.drugId,
      }

      console.log(statusObj, 'stat')

      try{
        updatePrescriptionStatus(statusObj)
        .unwrap()
        .then((userData)=>{
          console.log(userData, 'userData')
          const{message, error} = userData;
          setMsg(message?message:error)
          setButtonText(message);
        })

        setSold(true)

      }catch(e){
        console.log(e)
      }

    }

    const handleDelete = () => {

      const tosend={
        id:id,
        user:user?.id
      }
    try{
      deletePrescription(tosend)
          .unwrap() // Unwrap the result to access the data directly
          .then((userData) => {
            console.log(userData, 'userData')
            const{message, error} = userData;
            setMsg(message?message:error)
            setButtonText('Nurse Deleted');
            if(!userData){
              setButtonText(error?error:'Delete');
            // return setError(data.detail);
            }
           
          })
    
        } catch (err) {
          console.log(err);
          setButtonText("Edit");
          if (err.response) {
            // return setError(err.response.data.msg);
            console.log(err.responsedata)
            return setError('something went wrong');
          }
          return setError("There has been an error.");
        }
      
    }

    console.log(data.status, 'pres')
  
    return (
      <>
        {/* <Button onClick={onOpen}>Open Modal</Button> */}
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Prescription</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {
                edit? 
                
                <EditVitalSign
                 id={id}/>
                :isDelete?
              
              <DeleteModal nurse={data} message={msg} handleDelete={handleDelete} setDelete={setDelete}/>
              :
              <Banner
              gridArea='1 / 1 / 2 / 2'
              banner={banner}
              avatar={avatar}
              data={data}
              name='Adela Parkson'
              job='Product Designer'
              posts='17'
              followers='9.7k'
              following='274'
            /> 
              }
        
            </ModalBody>
             
                
                <ModalFooter>
               
                {
                  edit || !isDelete? 
                  user?.role == 5 || user?.role ==2 || user?.is_superuser ? 
                  <>
                   <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Close
                </Button>
                      <Button colorScheme="green" mr={3} onClick={()=>setEdit(true)}>Edit</Button>

               
                  
{/* <             Button colorScheme="red" mr={3} onClick={()=> handleShow()}>Delete</Button> */}
                  </>
              
                  :
                  <div style={{flexDirection:'column'}}>
                      <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Close
                </Button>
             
                {!isDelete?
              
                 
                  data.status === "fulfiled"?  
                  
                  <Button colorScheme="red" mr={3}>{msg? msg:'fulfilled'}</Button>
                  :
                  <Button colorScheme="red" mr={3} onClick={ handleStatusUpdate}>{msg? msg:'Dispense'}</Button>
                
                 
             
              :

              
              null

                
                 
               
                }

{/* <DeleteModal nurse={data} message={msg}/> */}
 
</div>
  :null
                }
            
               
               
              </ModalFooter>
              
           
          
          </ModalContent>
        </Modal>
      </>
    );
  };
  
  export default MyModal;
  
// api/users.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";




export const ophthamologistsApi = createApi({
  reducerPath:'ophthamologist',
  tagTypes: ['ophthamologist'],
  baseQuery: fetchBaseQuery({baseUrl: `${API_SERVER}/api`,
  prepareHeaders: (headers, { getState }) => {
    const {authSlice:{token}} = getState();
 
    let useToken = localStorage.getItem('user');
    const mytoken =JSON.parse(useToken).token
    if (token || mytoken) {
      headers.set('Authorization', `Token ${mytoken?mytoken:token}`);
    }
    return headers;
  },

}), // Replace with your API base URL
endpoints: (builder) => ({
    getOphthamologist: builder.query({
      query: (id) => `/ophtha/ophtha/${id}/`, // Replace with your backend endpoint
      headers:{'Content-Type': 'application/json'},
    }),
    // providesTags:['patients'],
    getAllOphthamologists: builder.query({
      query: () =>({
        url:`/ophtha/ophtha/`,
        headers:{'Content-Type': 'application/json'},
      }),
      transformErrorResponse:res=>res.sort((a,b)=> b.id - a.id),
      invalidatesTags: ['ophthamologists'],
    }),
    addOphthamologist: builder.mutation({
      query:(obj)=>({
        url:'/ophtha/ophtha/',
        method: 'POST',
        body:obj,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['ophthamologists'],
    }),
    updateOphthamologist:builder.mutation({
      query:(obj)=>({
        url:`/ophtha/ophtha/${obj.ophthalmologistID}/`,
        method: 'PATCH',
        body:obj,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['ophthamologists'],
    }),
    deleteOphthamologist:builder.mutation({ 
      query:(obj)=>({
        url: `/ophtha/ophtha/${obj.id}`,
        method: 'DELETE',
        body:obj,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['ophthamologists'],

    }),
  })

});

export const { useGetOphthamologistQuery, useGetAllOphthamologistsQuery, useAddOphthamologistMutation,
   useUpdateOphthamologistMutation, 
   useDeleteOphthamologistMutation} = ophthamologistsApi;
export const columnsDataCheck = [
    {
        Header: "AVATAR",
        accessor: "avatar",
      },
      {
        Header: "ID",
        accessor: "Id",
      },
      {
        Header: "FULLNAME",
        accessor: "fulname",
      },
      {
        Header: "EMAIL",
        accessor: "email",
      },
      {
        Header: "ADDRESS",
        accessor: "address",
      },
      {
          Header: "PHONE",
          accessor: "phone",
        },
        {
            Header: "GENDER",
            accessor: "gender",
          },

          {
            Header: "VIEW",
            accessor: "view",
          },
          {
            Header: "DELETE",
            accessor: "delete",
          },

        
  
   
  ];
  export const columnsDataComplex = [
    {
      Header: "AVATAR",
      accessor: "avatar",
    },
    {
      Header: "ID",
      accessor: "Id",
    },
    {
      Header: "FULLNAME",
      accessor: "fulname",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "ADDRESS",
      accessor: "address",
    },
    {
        Header: "PHONE",
        accessor: "phone",
      },
      {
        Header: "GENDER",
        accessor: "gender",
      },
      {
        Header: "VIEW",
        accessor: "view",
      },
      {
        Header: "DELETE",
        accessor: "delete",
      },
  
  ];
  
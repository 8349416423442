// api/users.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";




export const inventoriesApi = createApi({
  reducerPath:'inventories',
  tagTypes: ['inventories'],
  baseQuery: fetchBaseQuery({baseUrl: `${API_SERVER}/api`,
  prepareHeaders: (headers, { getState }) => {
    const {authSlice:{token}} = getState();
 
    console.log(token , 'state token');
    let useToken = localStorage.getItem('user');
    const mytoken =JSON.parse(useToken).token
    if (token || mytoken) {
      headers.set('Authorization', `Token ${mytoken?mytoken:token}`);
    }
    return headers;
  },

}), // Replace with your API base URL
endpoints: (builder) => ({
    getInventory: builder.query({
      query: (id) => `/inventories/inventories/${id}/`, // Replace with your backend endpoint
      headers:{'Content-Type': 'application/json'},
    }),
    // providesTags:['patients'],
    getAllInventories: builder.query({
      query: () =>({
        url:`/inventories/inventories/`,
        headers:{'Content-Type': 'application/json'},
      }),
      transformErrorResponse:res=>res.sort((a,b)=> b.id - a.id),
      invalidatesTags: ['inventories'],
    }),
    addInventory: builder.mutation({
      query:(patient)=>({
        url:'/inventories/inventories/',
        method: 'POST',
        body:patient,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['inventories'],
    }),
    updateInventory:builder.mutation({
      query:(patient)=>({
        url:`/inventories/inventories/${patient.inventoriesId}/`,
        method: 'PATCH',
        body:patient,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['inventories'],
    }),
    deleteInventory:builder.mutation({ 
      query:(obj)=>({
        url: `/inventories/inventories/${obj.id}`,
        method: 'DELETE',
        body:obj,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['inventories'],

    }),
  })

});

export const { useGetInventoryQuery, useGetAllInventoriesQuery, useAddInventoryMutation,
   useUpdateInventoryMutation, 
   useDeleteInventoryMutation} = inventoriesApi;
import React,{useEffect, useState} from 'react'
import {
    Avatar,
    Box,
    Flex,
    FormLabel,
    Icon,
    Select,
    Text,
    SimpleGrid,
    useColorModeValue,
  } from "@chakra-ui/react"
 
  import {
    columnsDataCheck,
    columnsDataComplex,
  } from "./components/patientColumn";

  import PatientTable from "./components/patientTable"
  import {useGetAllPatientsQuery } from "api/patient";
  import IconBox from "components/icons/IconBox";
  import AddPatientModal from "./components/AddPatientModal";
  import { useAuth } from "auth-context/auth.context";
  import {
    MdAddTask,
    // MdAttachMoney,
    // MdBarChart,
    // MdFileCopy,
  } from "react-icons/md";

function Patients() {
    const [isOpen, setIsOpen] = useState(false);
    const { user:{user} } = useAuth();
    const [isAddPatientOpen, setIsAddPatientOpen] = useState(false);
    const [isDelete, setDelete] = useState(false)
    const brandColor = useColorModeValue("brand.500", "white");
    let mainText = useColorModeValue("navy.700", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const [userID, setUserId] = useState()
    const onClose = () => {setIsOpen(false); setDelete(false)}
    const onOpen = () => setIsOpen(true);
    const onOpenAddPatient = () => setIsAddPatientOpen(true);
    const onCloseAddPatient = () => setIsAddPatientOpen(false);
    const {data:patients, error, isLoading} = useGetAllPatientsQuery();
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
      );
      const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.100" }
      );

    // console.log(patients, 'allusers')


    // useEffect(()=>{}, [patients])
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    if (!patients) {
      return <div>User not found.</div>;
    }

  const tableData =()=>{
  
    let result=[];

    patients && patients.map(patient =>{
      const{patientId, name, address, email, profileImage, gender, phone, age} = patient;
      result.push({ profileImage, patientId, name, email, address, gender, phone, age})
    })

    return result;
  }


  if(patients.length>0){

  return (
 <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
 <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px' mt="30px">
 {
              user?.is_superuser || user?.role ==5?(

                <SimpleGrid 
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                onClick={onOpenAddPatient}
                >
             <IconBox
                         w='56px'
                         h='56px'
                         bg={boxBg}
                         icon={
                           <Icon w='32px' h='32px' as={MdAddTask} color={brandColor} />
                         }
                       />
                     
                <Text
                       color={textColor}
                       fontSize='14px'
                       fontWeight='700'
                       lineHeight='100%'>
                       Add Patient
                     </Text>
                </SimpleGrid>

              ): null
              
            }
 
  <PatientTable
  columnsData={columnsDataComplex}
  patientData={tableData()}
  isOpen={isOpen}
  onOpen={onOpen}
  onClose={onClose}
  isDelete={isDelete}
  setDelete={setDelete}
/>

       
        <AddPatientModal 
        isOpen={isAddPatientOpen}
        onClose={onCloseAddPatient}
        onOpen={onOpenAddPatient}
        userID={userID}
          />

      </SimpleGrid>

 </Box>
  )
    
          }else{
            return <div>Loading...</div>
          }
}

export default Patients
// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue, Grid, } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import Information from "components/information/information";
import { formatDate } from "components/DateFormater/dateFormater";
import banner from "assets/img/auth/banner.png";

export default function Banner(props) {
  const { banner, avatar, job, posts, followers, following,data } = props;
  const {inventory, service, doctor, patient, createdBy, createdAt, pharmacy, transaction_amount} = data;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  console.log(data, 'nurse')
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      {/* <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      /> */}
    
      <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {inventory}
      </Text>
      <Text color={textColorSecondary} fontSize='sm'>
        {transaction_amount}
      </Text>
      {/* <Flex w='max-content' mx='auto' mt='26px'>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {posts}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Patients
          </Text>
        </Flex>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {followers}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Consultations
          </Text>
        </Flex>
      </Flex> */}
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>

        <Information
        //   boxShadow={cardShadow}
          title='service type'
          value={service}
        />
         <Information
        //   boxShadow={cardShadow}
          title='Doctor name'
          value={doctor}
        />
         {/* <Information
        //   boxShadow={cardShadow}
          title='Date of Birth'
          value={dateOfBirth}
        /> */}
         <Information
        //   boxShadow={cardShadow}
          title='Amount'
          value={transaction_amount}
        />
          {/* <Information
        //   boxShadow={cardShadow}
          title='Date of Birth'
          value={dateOfBirth}
        /> */}
         <Information
        //   boxShadow={cardShadow}
          title='Pharmacist'
          value={pharmacy}
        />
          <Information
        //   boxShadow={cardShadow}
          title='Created At'
          value={formatDate(createdAt, 'yyyy-MM-dd')}
        />
         <Information
        //   boxShadow={cardShadow}
          title='Created by'
          value={createdBy?createdBy:null} 
        />

        </Grid>

    </Card>
  );
}

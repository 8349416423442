import {
    Flex,
    Table,
    Progress,
    Icon,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Avatar,
    SimpleGrid,
  } from "@chakra-ui/react";
  import UserModal from '../userModal'
  import { MdVisibility, MdDelete  } from 'react-icons/md'
  import {
    MdAddTask,
    // MdAttachMoney,
    // MdBarChart,
    // MdFileCopy,
  } from "react-icons/md";
  import React, { useEffect, useMemo, useState } from "react";
  import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
  } from "react-table";
  import Profile from "assets/img/avatars/avatar6.png"
  import IconBox from "components/icons/IconBox";
  import Pagination from "components/pagination/pagination";
  import { useGetAllRolesQuery} from "api/role"
  import { useDeleteUserMutation } from "api/user";
  import DeleteModal from './DeleteModal';
  
  
  // Custom components
  import Card from "components/card/Card";
  import Menu from "components/menu/MainMenu";
  import { useAuth } from "auth-context/auth.context";
  



  export default function ColumnsTable(props) {
    const { columnsData, tableData, isOpen, onOpen, onClose, isDelete, setDelete, isModified, setModified} = props;
    const { user:{user} } = useAuth();
    const [currentPage, setCurrentPage] = useState(1);
    // const [isDelete, setDelete] = useState(false)
    // const [msg, setMsg] = useState('')
    // const[deleteUser, {isSuccess}] = useDeleteUserMutation()
    // const [info, setInfo] = useState("")
    // const [buttonText, setButtonText] = useState("Delete");
    // const [error, setError] = useState(undefined);

  

    const [userID, setUserId] = useState();
    // const brandColor = useColorModeValue("brand.500", "white");
    // let mainText = useColorModeValue("navy.700", "white");
    // const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const {data:roles} =  useGetAllRolesQuery();
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);
  
 
  
    const tableInstance = useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
    } = tableInstance;
    initialState.pageSize = data?.length;

    let pageSize = 5
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;


   const nPage =Math.ceil(initialState?.pageSize/ pageSize);

  //  const [localState, setLocalstate] =  useState(data)

   const brandColor = useColorModeValue("brand.500", "white");
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
      );
      const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.100" }
      );

      const handlePageChange = (page) => {
        setCurrentPage(page);
        // You can also fetch data for the new page here
      };


      // useEffect(() => {
      //   setLocalstate(data)
      // },[userID, data])

   
      const displayRole =  (num)=>{
        let myrole ='';
        if(num && roles){
          roles && roles.map(role =>{
            if(role.id === num)
            myrole = role?.name
          })
    
        }
          return myrole;
      }


      useEffect(() => {

      },[page, isModified])


    return (
      <Card
        direction='column'
        w='100%'
        px='0px'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
          <Text
            color={textColor}
            fontSize='14px'
            fontWeight='700'
            lineHeight='100%'>
            Users
          </Text>
        </Flex>
        <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page && page.slice(firstIndex, lastIndex).map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                >
                  {console.log(row.cells, 'row')}
                
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "AVATAR") {
                      data = (
                        <Avatar
                        mx='auto'
                        src={row.original.imageUrl?row.original.imageUrl:Profile}
                        h='50px'
                        w='50px'
                        mt='1px'
                        border='4px solid'
                        borderColor={borderColor}
                      />
                      );
                    } else if (cell.column.Header === "STATUS") {
                      data = (
                        <Flex align='center'>
  
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {row.original.is_active?"active":"disabled"}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "FIRSTNAME") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                         {row.original.first_name}
                        </Text>
                      );
                    } else if (cell.column.Header === "LASTNAME") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {row.original.last_name}
                       </Text>
                      );
                    }else if(cell.column.Header === "EMAIL") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {row.original.email}
                       </Text>
                      );
                    }else if(cell.column.Header === "ROLE") {
                      console.log(row.original.role, 'org')
                      console.log(displayRole(row.original?.role), 'rl')
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {displayRole(row.original?.role)}
                       </Text>
                      )
                    }else if(cell.column.Header === "VIEW") {
                      data = (
                        // <Text color={textColor} fontSize='sm' fontWeight='700'>
                        // handleViewChange(row.original.id)
                        <IconBox
              w='56px'
              h='56px'
              _hover={{ cursor: 'pointer',}}
              onClick={()=>{onOpen(); setUserId(row.original.id)}}
              icon={
                <Icon w='32px' h='32px' as={MdVisibility} color={brandColor} />
              }
            />
                  
                      )
                    }else if(cell.column.Header === "DELETE") {
                      data = (
                        // <Text color={textColor} fontSize='sm' fontWeight='700'>
                        // handleViewChange(row.original.id)
                        <IconBox
              w='56px'
              h='56px'
              _hover={{ cursor: 'pointer',}}
              onClick={()=>{onOpen(); setUserId(row.original.id); setDelete(true)}}
              icon={
                <Icon w='32px' h='32px' as={MdDelete} color={'red'} />
              }
            />
                  
                      )
                    }
                    
                    
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        maxH='30px !important'
                        py='8px'
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
         
        </Table>
        <Pagination currentPage={currentPage} totalPages={nPage} onPageChange={handlePageChange} />
        {
          isOpen? 
          <UserModal isOpen={isOpen} 
          onOpen={onOpen}
           onClose={onClose} 
           id={userID} 
           setDelete={setDelete} 
           isDelete={isDelete}
           isModified={isModified}
           setModified={setModified}
           />
          :
          
          null
        }
      
      </Card>
    );
  }
  
import React,{useEffect, useState} from 'react'
import {
    Avatar,
    Box,
    Flex,
    FormLabel,
    Icon,
    Select,
    Text,
    SimpleGrid,
    useColorModeValue,
  } from "@chakra-ui/react"
 
  import {
    columnsDataCheck,
    columnsDataComplex,
  } from "./components/column";
  import { useAuth } from "auth-context/auth.context";
  import PrescriptionTable from "./components/PrescriptionTable"
  import {useGetAllPrescriptionsQuery } from "api/prescription";
  import IconBox from "components/icons/IconBox";
  import AddPrecsiption from "./components/AddPrescription";
  import {
    MdAddTask,
    // MdAttachMoney,
    // MdBarChart,
    // MdFileCopy,
  } from "react-icons/md";

function Prescription() {
    const [isOpen, setIsOpen] = useState(false);
    const [isAddPatientOpen, setIsAddPatientOpen] = useState(false);
    const [isDelete, setDelete] = useState(false)
    const { user:{user} } = useAuth();
    const {data:prescriptions, error, isLoading} = useGetAllPrescriptionsQuery();
    const brandColor = useColorModeValue("brand.500", "white");
    let mainText = useColorModeValue("navy.700", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const [userID, setUserId] = useState()
    const onClose = () => {setIsOpen(false); setDelete(false)};
    const onOpen = () => setIsOpen(true);
    const onOpenAddPatient = () => setIsAddPatientOpen(true);
    const onCloseAddPatient = () => setIsAddPatientOpen(false);
    
    useEffect(()=>{

    }, [prescriptions])


    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
      );
      const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.100" }
      );

    console.log(prescriptions, 'allusers')
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    if (!prescriptions) {
      return <div>User not found.</div>;
    }

  const tableData = ()=>{
  
    let result=[];

    prescriptions && prescriptions.map(sign =>{
      const{prescriptionId,  drugName, prescription, status, doctor, diagnoses, pharmacy, createdby, patient,
    } = sign;
      result.push({ drugName, prescriptionId, prescription, doctor, status, diagnoses, createdby, pharmacy, patient,
    })
    })

    return result;
  }





  return (
 <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
 <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px' mt="30px">
  {
    user?.role === 2 || user?.role === 5 || user?.superuser?  
    <SimpleGrid 
    _hover={bgHover}
    _focus={bgFocus}
    _active={bgFocus}
    onClick={onOpenAddPatient}
    >
 <IconBox
             w='56px'
             h='56px'
             bg={boxBg}
             icon={
               <Icon w='32px' h='32px' as={MdAddTask} color={brandColor} />
             }
           />
    <Text
           color={textColor}
           fontSize='14px'
           fontWeight='700'
           lineHeight='100%'>
           Add Precsription
         </Text>
    </SimpleGrid>
    
    :

    null
  }

        <PrescriptionTable
          columnsData={columnsDataComplex}
          patientData={tableData()}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          isDelete={isDelete}
          setDelete={setDelete}
        />
        <AddPrecsiption 
        isOpen={isAddPatientOpen}
        onClose={onCloseAddPatient}
        onOpen={onOpenAddPatient}
        userID={userID}
          />

      </SimpleGrid>

 </Box>
  )
}

export default Prescription;
// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue,  Grid } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import Information from "components/information/information";
import { formatDate } from "components/DateFormater/dateFormater";
import React from "react";
import {useGetUserQuery} from 'api/user';

export default function Banner(props) {
  const { banner, info } = props;
 
  const {name,patientId, phone, imageUrl, email, address, gender, age, createdAt, updatedAt, createdBy } = info;
  // Chakra Color Mode
  const {data:user} = useGetUserQuery(createdBy??createdBy)
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  // const { data, isLoading, errors } = useGetUserQuery(2);
  // const {first_name, last_name} = data??data;
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={imageUrl}
        h='87px'
        w='87px'
        mt='-43px'
        border='4px solid'
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize='sm'>
        {email}
      </Text>
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>

        <Information
        //   boxShadow={cardShadow}
          title='address'
          value={address}
        />
         <Information
        //   boxShadow={cardShadow}
          title='Phone'
          value={phone}
        />
         {/* <Information
        //   boxShadow={cardShadow}
          title='Date of Birth'
          value={dateOfBirth}
        /> */}
         <Information
        //   boxShadow={cardShadow}
          title='Gender'
          value={gender}
        />
          <Information
        //   boxShadow={cardShadow}
          title='Age'
          value={age}
        />
         {/* <Information
        //   boxShadow={cardShadow}
          title='Gender'
          value={gender}
        /> */}
          <Information
        //   boxShadow={cardShadow}
          title='Created At'
          value={formatDate(createdAt, 'yyyy-MM-dd')}
        />
         <Information
        //   boxShadow={cardShadow}
          title='Created by'
          value={user? `${user?.first_name} ${user.last_name}`: null} 
        />

        </Grid>
    </Card>
  );
}

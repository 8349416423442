// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue, Grid, } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import Information from "components/information/information";
import { formatDate } from "components/DateFormater/dateFormater";
import { useGetDoctorQuery } from "api/physician";
import { useGetUserQuery } from "api/user";
import { useGetPatientQuery } from "api/patient";
import {useGetDiagnosisQuery} from "api/diagnosis";
import banner from "assets/img/auth/banner.png";

export default function Banner(props) {
  const { banner, avatar, job, posts, followers, following,data } = props;
  const {drugName, prescription, doctor, blood_glucose_level, diagnoses, urine_level, capillary_refill_time, pupil_repsonse,
    pulse_oximetry, specialisation, height, patient,comment_notes, pulseRate, createdBy,temperature, createdAt, gender, email} = data;
  const{data:consultation} = useGetDoctorQuery(doctor)
  const {data:patientName} = useGetPatientQuery(patient)
  const {data:diagnosis} = useGetDiagnosisQuery(diagnoses)
  const{data:user} = useGetUserQuery(createdBy)
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  console.log(user, 'nurse')
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
    <Grid
      mb='20px'
      templateColumns={{
        base: "1fr",
        lg: "repeat(1, 1fr)",
        "2xl": "1.34fr 1.62fr 1fr",
      }}
    >

<Information
        //   boxShadow={cardShadow}
          title='Patient Info'
          value={patientName?patientName.name:null}
        />

    </Grid>
     
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(1, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>

        <Information
        //   boxShadow={cardShadow}
          title='Drug Name'
          value={drugName}
        />
         <Information
        //   boxShadow={cardShadow}
          title='Prescription'
          value={prescription}
        />
       
      <Information
        //   boxShadow={cardShadow}
          title='Doctor name'
          value={consultation?consultation.name:null} 
        /> 
        
        <Information
        //   boxShadow={cardShadow}
          title='Created At'
          value={formatDate(createdAt, 'yyyy-MM-dd')}
        /> 
          

        </Grid>
        {/* <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
        >
           
   
         <Information
        //   boxShadow={cardShadow}
          title='Created By'
          value={user?`${user.first_name} ${user.last_name}`:null} 
        />   

<Information
        //   boxShadow={cardShadow}
          title='Pulse Oxymetry'
          value={pulse_oximetry} 
        />  
       
        </Grid> */}

    </Card>
  );
}

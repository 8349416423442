

// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React from "react";
import {
  MdAccessibility,
  MdAttachMoney,
  MdLocalHospital,
  MdLocalPharmacy,
  MdPeople,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import {FaUserDoctor}from "react-icons/fa6"
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import { useAuth } from "auth-context/auth.context";
import { useGetUserQuery, useGetAllUsersQuery } from "api/user";
import { useGetAllPatientsQuery } from "api/patient";
import { useGetAllNursesQuery } from "api/nurse";
import { useGetAllPharmaciesQuery } from "api/pharmacy";
import { useGetAllDoctorsQuery } from "api/physician";
import { useGetAllTransactionsQuery } from "api/transaction";
import { useGetAllInventoriesQuery } from "api/inventories";
import { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import LocationComponent from "variables/location"

export default function UserReports() {
  // Chakra Color Mode
  const history = useHistory();
  const brandColor = useColorModeValue("brand.500", "white");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  let { user } = useAuth();
  // console.log(user.user.id, 'profile')
  const { data, error, isLoading } = useGetUserQuery(user?.user.id);
  const {data:patients} = useGetAllPatientsQuery()
  const {data:nurses} = useGetAllNursesQuery()
  const {data:users} = useGetAllUsersQuery();
  const {data:pharmacies} = useGetAllPharmaciesQuery()
  const {data:doctors} = useGetAllDoctorsQuery()
  const {data:transactions} = useGetAllTransactionsQuery();
  const {data:inventories} = useGetAllInventoriesQuery();





  const getTotal = ()=>{
    let total = 0;
    for(let i=0; i<transactions.length; i++){
      if(transactions[i].transaction_amount){
        total += transactions[i].transaction_amount
      }
    }
    return total;
  }

  // console.log(users, 'allusers')

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!users) {
    return <div>User not found.</div>;
  }


  const tableData = ()=>{
  
    let result=[];

    users && users.slice(0, 8).map(user =>{
      const{id, first_name, last_name, email, imageUrl, is_active, role} = user;
      result.push({ imageUrl, id, first_name, last_name, email, is_active, role})
    })

    return result;
  }

  const handleClick = (url) => {
    // Use history.push() to change the URL navigation
    history.push(url); // Replace '/new-url' with the desired URL
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'
        >
          <SimpleGrid
            _hover={{ cursor: 'pointer',}}
            onClick={()=>handleClick('/admin/users')}
          >
   <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdPeople} color={brandColor} />
              }
            />
          }
          name='Users'
          value={users?users.length:null}
        />
          </SimpleGrid>
          <SimpleGrid
            _hover={{ cursor: 'pointer',}}
            onClick={()=>handleClick('/admin/patients')}
          >

         
        <MiniStatistics
     
        _hover={{ cursor: 'pointer',}}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAccessibility} color={brandColor} />
              }
            />
          }
          name='Patients'
          value={patients?patients?.length:null}
        />
           </SimpleGrid>
        {/* <MiniStatistics growth='+23%' name='Nurse' value={nurses?.length}  */}

        <SimpleGrid
            _hover={{ cursor: 'pointer',}}
            onClick={()=>handleClick('/admin/nurses')}
           
          >

          
        <MiniStatistics name='Nurse' value={nurses?nurses?.length:null} 
 startContent={
  <IconBox
    w='56px'
    h='56px'
    bg={boxBg}
    icon={
      <Icon w='32px' h='32px' as={MdLocalHospital} color={brandColor} />
    }
  />
}
        
        />

</SimpleGrid>
<SimpleGrid
   _hover={{ cursor: 'pointer',}}
   onClick={()=>handleClick('/admin/pharmacies')}
>
<MiniStatistics  name='Pharmacy' value={pharmacies?pharmacies.length:null}
         startContent={
          <IconBox
            w='56px'
            h='56px'
            bg={boxBg}
            icon={
              <Icon w='32px' h='32px' as={MdLocalPharmacy} color={brandColor} />
            }
          />
        }
        />
</SimpleGrid>
       
<SimpleGrid
   _hover={{ cursor: 'pointer',}}
   onClick={()=>handleClick('/admin/doctors')}
>
        <MiniStatistics  name='Doctors' value={doctors?doctors?.length:null} 
         startContent={
          <IconBox
            w='56px'
            h='56px'
            bg={boxBg}
            icon={
              <Icon w='32px' h='32px' as={FaUserDoctor} color={brandColor} />
            }
          />
        }
        />
     
  </SimpleGrid>
  <SimpleGrid
   _hover={{ cursor: 'pointer',}}
   onClick={()=>handleClick('/admin/records')}
>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Total Transactions'
          value={transactions?getTotal(): null}
        />
        </SimpleGrid>
      </SimpleGrid>

      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid> */}
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid> */}
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <ComplexTable
      
          columnsData={columnsDataComplex}
          tableData={tableData()}

          // users={users}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          {/* <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} /> */}
                {/* <LocationComponent/> */}
        </SimpleGrid>
      </SimpleGrid>

    </Box>
  );
}

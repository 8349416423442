import React,{useState} from 'react'
import Card from "components/card/Card.js"
import FileUploadComponent from "../../../auth/signUp/fileupload";
import {   Box,
    Grid,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    Select,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue, } from "@chakra-ui/react";
    import { MdOutlineRemoveRedEye } from "react-icons/md";
    import { convertToFormattedDate } from 'components/DateFormater/formatDateString'
  import {useAddDentistMutation} from 'api/dentists';
  import { useGetAllSpecialisationsQuery } from 'api/specialisation';
  import DatePicker from 'react-datepicker';
  import 'react-datepicker/dist/react-datepicker.css';
  import { useAuth } from "auth-context/auth.context";
  import {myState} from "./state"


function DoctorForm() {
const [address, setAddress] = useState("");
const [phone, setPhone] = useState("");
const [fullName, setName] = useState("");
const [email, setEmail] = useState("");
const [license, setLicenseNumber] = useState("");
const [state, setState] = useState("");
const [expiry, setExpiry] = useState(new Date());
const {data:specialisation} = useGetAllSpecialisationsQuery()
const { user:{user} } = useAuth();
const [selectedDate, setSelectedDate] = useState(new Date());
const [error, setError] = useState(undefined);
const [buttonText, setButtonText] = useState("Add Dentist");
const [uploadedImageUrl, setUploadedImageUrl] = useState('')
console.log(uploadedImageUrl, 'img')
const textColor = useColorModeValue("navy.700", "white");
const textColorSecondary = "gray.400";
const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
const textColorBrand = useColorModeValue("brand.500", "white");
const brandStars = useColorModeValue("brand.500", "brand.400");
const errorText = useColorModeValue("red.500", "red.400");
const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
const googleText = useColorModeValue("navy.700", "white");
const googleHover = useColorModeValue(
  { bg: "gray.200" },
  { bg: "whiteAlpha.300" }
);
const googleActive = useColorModeValue(
  { bg: "secondaryGray.300" },
  { bg: "whiteAlpha.200" }

);

const [addDentist, { isLoading, isError,error:dataError, isSuccess}] = useAddDentistMutation();
const [show, setShow] = React.useState(false);




const [selectedOption, setSelectedOption] = useState('male');
const [selectedOptionSpecialisation, setSelectedOptionSpecialisation] = useState('1');

console.log(selectedOptionSpecialisation, 'expiry')

const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSelectChangeSpecialisation = (event) => {
    setSelectedOptionSpecialisation(event.target.value);
  };

const handleDateChange = (date) => {
  setSelectedDate(date)
};

const handleExpiryDate = (date)=>{
  setExpiry(date)
}

const handleStateChange = (event) => {

  setState(event.target.value)
}

  // const handleClick = () => setShow(!show);
  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (address === "") {
      return setError(`Field must not be empty.`);
    }
    if (email === "") {
      return setError(`Field must not be empty.`);
    }
    if (phone === "") {
      return setError(`Field must not be empty.`);
    }
    if(fullName === "") {
      return setError(`Field must not be empty.`);
    }
    if(selectedOption === "") {
      return setError(`Field must not be empty.`);
    }
    // if(last_name === "") {
    //   return setError("You must enter your last name.")}
    // if(confirmPassword === "") {
    //   return setError("You must confirm your password");}
    //   if(password !== confirmPassword) {
    //     return setError("Your password not match")}
    try {
      setButtonText(isLoading?'loading....':'Add Dentist');

      const datatoBackend ={
        name:fullName,
        contact:address,
        phone,
        specialisation:Number(selectedOptionSpecialisation),
        dateOfBirth: convertToFormattedDate(selectedDate),
        email,
        license_number:license,
        license_state:state,
        licence_expiry_date:convertToFormattedDate(expiry),
        createdBy: user.id,
        gender:selectedOption
      }

      console.log(datatoBackend)

      setButtonText(isLoading?'loading....': 'Add Dentist')
      addDentist(datatoBackend)
      .unwrap() // Unwrap the result to access the data directly
      .then((userData) => {
        console.log(userData, 'userData')
        setButtonText('Dentist added successfully');

        // Handle successful login
        if(!userData){
          setButtonText(error?error:'Add Dentist');
        
        }
       
      })

    
    } catch (err) {
      console.log(err);
      console.log(err.response)
      if (err.response) {
        return setError(err.response.data.msg);
      }
      return setError("There has been an error.");
    }
  };


  console.log(selectedOptionSpecialisation)
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <FormControl>
        <Grid
        mb='20px'
        
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
        >
          <div>
            <Text color={'red.400'}>{error?"something went wrong": null}</Text>
<FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                FullName<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={'Adewole Babs'}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setName(event.target.value)
                  setError(undefined);
                }}
              />
</div>
<div>
<FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder={'email'}
              mb='24px'
            //   defaultValue={email}
              fontWeight='500'
              size='lg'
              onChange={(event) => {
                setEmail(event.target.value);
                setError(undefined);
              }}
            />
</div>
         <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Address<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={'address'}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setAddress(event.target.value);
                  setError(undefined);
                }}
              />
            </div>



<FormLabel
               
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Date of Birth<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
                {/* <FileUploadComponent uploadedImageUrl={uploadedImageUrl} setUploadedImageUrl={setUploadedImageUrl}/> */}
                <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      dateFormat="yyyy-MM-dd"
    />
              </FormLabel> 
           
      <div>
      <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Phone<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <InputGroup size='md'>
                 <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={'phone no'}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setPhone(event.target.value);
                  setError(undefined);
                }}
              />
              
              </InputGroup>
              </div>
    
               <div>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Gender<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Select value={selectedOption} onChange={handleSelectChange}  mb='24px'>
        <option value="male">male</option>
        <option value="female">female</option>
      </Select>
      </div>

      <div>
         <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Specialisation<Text color={brandStars}>*</Text>
              </FormLabel>
         <Select value={selectedOptionSpecialisation} onChange={handleSelectChangeSpecialisation}  mb='24px'>
                {
                  specialisation && specialisation.map(role =>(
                    <option value={role.specialisationID} key={role.specialisationID}>{role.name}</option>
                  ))
                }
       
       
      </Select></div>
      <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                License Expiry Date<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
                {/* <FileUploadComponent uploadedImageUrl={uploadedImageUrl} setUploadedImageUrl={setUploadedImageUrl}/> */}
       <DatePicker
      selected={expiry}
      onChange={handleExpiryDate}
      dateFormat="yyyy-MM-dd"
    />
      </FormLabel> 
              <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                License Number<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={'license number'}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setLicenseNumber(event.target.value);
                  setError(undefined);
                }}
              />
            </div>  

            <div>
         <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                License State<Text color={brandStars}>*</Text>
              </FormLabel>
         <Select value={state} onChange={handleStateChange}  mb='24px'>
                {
                  myState && myState.map((role, index) =>(
                    <option value={role} key={index}>{role}</option>
                  ))
                }
       
       
      </Select></div>  

</Grid>
<Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                onClick={register}
              >
                {buttonText}
              </Button>
</FormControl>
        
        </Box>
     
    </Card>
  )
}

export default DoctorForm

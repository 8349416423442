import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdGamepad,
  MdSpatialAudioOff,
  MdPropaneTank,
} from "react-icons/md";

// Admin Imports

import Profile from "views/admin/profile";
import Patient from "views/admin/Patients/patient";
import Doctor from "views/admin/Doctors/doctor";
import Prescription from "views/admin/Prescriptions/precsription";
import Diagnosis from "views/admin/Diagnosis/diagnosis";

// Auth Imports
// import SignInCentered from "views/auth/signIn";


const routes = [
    {
        name: "Profile",
        layout: "/doctor",
        path: "/default",
        icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
        component: Profile,
      },

    {
        name: "Doctors",
        layout: "/doctor",
        path: "/doctors",
        icon: <Icon as={MdGamepad} width='20px' height='20px' color='inherit' />,
        component: Doctor,
      },
      {
        name: "Beneficials",
        layout: "/doctor",
        path: "/beneficials",
        icon: <Icon as={MdSpatialAudioOff} width='20px' height='20px' color='inherit' />,
        component: Patient,
      },

      {
        name: "Diagnosis",
        layout: "/doctor",
        path: "/diagnosis",
        icon: <Icon as={MdGamepad} width='20px' height='20px' color='inherit' />,
        component: Diagnosis,
      },

      {
        name: "Prescription",
        layout: "/doctor",
        path: "/prescriptions",
        icon: <Icon as={MdPropaneTank} width='20px' height='20px' color='inherit' />,
        component: Prescription,
      },
    
  
];


export default routes;
import React,{useCallback, useState} from 'react'
import Card from "components/card/Card.js"
import FileUploadComponent from "../../../auth/signUp/fileupload";
import {   Box,
    Grid,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    UnorderedList, 
     ListItem,
    Input,
    Textarea,
    Select,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue, } from "@chakra-ui/react";
import { useAuth } from "auth-context/auth.context";


import {useGetAllCategoriesQuery} from 'api/categories';
import { useAddInventoryMutation} from 'api/inventories';





function Form({onBoxClose}) {

  const [ addInventory, {isLoading, errors }] = useAddInventoryMutation();
const {data:categories} = useGetAllCategoriesQuery();
const errorText = useColorModeValue("red.500", "red.400");
const { user:{user} } = useAuth();
const [drugName, setDrugName] = useState('');
const [description, setDescription] = useState('');
const [unit_price, setUnitPrice] = useState('');
const [quantity, setQuantity] = useState('');
const [error, setError] = useState(undefined);
const [buttonText, setButtonText] = useState("Add Inventory");
const [uploadedImageUrl, setUploadedImageUrl] = useState('')
// console.log(doctors, 'doctors')

const textColor = useColorModeValue("navy.700", "white");

const brandStars = useColorModeValue("brand.500", "brand.400");

// const  handleSearch =(e)=>{
//   setSearch(e)
// }


// const handleSearchDiagnostic =(e)=>{
//   setSearchDiagnostic(e)
// }













const [selectedOptionBool, setSelectedOptionBool] = useState(true);

const handleSelectChangeAvailable = (event) => {
  setSelectedOptionBool(event.target.value);
};

console.log(drugName, 'cat')

const [selectedOption, setSelectedOption] = useState(Number(2));

const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
//   const handleClick = () => setShow(!show);
const handleSubmit = async (event) => {
  if (event) {
    event.preventDefault();
  }

  if (drugName=== "") {
    return setError(`Field must not be empty.`);
  }

  if (description === "") {
    return setError(`Field must not be empty.`);
  }
  if (quantity === "") {
    return setError(`Field must not be empty.`);
  }
  if (unit_price === "") {
    return setError(`Field must not be empty.`);
  }

  if (selectedOptionBool === "") {
    return setError(`Field must not be empty.`);
  }

  if (selectedOption=== "") {
    return setError(`Field must not be empty.`);
  }


    try {
      setButtonText(isLoading?'Loading...':'Add Inventory');
      const regexPattern = /\d+/g;
      const toPrice = unit_price.match(regexPattern)
      const toQuantity = quantity.match(regexPattern)
      console.log(toPrice)
      const datatoBackend ={
        name:drugName,
        description:description,
        created_by: user?.id,
        quantity: Number(toQuantity),
        unit_price:Number(toPrice),
        is_available:Boolean(selectedOptionBool),
        category: Number(selectedOption)

       
        // profileImage:uploadedImageUrl,
      }

    console.log(datatoBackend, 'test')

    addInventory(datatoBackend)
    .unwrap() // Unwrap the result to access the data directly
    .then((userData) => {
      console.log(userData, 'userData')
      // Handle successful login
      setButtonText('Inventory added successfully');
      onBoxClose(false)
      if(!userData){
        setButtonText(error?error:'Add Prescription');
      // return setError(data.detail);
      }
     
    })
  
  } catch (err) {
    console.log(err);
    setButtonText("Edit");
    if (err.response) {
      // return setError(err.response.data.msg);
      console.log(err.responsedata)
      return setError('something went wrong');
    }
    return setError("There has been an error.");
  }
};
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>Error: {errors.message}</div>;
  }

 
 
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <FormControl>
        <Grid
        mb='20px'
        
        templateColumns={{
          base: "1fr",
          lg: "repeat(1, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
        >
          <div>
<FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Drug Name <Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={''}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setDrugName(event.target.value)
                  setError(undefined);
                }}
              />
</div>
<div>
<FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Description <Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='weight'
              placeholder={''}
              mb='24px'
            //   defaultValue={weight}
              fontWeight='500'
              size='lg'
              onChange={(event) => {
                setDescription(event.target.value);
                setError(undefined);
              }}
            />
</div>
<div>
<FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Unit Price <Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
        
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='weight'
              placeholder={''}
              mb='24px'
            //   defaultValue={weight}
              fontWeight='500'
              size='lg'
              onChange={(event) => {
                setUnitPrice(event.target.value);
                setError(undefined);
              }}
            />
</div>
<div>
<FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Quantity<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='weight'
              placeholder={''}
              mb='24px'
            //   defaultValue={weight}
              fontWeight='500'
              size='lg'
              onChange={(event) => {
                setQuantity(event.target.value);
                setError(undefined);
              }}
            />
</div>


<div>
         <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Category <Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
         
              </FormLabel>
         <Select value={selectedOption} onChange={handleSelectChange}  mb='24px'>
                {
                  categories && categories.map((cat, index) =>(
                    <option value={cat.categoryID} key={cat.categoryID}>{cat.name}</option>
                  ))
                }
       
       
      </Select></div>

      <div>
         <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Available  <Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
         <Select value={selectedOptionBool} onChange={handleSelectChangeAvailable}  mb='24px'>
               <option value='true' > Yes</option>
               <option value='false' > No</option>
       
       
      </Select></div>
         
</Grid>
<Grid
        mb='20px'
        
        templateColumns={{
          base: "1fr",
          lg: "repeat(1, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
        >

           
         
         
          
         
{/*         
            <div>
            <Button colorScheme="blue" ml={3} onClick={()=> onOpenPharmacy(true)}>{pharmName?pharmName:'Select Pharmacy'}</Button>
            </div>  */}

          </Grid>

<Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                onClick={handleSubmit}
              >
                {buttonText}
              </Button>
</FormControl>
        
        </Box>
     
    </Card>
  )
}

export default Form

// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import { formatDate } from "components/DateFormater/dateFormater";
import {useGetRoleQuery} from "api/role"

export default function Banner(props) {
  const { banner, posts, followers, following,data } = props;
  const {first_name, last_name, imageUrl, email, is_active, role, last_login, date_joined, city, state} = data;
  const {data:userRole} = useGetRoleQuery(role)


  console.log(city)
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={imageUrl}
        h='87px'
        w='87px'
        mt='-43px'
        border='4px solid'
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {first_name} {last_name}
      </Text>
      <Text color={textColorSecondary} fontSize='sm'>
        {email}
      </Text>
      <Flex w='max-content' mx='auto' mt='26px'>
        <Flex mx='auto' me='60px' align={'center'} justifyContent="space-around" direction='column'>
          <Text color={textColorPrimary} fontSize='14px' fontWeight='700'>
            {userRole?userRole.name:null}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Role
          </Text>
        </Flex>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='14px' fontWeight='700'>
            {formatDate(date_joined, 'yyyy-MM-dd')}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
           Date Joined
          </Text>
        </Flex>
      
      </Flex>
      <Flex w='max-content' mx='auto' mt='26px'>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='14px' fontWeight='700'>
            {is_active? 'active' : 'disabled'}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            active
          </Text>
        </Flex>
        {/* <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='14px' fontWeight='700'>
            {last_login?formatDate(last_login, 'yyyy-MM-dd'): 'N/A'}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
           last_login
          </Text>
        </Flex> */}

        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='14px' fontWeight='700'>
            {city}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
           city
          </Text>
        </Flex>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='14px' fontWeight='700'>
            {state}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
           state
          </Text>
        </Flex>
      
      </Flex>
    </Card>
  );
}

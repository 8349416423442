import {
    Flex,
    Table,
    Progress,
    Icon,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Avatar,
    SimpleGrid,
  } from "@chakra-ui/react";
  import DiagnosisModal from './diagnosisModal'
  import { MdVisibility, MdDelete  } from 'react-icons/md'
  import React, { useMemo, useState } from "react";
  import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
  } from "react-table";
  import Profile from "assets/img/avatars/avatar6.png"
  import IconBox from "components/icons/IconBox";
  import Pagination from "components/pagination/pagination";
  import {useGetAllPatientsQuery} from "api/patient"

  
  // Custom components
  import Card from "components/card/Card";
  import Menu from "components/menu/MainMenu";
  
  // Assets
  import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";

  export default function DoctorTable(props) {
    const { columnsData, patientData, isOpen, onOpen, onClose, isDelete, setDelete} = props;
    const [currentPage, setCurrentPage] = useState(1);
    const{data:patients} = useGetAllPatientsQuery()
    const [userID, setUserId] = useState();

    const brandColor = useColorModeValue("brand.500", "white");
    // let mainText = useColorModeValue("navy.700", "white");
    // const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => patientData, [patientData]);
 
  
    const tableInstance = useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
    } = tableInstance;
    initialState.pageSize = data?.length;

    let pageSize = 5
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;


 
    const nPage =Math.ceil(initialState?.pageSize / pageSize);
  
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
      );
      const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.100" }
      );

      const handlePageChange = (page) => {
        setCurrentPage(page);
        // You can also fetch data for the new page here
      };

     
    
    return (
      <Card
        direction='column'
        w='100%'
        px='0px'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
     
          <Text
            color={textColor}
            fontSize='14px'
            fontWeight='700'
            lineHeight='100%'>
            Diagnosis
          </Text>
        </Flex>
        <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.slice(firstIndex, lastIndex).map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                onClick={()=>{onOpen(); setUserId(row.original.diagnosesID)}}
                >
                  
                
                  {row.cells.map((cell, index) => {
                    let data = "";
               
                    if (cell.column.Header === "ID") {
                      data = (
                        <Flex align='center'>
  
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {row.original.diagnosesID}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "NAME") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                         {patients && patients.map(patient =>(
                          patient.patientId == row.original.name?`${patient?.name}`:null
                         ))}
                        </Text>
                      );
                    } else if (cell.column.Header === "TYPE") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {row.original.diagnosis_type}
                       </Text>
                      );
                    }else if(cell.column.Header === "SYMPTOMS") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {row.original.symptoms}
                       </Text>
                      );
                    }else if(cell.column.Header === "CAUSES") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {row.original.causes}
                         </Text>
                        );
                      }else if(cell.column.Header === "SEVERITY") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {row.original.severity}
                         </Text>
                        );
                      }else if(cell.column.Header === "LOCATION") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {row.original.location}
                         </Text>
                        );
                      }else if(cell.column.Header === "VIEW") {
                        data = (
                          <IconBox
                w='56px'
                h='56px'
                _hover={{ cursor: 'pointer',}}
                onClick={()=>{onOpen(); setUserId(row.original.diagnosesID)}}
                icon={
                  <Icon w='32px' h='32px' as={MdVisibility} color={brandColor} />
                }
              />
                    
                        )
                      }else if(cell.column.Header === "DELETE") {
                        data = (
                          // <Text color={textColor} fontSize='sm' fontWeight='700'>
                          // handleViewChange(row.original.id)
                          <IconBox
                w='56px'
                h='56px'
                _hover={{ cursor: 'pointer',}}
                onClick={()=>{onOpen(); setUserId(row.original.diagnosesID); setDelete(true)}}
                icon={
                  <Icon w='32px' h='32px' as={MdDelete} color={'red'} />
                }
              />
                    
                        )
                      }
                    
                    
                    
                    
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        maxH='30px !important'
                        py='8px'
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
         
        </Table>
        <Pagination currentPage={currentPage} totalPages={nPage} onPageChange={handlePageChange} />
        {
          isOpen? 
          <DiagnosisModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} id={userID} isDelete={isDelete} setDelete={setDelete}/>
          :
          
          null
        }
      
      </Card>
    );
  }
  
export function convertToFormattedDate(inputDate) {
    const dateObject = new Date(inputDate);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(dateObject.getDate()).padStart(2, '0');
  
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  
//   const inputDate = 'Tue Aug 22 2023 11:01:13 GMT+0100 (West Africa Standard Time)';
//   const formattedDate = convertToFormattedDate(inputDate);
//   console.log(formattedDate); // Output: "2023-08-22"
  
import React from 'react';
import { Button, Box } from '@chakra-ui/react';

function DeleteModal({user, message, handleDelete, onClose}) {
  return (
    <div>
        {message?message: `You are about to delete this user - ${user?.username}?`}

                         
                 <Box style={{flex:'row', justifyContent:'center', alignItems:'center', marginTop:'20px',}}>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
              <Button colorScheme="red" mr={3} onClick={()=> handleDelete()}>Delete</Button>
              </Box>
                
    </div>
  )
}

export default DeleteModal
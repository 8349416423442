import React from 'react'
import { Button } from '@chakra-ui/react';

function DeleteModal({message, handleDelete, setDelete}) {
  return (
    <div>
          {
        message?message: `You are about to delete this document?`
      }

      <div style={{justifyContent: 'center', margin:'10px'}}>
      
      <Button colorScheme="blue" mr={3} onClick={()=> setDelete(false)}>
                  Close
                </Button>
                <Button colorScheme="red" mr={3} onClick={()=> handleDelete()}>Confirm Delete</Button>
      </div>


    </div>
  )
}

export default DeleteModal
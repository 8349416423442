import React from 'react'

function DeleteModal({nurse, message}) {
  return (
    <div>
          {
        message?message: `You are about to delete this patient - ${nurse?.name}?`
      }
    </div>
  )
}

export default DeleteModal
// api/users.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";




export const dentistsApi = createApi({
  reducerPath:'dentists',
  tagTypes: ['dentists'],
  baseQuery: fetchBaseQuery({baseUrl: `${API_SERVER}/api`,
  prepareHeaders: (headers, { getState }) => {
    const {authSlice:{token}} = getState();
 
    let useToken = localStorage.getItem('user');
    const mytoken =JSON.parse(useToken).token
    if (token || mytoken) {
      headers.set('Authorization', `Token ${mytoken?mytoken:token}`);
    }
    return headers;
  },

}), // Replace with your API base URL
endpoints: (builder) => ({
    getDentist: builder.query({
      query: (id) => `/dentists/dentists/${id}/`, 
      headers:{'Content-Type': 'application/json'},
    }),
    // providesTags:['patients'],
    getAllDentists: builder.query({
      query: () =>({
        url:`/dentists/dentists/`,
        headers:{'Content-Type': 'application/json'},
      }),
      transformErrorResponse:res=>res.sort((a,b)=> b.id - a.id),
      invalidatesTags: ['dentists'],
    }),
    addDentist: builder.mutation({
      query:(patient)=>({
        url:'/dentists/dentists/',
        method: 'POST',
        body:patient,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['dentists'],
    }),
    updateDentist:builder.mutation({
      query:(obj)=>({
        url:`/dentists/dentists/${obj.dentistID}/`,
        method: 'PATCH',
        body:obj,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['dentists'],
    }),
    deleteDentist:builder.mutation({ 
      query:(obj)=>({
        url: `/dentists/dentists/${obj.id}`,
        method: 'DELETE',
        body:obj,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['dentists'],

    }),
  })

});

export const { useGetDentistQuery, useGetAllDentistsQuery, useAddDentistMutation,
   useUpdateDentistMutation, 
   useDeleteDentistMutation} = dentistsApi;
import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdSupervisedUserCircle,
  MdSpatialAudioOff,
  MdHome,
  MdLock,
  MdLogout,
  MdBatterySaver,
  MdGamepad,
  MdPropaneTank,

} from "react-icons/md";

// Admin Imports

import Profile from "views/admin/profile";
import Patient from "views/admin/Patients/patient";
import Doctor from "views/admin/Doctors/doctor";
import Prescription from "views/admin/Prescriptions/precsription";
import Inventories from "views/admin/Inventories/inventories";

// Auth Imports
// import SignInCentered from "views/auth/signIn";


const routes = [
    {
        name: "Profile",
        layout: "/pharm",
        path: "/profile",
        icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
        component: Profile,
      },

    // {
    //     name: "Doctors",
    //     layout: "/pharm",
    //     path: "/doctors",
    //     icon: <Icon as={MdGamepad} width='20px' height='20px' color='inherit' />,
    //     component: Doctor,
    //   },
      {
        name: "Beneficials",
        layout: "/pharm",
        path: "/beneficials",
        icon: <Icon as={MdSpatialAudioOff} width='20px' height='20px' color='inherit' />,
        component: Patient,
      },

      {
        name: "Prescription",
        layout: "/pharm",
        path: "/prescriptions",
        icon: <Icon as={MdPropaneTank} width='20px' height='20px' color='inherit' />,
        component: Prescription,
      },
      // {
      //   name: "Inventories",
      //   layout: "/pharm",
      //   path: "/inventories",
      //   icon: <Icon as={MdPropaneTank} width='20px' height='20px' color='inherit' />,
      //   component: Inventories,
      // },
  
];


export default routes;
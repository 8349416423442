// api/users.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";
import { useAuth } from 'auth-context/auth.context';






export const usersApi = createApi({
  reducerPath: 'users',
  tagTypes: ['users'],
  baseQuery: fetchBaseQuery({baseUrl: `${API_SERVER}/api`,
  prepareHeaders: (headers, { getState }) => {
    const {authSlice:{token}} = getState();
 
    console.log(token , 'state token');
    let useToken = localStorage.getItem('user');
    const mytoken =JSON.parse(useToken).token
    if (token || mytoken) {
      headers.set('Authorization', `Token ${mytoken?mytoken:token}`);
    }
    return headers;
  },

}), // Replace with your API base URL
endpoints: (builder) => ({
    getUser: builder.query({
      query: (userId) => `/users/users/${userId}/`, // Replace with your backend endpoint
      headers:{'Content-Type': 'application/json'},
    }),
    invalidatesTags: ['users'],
    getAllUsers: builder.query({
      query: () =>({
        url:`/users/users/`,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['users'],
    }),
    addUser:builder.mutation({
      query:(user)=>({
        url:'/users/users/',
        method: 'POST',
        body:user,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['users'],
    }),
    updateUser:builder.mutation({
      query:(user)=>({
        url:`/users/users/${user.id}/`,
        method: 'PATCH',
        body:user,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['users'],
    }),
    deleteUser:builder.mutation({ 
      query:(obj)=>({
        url: `/users/users/${obj.id}/`,
        method: 'DELETE',
        body:obj,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidatesTags: ['users'],

    }),
  })

});

export const { useGetUserQuery, useGetAllUsersQuery, useAddUserMutation,
   useUpdateUserMutation, 
   useDeleteUserMutation} = usersApi;
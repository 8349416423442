// api/users.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {API_SERVER} from "config/constant";







export const vitalsignsApi = createApi({
  reducerPath:'vitalsigns',
  tagTypes: ['vitalsigns'],
  baseQuery: fetchBaseQuery({baseUrl: `${API_SERVER}/api`,
  prepareHeaders: (headers, { getState }) => {
    const {authSlice:{token}} = getState();
 
    console.log(token , 'state token');
    let useToken = localStorage.getItem('user');
    const mytoken =JSON.parse(useToken).token
    if (token || mytoken) {
      headers.set('Authorization', `Token ${mytoken?mytoken:token}`);
    }
    return headers;
  },

}), // Replace with your API base URL
endpoints: (builder) => ({
    getVitalsign: builder.query({
      query: (id) => `/vitalsigns/vitalsigns/${id}/`, // Replace with your backend endpoint
      headers:{'Content-Type': 'application/json'},
    }),
    // providesTags:['patients'],
    getAllVitalsigns: builder.query({
      query: () =>({
        url:`/vitalsigns/vitalsigns/`,
        headers:{'Content-Type': 'application/json'},
      }),
      transformErrorResponse:res=>res.sort((a,b)=> b.id - a.id),
      invalidates: ['vitalsigns'],
    }),
    addVitalsign:builder.mutation({
      query:(pharm)=>({
        url:'/vitalsigns/vitalsigns/',
        method: 'POST',
        body:pharm,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidates: ['vitalsigns'],
    }),
    updateVitalsign:builder.mutation({
      query:(pharm)=>({
        url:`/vitalsigns/vitalsigns/${pharm.vitalSignId}/`,
        method: 'PATCH',
        body:pharm,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidates: ['vitalsigns'],
    }),
    deleteVitalsign:builder.mutation({ 
      query:(obj)=>({
        url: `/vitalsigns/vitalsigns/${obj.id}`,
        method: 'DELETE',
        body:obj,
        headers:{'Content-Type': 'application/json'},
      }),
      invalidates: ['vitalsigns'],

    }),
  })

});

export const { useGetVitalsignQuery, useGetAllVitalsignsQuery, useAddVitalsignMutation,
   useUpdateVitalsignMutation, useDeleteVitalsignMutation} = vitalsignsApi;
import React,{useState} from 'react'
import Card from "components/card/Card.js"
import FileUploadComponent from "../../../auth/signUp/fileupload";
import {   Box,
    Grid,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    Select,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue, } from "@chakra-ui/react";
    import { useAuth } from "auth-context/auth.context";
    import { MdOutlineRemoveRedEye } from "react-icons/md";
    import { useUpdateInventoryMutation, useGetInventoryQuery } from 'api/inventories';
    import {useGetAllCategoriesQuery, } from 'api/categories'
 



function EditForm({id}) {

  const [ updateInventory] =  useUpdateInventoryMutation();
  const {data:categories} = useGetAllCategoriesQuery();
  const { data, isLoading, errors } = useGetInventoryQuery(id);
  const errorText = useColorModeValue("red.500", "red.400");
  const { user:{user} } = useAuth();
  const [drugName, setDrugName] = useState(data?.name);
  const [description, setDescription] = useState(data?.description);
  const [unit_price, setUnitPrice] = useState(data?.unit_price);
  const [quantity, setQuantity] = useState(data?.quantity);
const [error, setError] = useState(undefined);
const [buttonText, setButtonText] = useState("Update Inventory");
const [uploadedImageUrl, setUploadedImageUrl] = useState('')
// console.log(doctors, 'doctors')

const textColor = useColorModeValue("navy.700", "white");

const brandStars = useColorModeValue("brand.500", "brand.400");








console.log(quantity)


const [selectedOptionBool, setSelectedOptionBool] = useState(true);

const handleSelectChangeAvailable = (event) => {
  setSelectedOptionBool(event.target.value);
};

console.log(drugName, 'cat')

const [selectedOption, setSelectedOption] = useState(Number(2));


const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };




  const handleSubmission = async (event) => {
    if (event) {
      event.preventDefault();
    }

    if (drugName === "") {
      return setError("You must enter your drug name");
    }

    // if(diagnosticId === "") {
    //   return setError("You must enter the diagnostic ID");
    // }
    // if(doctorId === "") {
    //   return setError("You must enter a doctor name");
    // }
    // if(patientId=== "") {
    //   return setError("You must enter a the patient name");
    // }
    const regexPattern = /\d+/g;
    const toPrice = unit_price && unit_price.match(regexPattern);
    const toQuantity = quantity && quantity.match(regexPattern);
    
    try {
      setButtonText(isLoading?'Loading...':'Update Prescription');
      const datatoBackend ={
        inventoriesId:data?.inventoriesId,
        name:drugName?drugName:data?.name,
        description:description?description:data?.description,
        created_by: Number(user.id),
        quantity: Number(quantity)? Number(toQuantity):data?.quantity,
        unit_price:unit_price?Number(toPrice):data?.unit_price,
        is_available:selectedOptionBool?Boolean(selectedOptionBool):data?.is_available,
        category:selectedOption?Number(selectedOption):data?.category,

        // profileImage:uploadedImageUrl,
      }

      console.log(datatoBackend, 'test')

      updateInventory(datatoBackend)
      .unwrap() // Unwrap the result to access the data directly
      .then((userData) => {
        console.log(userData, 'userData')
        // Handle successful login
        setButtonText('Prescription updataed successfully');
        if(!userData){
          setButtonText(error?error:'Update Inventory');
        // return setError(data.detail);
        }
       
      })
    
    } catch (err) {
      console.log(err);
      setButtonText("Edit");
      if (err.response) {
        // return setError(err.response.data.msg);
        console.log(err.responsedata)
        return setError('something went wrong');
      }
      return setError("There has been an error.");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>Error: {errors.message}</div>;
  }

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <FormControl>
    <Grid
    mb='20px'
    
    templateColumns={{
      base: "1fr",
      lg: "repeat(1, 1fr)",
      "2xl": "1.34fr 1.62fr 1fr",
    }}
    gap={{ base: "20px", xl: "20px" }}
    >
      <div>
<FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Drug Name <Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            placeholder={data?.name}
            mb='24px'
            fontWeight='500'
            size='lg'
            onChange={(event) => {
              setDrugName(event.target.value)
              setError(undefined);
            }}
          />
</div>
<div>
<FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          mb='8px'>
          Description <Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
        </FormLabel>
        <Input
          isRequired={true}
          variant='auth'
          fontSize='sm'
          ms={{ base: "0px", md: "0px" }}
          type='weight'
          placeholder={data?.description}
          mb='24px'
        //   defaultValue={weight}
          fontWeight='500'
          size='lg'
          onChange={(event) => {
            setDescription(event.target.value);
            setError(undefined);
          }}
        />
</div>
<div>
<FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          mb='8px'>
          Unit Price <Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
          </FormLabel>
    
        <Input
          isRequired={true}
          variant='auth'
          fontSize='sm'
          ms={{ base: "0px", md: "0px" }}
          type='weight'
          placeholder={data?.unit_price}
          mb='24px'
        //   defaultValue={weight}
          fontWeight='500'
          size='lg'
          onChange={(event) => {
            setUnitPrice(event.target.value);
            setError(undefined);
          }}
        />
</div>
<div>
<FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          mb='8px'>
          Quantity<Text color={brandStars}>*</Text>
        </FormLabel>
        <Input
          isRequired={true}
          variant='auth'
          fontSize='sm'
          ms={{ base: "0px", md: "0px" }}
          type='weight'
          placeholder={data?.quantity}
          mb='24px'
        //   defaultValue={weight}
          fontWeight='500'
          size='lg'
          onChange={(event) => {
            setQuantity(event.target.value);
            setError(undefined);
          }}
        />
</div>


<div>
     <FormLabel
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            display='flex'>
            Category <Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
     
          </FormLabel>
     <Select value={selectedOption} onChange={handleSelectChange}  mb='24px'>
            {
              categories && categories.map((cat, index) =>(
                <option value={cat.categoryID} key={cat.categoryID}>{cat.name}</option>
              ))
            }
   
   
  </Select></div>

  <div>
     <FormLabel
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            display='flex'>
            Available  <Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
          </FormLabel>
     <Select value={selectedOptionBool} onChange={handleSelectChangeAvailable}  mb='24px'>
           <option value='true' > Yes</option>
           <option value='false' > No</option>
   
   
  </Select></div>
     
</Grid>
<Grid
    mb='20px'
    
    templateColumns={{
      base: "1fr",
      lg: "repeat(1, 1fr)",
      "2xl": "1.34fr 1.62fr 1fr",
    }}
    gap={{ base: "20px", xl: "20px" }}
    >

       
     
     
      
     
{/*         
        <div>
        <Button colorScheme="blue" ml={3} onClick={()=> onOpenPharmacy(true)}>{pharmName?pharmName:'Select Pharmacy'}</Button>
        </div>  */}

      </Grid>

<Button
            fontSize='sm'
            variant='brand'
            fontWeight='500'
            w='100%'
            h='50'
            mb='24px'
            onClick={handleSubmission}
          >
            {buttonText}
          </Button>
</FormControl>
    
    </Box>
 
</Card>
  )
}

export default EditForm

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../auth-context/auth.context";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

export const ProtectedAdminRoute = ({children, ...rest }) => {
  const history = useHistory();
  let { user } = useAuth();


  if (!user && !user?.user?.is_superuser) {
    return (
      <SweetAlert
      style={{backgroundColor:'rgba(66, 153, 225, 0.6)'}}
        confirmBtnText="Sign In"
        confirmBtnStyle={{padding:'5px 15px 5px 15px'}}
        title="You must be Admin to access this"
        onCancel={() => history.push("/auth/sign-in")}
        onConfirm={() => history.push("/auth/sign-in")}
        confirmBtnCssClass={"px-5"}
      />
    );
  }

  return <Route
  {...rest}
  render={({ location }) =>
    user?.token && user?.user?.is_superuser? (
      children
    ) : (
      <Redirect
        to={{
          pathname: '/auth', // Redirect to the login page or any other page
          state: { from: location },
        }}
      />
    )
  }
/>
};

import React, { useState } from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { useGetUserQuery, useDeleteUserMutation } from "api/user";
import Banner from "views/admin/profile/components/Banner";
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import EditUser from "./components/EditUser"
import DeleteModal from './components/DeleteModal';
import { useAuth } from "auth-context/auth.context";

const MyModal = ({isOpen, onOpen, onClose, id, isDelete, setDelete, isModified, setModified}) => {
  const { user:{user} } = useAuth();
    const { data, isLoading } = useGetUserQuery(id);
    const [buttonText, setButtonText] = useState("Delete");
    const [error, setError] = useState(undefined);
    const[deleteUser, {isSuccess}] = useDeleteUserMutation()
    const [edit, setEdit] = useState(false)
    // const [isDelete, setDelete] = useState(false)
    const [msg, setMsg] = useState('')


    const handleShow = () => {
      setDelete(true)
      setEdit(false)

    }

    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    if (!data) {
      return <div>User not found.</div>;
    }


    const handleDelete = () => {
      const tosend={
        id:id,
        user:user?.id
      }
    try{
          deleteUser(tosend)
          .unwrap() // Unwrap the result to access the data directly
          .then((userData) => {
            console.log(userData, 'userData')
            const{message, error} = userData;
            setMsg(message?message:error)
            setButtonText('User Deleted');
            setModified(true)
            if(!userData){
              setButtonText(error?error:'Delete');
            // return setError(data.detail);
            }
           
          })
    
      //  onClose()
        
        } catch (err) {
          console.log(err);
          setButtonText("Edit");
          if (err.response) {
            // return setError(err.response.data.msg);
            console.log(err.responsedata)
            return setError('something went wrong');
          }
          return setError("There has been an error.");
        }
      
    }


    console.log(user.role, 'idss')
  
    return (
      <>
        {/* <Button onClick={onOpen}>Open Modal</Button> */}
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>User</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {
                edit? 
                
                <EditUser id={id} onClose={onClose}/>
                :isDelete?
              
              <DeleteModal user={data}  message={msg} handleDelete={handleDelete} onClose={onClose}/>
              :
              <Banner
              gridArea='1 / 1 / 2 / 2'
              banner={banner}
              avatar={avatar}
              data={data}
              name='Adela Parkson'
              job='Product Designer'
              posts='17'
              followers='9.7k'
              following='274'
            /> 
              }
        
            </ModalBody>
             
                
                <ModalFooter>
               
                {
                  edit || !isDelete ?
                  // user?.role == 5 ||user?.is_superuser ?
                  <>
                   <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Close
                </Button>
                
                      <Button colorScheme="green" mr={3} onClick={()=>setEdit(true)}>Edit</Button>

               
                  
{/* <Button colorScheme="red" mr={3} onClick={()=> handleShow()}>Delete</Button> */}
                  </>
              
                //   :
                //   <>
                //       <Button colorScheme="blue" mr={3} onClick={onClose}>
                //   Close
                // </Button>
                //   <Button colorScheme="red" mr={3} onClick={()=> handleDelete()}>Delete</Button>
                //   </>
                  : null
                }
            
               
               
              </ModalFooter>
              
           
          
          </ModalContent>
        </Modal>
      </>
    );
  };
  
  export default MyModal;
  
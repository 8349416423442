import React,{useState} from 'react'
import {
    Avatar,
    Box,
    Flex,
    FormLabel,
    Icon,
    Select,
    Text,
    SimpleGrid,
    useColorModeValue,
  } from "@chakra-ui/react"
 
  import {
    columnsDataCheck,
    columnsDataComplex,
  } from "./components/column";

  import VitalSignTable from "./components/vitalSignTable"
  import {useGetAllVitalsignsQuery } from "api/vitalsign";
  import IconBox from "components/icons/IconBox";
  import AddVitalSign from "./components/AddVitalsign";
  import {
    MdAddTask,
    // MdAttachMoney,
    // MdBarChart,
    // MdFileCopy,
  } from "react-icons/md";

function VitalSign() {
    const [isOpen, setIsOpen] = useState(false);
    const [isDelete, setDelete] = useState(false)
    const [isAddPatientOpen, setIsAddPatientOpen] = useState(false);
    const brandColor = useColorModeValue("brand.500", "white");
    let mainText = useColorModeValue("navy.700", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const [userID, setUserId] = useState()
    const onClose = () =>{setIsOpen(false); setDelete(false);};
    const onOpen = () => setIsOpen(true);
    const onOpenAddPatient = () => setIsAddPatientOpen(true);
    const onCloseAddPatient = () => setIsAddPatientOpen(false);

    const {data:vitalsigns, error, isLoading} = useGetAllVitalsignsQuery();
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
      );
      const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.100" }
      );

    console.log(vitalsigns, 'allusers')
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    if (!vitalsigns) {
      return <div>User not found.</div>;
    }

  const tableData = (vitalsigns)=>{
  
    let result=[];

    vitalsigns && vitalsigns.map(sign =>{
      const{vitalSignId,  bloodPressure, weight, painLevel, blood_glucose_level, urine_level, capillary_refill_time, createdby, pupil_repsonse,
    pulse_oximetry, pain_assessment, pulseRate,height,temperature,comment_notes, specialisation, patient
    } = sign;
      result.push({ bloodPressure, vitalSignId, weight, blood_glucose_level, painLevel, urine_level, createdby, capillary_refill_time, pupil_repsonse,
    pulse_oximetry, pain_assessment, pulseRate,height,temperature,comment_notes, specialisation, patient
    })
    })

    return result;
  }
  return (
 <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
 <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px' mt="30px">
 <SimpleGrid 
     _hover={bgHover}
     _focus={bgFocus}
     _active={bgFocus}
     onClick={onOpenAddPatient}
     >
  <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAddTask} color={brandColor} />
              }
            />
     <Text
            color={textColor}
            fontSize='14px'
            fontWeight='700'
            lineHeight='100%'>
            Add VitalSign
          </Text>
     </SimpleGrid>
        <VitalSignTable
          columnsData={columnsDataComplex}
          patientData={tableData(vitalsigns)}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          isDelete={isDelete}
          setDelete={setDelete}
        />
        <AddVitalSign 
        isOpen={isAddPatientOpen}
        onClose={onCloseAddPatient}
        onOpen={onOpenAddPatient}
        userID={userID}
          />

      </SimpleGrid>

 </Box>
  )
}

export default VitalSign;
import React,{useState} from 'react'
import Card from "components/card/Card.js"
import {   Box,
    Grid,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    Select,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue, } from "@chakra-ui/react";
    import axios from 'axios';
    import { MdOutlineRemoveRedEye } from "react-icons/md";
    import { convertToFormattedDate } from 'components/DateFormater/formatDateString'
    import {useAddPatientMutation} from 'api/patient';
    import DatePicker from 'react-datepicker';
    import 'react-datepicker/dist/react-datepicker.css';
    import { useAuth } from "auth-context/auth.context";
    import FileUploadComponent from "views/auth/signUp/fileupload";
    import {imageLoader} from "components/FileLoader/fileloader"


function PatientForm({onClose}) {
const [address, setAddress] = useState("");
const [phone, setPhone] = useState("");
const [fullName, setName] = useState("");
const [email, setEmail] = useState("");
const { user:{user} } = useAuth();
const [password, setPassword] = useState("");
const [confirmPassword, setConfirmPassword] = useState("");
const [selectedDate, setSelectedDate] = useState(new Date());
const [error, setError] = useState(undefined);
const [buttonText, setButtonText] = useState("Add Beneficial");
const [selectedFile, setSelectedFile] = useState(null);
const [city, setCity] = useState("");
const [state, setState] = useState("")
const [uploadedImageUrl, setUploadedImageUrl] = useState('')
const [selectAge, setSelectAge] = useState("")

console.log(uploadedImageUrl, 'img')
const textColor = useColorModeValue("navy.700", "white");
const textColorSecondary = "gray.400";
const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
const textColorBrand = useColorModeValue("brand.500", "white");
const brandStars = useColorModeValue("brand.500", "brand.400");
const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
const googleText = useColorModeValue("navy.700", "white");
const errorText = useColorModeValue("red.500", "red.400");
const googleHover = useColorModeValue(
  { bg: "gray.200" },
  { bg: "whiteAlpha.300" }
);
const googleActive = useColorModeValue(
  { bg: "secondaryGray.300" },
  { bg: "whiteAlpha.200" }

);

const [addPatient, { isLoading, isError, isSuccess}] = useAddPatientMutation();
const [show, setShow] = React.useState(false);

console.log(selectAge)


const [selectedOption, setSelectedOption] = useState('male');

const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleSelectAgeRange = (event) => {
    setSelectAge(event.target.value);
  };

const handleDateChange = (date) => {
  setSelectedDate(date)
};


  // const handleClick = () => setShow(!show);
  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }
  
   
    try {
      setButtonText(isLoading?'loading....':'Add Patient');

      const datatoBackend ={
        name:fullName,
        address,
        phone,
        age:selectAge, 
        profileImage: uploadedImageUrl,
        email,
        gender:selectedOption,
        city:city,
        state:state,
        createdBy: user.id,
      }

      console.log(datatoBackend)


      addPatient(datatoBackend)
      .unwrap() // Unwrap the result to access the data directly
      .then((userData) => {
        console.log(userData, 'userData')
        setButtonText('Patient added successfully');
        onClose(false)
        // Handle successful login
        if(!userData){
          setButtonText(error?error:'Add Patient');
        
        }
       
      })

    
    } catch (err) {
      console.log(err);
      console.log(err.response)
      if (err.response) {
        return setError(err.response.data.msg);
      }
      return setError("There has been an error.");
    }
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('upload_preset', 'xhuvfous'); // Replace with your Cloudinary upload preset
  
        // Upload the file to Cloudinary using the Cloudinary API
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/daxgakzgm/image/upload`, // Replace 'your_cloud_name' with your Cloudinary Cloud Name
          formData
        );
  
        // Get the image URL from the Cloudinary response
        const imageUrl = await response.data.secure_url;
  
        // Set the uploaded image URL state
       await setUploadedImageUrl(imageUrl);
  
        // // Make a POST request to your backend to store the image URL
        // await axios.post('/api/store-image', { imageUrl });
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };


  console.log(selectedFile)
  console.log(uploadedImageUrl)

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <FormControl>
        <Grid
        mb='20px'
        
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
        >
          <div>
<FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                FullName<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={'Adewole Babs'}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setName(event.target.value)
                  setError(undefined);
                }}
              />
</div>
<div>
<FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder={'email'}
              mb='24px'
            //   defaultValue={email}
              fontWeight='500'
              size='lg'
              onChange={(event) => {
                setEmail(event.target.value);
                setError(undefined);
              }}
            />
</div>
         <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Address<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={'address'}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setAddress(event.target.value);
                  setError(undefined);
                }}
              />
            </div>
            <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                City<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={'city'}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setCity(event.target.value);
                  setError(undefined);
                }}
              />
            </div>
            <div>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                State<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={'state'}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  setState(event.target.value);
                  setError(undefined);
                }}
              />
            </div>
            <div>
<FormLabel
               
               ms='4px'
               fontSize='sm'
               fontWeight='500'
               color={textColor}
               mb='8px'>
               ProfileImage<Text color={brandStars}>*</Text>
               <FileUploadComponent  setSelectedFile={setSelectedFile}/>
             </FormLabel> 
</div>


<FormLabel
               
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Age<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
                {/* <FileUploadComponent uploadedImageUrl={uploadedImageUrl} setUploadedImageUrl={setUploadedImageUrl}/> */}
                {/* <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      dateFormat="yyyy-MM-dd"
    /> */}
      <Select value={selectAge} onChange={handleSelectAgeRange}  mb='24px'>
        <option value="0-5">0-5</option>
        <option value="6 to 12">6 to 12</option>
        <option value="12 to 18">12 to 18</option>
        <option value="18 to 25">18 to 25</option>
        <option value="26 to 40">26 to 40</option>
        <option value="50 to 75">50 to 75</option>
        <option value="80+">80+</option>
      </Select>
              </FormLabel> 
           
      <div>
      <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Phone<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <InputGroup size='md'>
                 <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder={'phone'}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={async(event) => {
                  setPhone(event.target.value);
                  if(selectedFile && !uploadedImageUrl){
                  await handleFileUpload(selectedFile, setUploadedImageUrl)
                  }
                 
                  setError(undefined);
                }}
              />
              
              </InputGroup>
              </div>
               <div>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Gender<Text color={error? errorText:brandStars}> {error?` ${ error}`: '*'}</Text>
              </FormLabel>
              <Select value={selectedOption} onChange={handleSelectChange}  mb='24px'>
        <option value="male">male</option>
        <option value="female">female</option>
      </Select>
      </div>
      
         
             

</Grid>
<Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                onClick={register}
              >
                {buttonText}
              </Button>
</FormControl>
        
        </Box>
     
    </Card>
  )
}

export default PatientForm

let BACKEND_SERVER = null;
if (process.env.REACT_APP_BACKEND_SERVER) {
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} else {
  BACKEND_SERVER = "https://app.eimpactchart.com";
  // BACKEND_SERVER="http://127.0.0.1:8000"

}

export const API_SERVER = BACKEND_SERVER;

export function formatDate(date, format) {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    };
  
    return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
  }
  
  // Example usage
//   const inputDate = '2023-08-25T14:30:00';
//   const formattedDate = formatDate(inputDate, 'yyyy-MM-dd HH:mm:ss');
//   console.log(formattedDate); // Output: "2023-08-25 14:30:00"
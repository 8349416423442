export const columnsDataCheck = [
  
      {
        Header: "ID",
        accessor: "Id",
      },
      {
        Header: "DRUG NAME",
        accessor: "drug name",
      },
      {
        Header: "UNIT PRICE",
        accessor: "unit price",
      },
  
      {
        Header: "AVAILABLE",
        accessor: "available",
      },
      {
          Header: "QAUNTITY",
          accessor: "quantity",
        },
        {
          Header: "VIEW",
          accessor: "view",
        },
        {
          Header: "DELETE",
          accessor: "delete",
        },
  
   
  ];
  export const columnsDataComplex = [

    {
      Header: "ID",
      accessor: "Id",
    },
    {
      Header: "DRUG NAME",
      accessor: "drug name",
    },
    {
      Header: "UNIT PRICE",
      accessor: "unit price",
    },

    {
      Header: "AVAILABLE",
      accessor: "available",
    },
    {
        Header: "QAUNTITY",
        accessor: "quantity",
      },
      {
        Header: "VIEW",
        accessor: "view",
      },
      {
        Header: "DELETE",
        accessor: "delete",
      },
   
  ];
  
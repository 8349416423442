export const columnsDataCheck = [
  {
    Header: "AVATAR",
    accessor: "avatar",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "FIRSTNAME",
    accessor: "firstname",
  },
  {
    Header: "LASTNAME",
    accessor: "lastname",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "ROLE",
    accessor: "role",
  },
 
];
export const columnsDataComplex = [
  {
    Header: "AVATAR",
    accessor: "avatar",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "FIRSTNAME",
    accessor: "firstname",
  },
  {
    Header: "LASTNAME",
    accessor: "lastname",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "ROLE",
    accessor: "role",
  },
 
 
];

export const columnsDataCheck = [
  
      {
        Header: "ID",
        accessor: "Id",
      },
      {
        Header: "BLOOD PRESSURE",
        accessor: "Blood Pressure",
      },
      {
        Header: "WEIGHT",
        accessor: "weight",
      },
      {
        Header: "HEIGHT",
        accessor: "height",
      },
      {
          Header: "TEMPERATURE",
          accessor: "temperature",
        },
        {
            Header: "URINE LEVEL",
            accessor: "urinelevel",
          },

          {
            Header: "PULSE RATE",
            accessor: "pulse rate",
          },

          {
            Header: "VIEW",
            accessor: "view",
          },
          {
            Header: "DELETE",
            accessor: "delete",
          },
  
   
  ];
  export const columnsDataComplex = [

      {
        Header: "ID",
        accessor: "Id",
      },
      {
        Header: "BLOOD PRESSURE",
        accessor: "Blood Pressure",
      },
      {
        Header: "WEIGHT",
        accessor: "weight",
      },
     
      {
          Header: "HEIGHT",
          accessor: "height",
        },
        {
            Header: "URINE LEVEL",
            accessor: "urinelevel",
          },
          {
            Header: "PULSE RATE",
            accessor: "pulse rate",
          },
   
          {
            Header: "VIEW",
            accessor: "view",
          },
          {
            Header: "DELETE",
            accessor: "delete",
          },
  ];
  
import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdSupervisedUserCircle,
  MdSpatialAudioOff,
  MdHome,
  MdLock,
  MdLogout,
  MdBatterySaver,
  MdGamepad,
  MdPropaneTank,
  MdMonetizationOn,
  MdOutlineShoppingCart,
  MdLogin,
  MdAdminPanelSettings,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";

// Auth Imports
// import SignInCentered from "views/auth/signIn";
import SignIn from "views/auth/signIn/index.jsx";
import SignUp from "views/auth/signUp/index.jsx";
import Users from "views/admin/users/users";
import Patient from "views/admin/Patients/patient";
import Nurse from "views/admin/Nurse/nurse";
import Doctor from "views/admin/Doctors/doctor";
import Prescription from "views/admin/Prescriptions/precsription";
import Pharmacies from "views/admin/Pharmacies/pharmacies";
import Diagnosis from "views/admin/Diagnosis/diagnosis";
import Vitalsign from "views/admin/VitalSign/vitalsign";
import Inventories from "views/admin/Inventories/inventories";
import TransactionRecords from "views/admin/TransactionRecords/transaction";
import Dentists from "views/admin/Dentists/dentist"
import Ophthamology from "views/admin/Ophthamology/ophthamology";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/data-tables",
  //   component: DataTables,
  // },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "/users",
    icon: <Icon as={MdSupervisedUserCircle} width='20px' height='20px' color='inherit' />,
    component: Users,
  },
  {
    name: "Beneficials",
    layout: "/admin",
    path: "/patients",
    icon: <Icon as={MdSpatialAudioOff} width='20px' height='20px' color='inherit' />,
    component: Patient,
  },
  {
    name: "Nurses",
    layout: "/admin",
    path: "/nurses",
    icon: <Icon as={ MdBatterySaver} width='20px' height='20px' color='inherit' />,
    component: Nurse,
  },
  {
    name: "Dentists",
    layout: "/admin",
    path: "/dentists",
    icon: <Icon as={MdGamepad} width='20px' height='20px' color='inherit' />,
    component: Dentists,
  },

  {
    name: "Doctors",
    layout: "/admin",
    path: "/doctors",
    icon: <Icon as={MdGamepad} width='20px' height='20px' color='inherit' />,
    component: Doctor,
  },
  {
    name: "Pharmacies",
    layout: "/admin",
    path: "/pharmacies",
    icon: <Icon as={MdPropaneTank} width='20px' height='20px' color='inherit' />,
    component: Pharmacies,
  },
  {
    name: "Ophthamology",
    layout: "/admin",
    path: "/ophthamolohy",
    icon: <Icon as={MdGamepad} width='20px' height='20px' color='inherit' />,
    component: Ophthamology,
  },

  {
    name: "Vitalsign",
    layout: "/admin",
    path: "/vitalsign",
    icon: <Icon as={MdGamepad} width='20px' height='20px' color='inherit' />,
    component: Vitalsign,
  },
  {
    name: "Diagnosis",
    layout: "/admin",
    path: "/diagnosis",
    icon: <Icon as={MdGamepad} width='20px' height='20px' color='inherit' />,
    component: Diagnosis,
  },
  {
    name: "Prescription",
    layout: "/admin",
    path: "/prescriptions",
    icon: <Icon as={MdPropaneTank} width='20px' height='20px' color='inherit' />,
    component: Prescription,
  },

  {
    name: "Signin",
    layout: "/auth",
    path: "/sign-in",
    icon: (
      <Icon as={MdLogin} width='16px' height='16px' color='inherit' />
    ),
    component: SignIn,
    hide: true
  },

  {
    name: "Inventories",
    layout: "/admin",
    path: "/inventories",
    icon: <Icon as={MdPropaneTank} width='20px' height='20px' color='inherit' />,
    component: Inventories,
  },

  {
    name: "Records",
    layout: "/admin",
    path: "/records",
    icon: <Icon as={MdMonetizationOn} width='20px' height='20px' color='inherit' />,
    component: TransactionRecords,
  },
  // {
  //   name: "Sign up",
  //   layout: "/admin",
  //   path: "/sign-up",
  //   icon: (
  //     <Icon as={MdLock} width='16px' height='16px' color='inherit' />
  //   ),
  //   component: SignUp,
  //   hide: true
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdAdminPanelSettings} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
];

export const Logout = [
  {
    name: "Log Out",
    layout: "/auth",
    path: "/sign-out",
    icon: (
      <Icon as={MdLogout} width='16px' height='16px' color='inherit' />
    ),
    component: SignIn,
  }
];
export default routes;
